//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import _ from "lodash";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../components/general";
import TestBookData from "../../components/emergency/TestBookData";

//3rd Party Components
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Carousel } from "primereact/carousel";
import { Dropdown } from "primereact/dropdown";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { selectedBuildingAtom } from "../../atoms/BuildingAtom";
import {
	testBookSeriesIdAtom,
	testBookDataSelectorFamily,
	testBookStateSelectorFamily,
	testBookTableFilterAtom,
} from "../../atoms/TestBookAtom";

//Helpers
import { buildFiltersNew } from "../../helpers/ExportUtils";
import { useApiRequest } from "../../helpers/NetworkUtils";
import { GET_TEST_BOOK } from "../../helpers/Constants";
import { useHandleGenericMultiExport } from "../../helpers/ExportUtils";
import i18n from "../../i18n";

//Other
const appElement = document.getElementById("appWrapper");
const filterViewOptions = [
	{ value: "all", disabled: false },
	{ value: "fail", disabled: true },
	{ value: "warn", disabled: true },
];

function TestBook() {
	const { t } = useTranslation();
	const moreBttnMenu = useRef(null);

	// Atom State
	const [loading, setLoading] = useRecoilState(loadingAtom);
	const selectedBuilding = useRecoilValue(selectedBuildingAtom);
	const [seriesIds, setSeriesIds] = useRecoilState(testBookSeriesIdAtom);
	const tableStates = useRecoilValue(testBookStateSelectorFamily(seriesIds));
	const tableFilterData = useRecoilValue(testBookDataSelectorFamily(seriesIds));
	const resetTableFilters = useResetRecoilState(testBookTableFilterAtom);

	const [testData, setTestData] = useState();
	const [filterView, setFilterView] = useState(filterViewOptions[0].value);

	const { data } = useApiRequest("get", `${GET_TEST_BOOK}/${selectedBuilding.id}`, null);
	const handleFileMultiExport = useHandleGenericMultiExport();

	useEffect(() => {
		setLoading({ visible: true, message: t("pageTestBook.loading") });

		return function cleanup() {
			resetTableFilters();
		};
	}, []);

	useEffect(() => {
		if (data) {
			data.scenecom.map((s) => {
				s.updatedAt = new Date(s.updatedAt);
				s.emergencySummary.lastFunctionTestDate = new Date(s.emergencySummary.lastFunctionTestDate);
				s.emergencySummary.lastDurationTestDate = new Date(s.emergencySummary.lastDurationTestDate);
				s.testBookEntry.map((t) => {
					t.timestamp = new Date(t.timestamp);
				});
				return s;
			});
			setTestData(data);
			setSeriesIds(data.scenecom.map((d) => d.hardwareId));
			if (data.scenecom.filter((s) => s.emergencySummary.inFailure !== 0).length)
				filterViewOptions.find((f) => f.value === "fail").disabled = false;
			if (data.scenecom.filter((s) => s.emergencySummary.inWarning !== 0).length)
				filterViewOptions.find((f) => f.value === "warn").disabled = false;
		}
	}, [data]);

	const moreBttnItems = [
		{
			label: "Export",
			items: [
				{
					label: "PDF",
					icon: "pi pi-file-pdf",
					command: () => handleExport("pdf"),
				},
				{
					label: "Excel",
					icon: "pi pi-file-excel",
					command: () => handleExport("excel"),
				},
				{
					label: "CSV",
					icon: "pi pi-file",
					command: () => handleExport("csv"),
				},
			],
		},
	];

	const moreBttn = (
		<>
			<Button
				label={t("pageTestBook.secondaryBttn")}
				icon="pi pi-angle-down"
				onClick={(event) => moreBttnMenu.current.toggle(event)}
				aria-label="More Actions"
				aria-controls="bttnMenu"
				aria-haspopup
			/>
			<Menu id="bttnMenu" popup ref={moreBttnMenu} model={moreBttnItems} appendTo={appElement} />
		</>
	);

	const filterViewOptionsTemplate = (option, props) => {
		let count = 0;

		switch (option.value) {
			case "all":
				count = testData.scenecom.length;
				break;
			case "fail":
				count = testData.scenecom.reduce((prevValue, currValue) => {
					return prevValue + currValue.emergencySummary.inFailure;
				}, 0);
				break;
			case "warn":
				count = testData.scenecom.reduce((prevValue, currValue) => {
					return prevValue + currValue.emergencySummary.inWarning;
				}, 0);
				break;
			default:
				break;
		}

		return (
			<span>
				{t(`pageTestBook.view.${option.value}`)} ({count})
			</span>
		);
	};

	const filterViewBttn = (
		<Dropdown
			className="feature"
			value={filterView}
			options={filterViewOptions}
			optionLabel="value"
			onChange={(e) => setFilterView(e.value)}
			valueTemplate={filterViewOptionsTemplate}
			itemTemplate={filterViewOptionsTemplate}
		/>
	);

	const testbookTemplate = (scenecom) => {
		return <TestBookData data={scenecom} />;
	};

	const valueByFilter = () => {
		let scenecoms = null;
		if (filterView === "all") {
			scenecoms = testData.scenecom;
		} else if (filterView === "fail") {
			scenecoms = testData.scenecom.filter((s) => s.emergencySummary.inFailure);
		} else if (filterView === "warn") {
			scenecoms = testData.scenecom.filter((s) => s.emergencySummary.inWarning);
		}

		return scenecoms;
	};

	const handleExport = async (type) => {
		const tableName = "Emergency Test Book";
		const tableResource = "common.test";
		const columns = tableStates[0].tableProps.children.map((p) => p.props.header);
		const fields = tableStates[0].tableProps.children.map((p) => p.props.field);
		const filters = buildFiltersNew(tableStates[0].tableFilters, "pageTestBook", "common.test");
		const pageTitles = tableStates.map((s) => s.title);
		const baseData =
			tableFilterData[0] !== null
				? _.cloneDeep(tableFilterData)
				: _.cloneDeep(testData.scenecom.map((s) => s.testBookEntry));

		baseData.map((d) => {
			d.map((a) => {
				Object.entries(a).forEach(([key, value]) => {
					a[key] = i18n.exists(`common.test.${value}`) ? t(`common.test.${value}`) : value;
				});
			});
		});

		columns.shift();
		fields.shift();

		handleFileMultiExport(
			type,
			tableName,
			{
				baseData: baseData,
				filters: filters,
				columns: [...columns, "Details"],
				fields: [...fields, "testBookSubEntry"],
				pageTitles: pageTitles,
			},
			tableResource
		);
	};

	return (
		<>
			<PageHelmet title={t("pageTestBook.pageHeader")} />
			<PageHeader
				title={t("pageTestBook.pageHeader", {
					count: testData && testData.scenecom.length,
				})}
				subtitle={`${t("pageTestBook.pageSubTitle")}: ${selectedBuilding.name}`}
				enableBack
				featureBttn={testData && filterViewBttn}
				secondaryBttn={moreBttn}
			/>
			<main className="testBook">
				<div className="mainContent">
					{!loading.visible && testData && (
						<>
							{testData.scenecom.length === 1 ? (
								<TestBookData data={testData.scenecom[0]} />
							) : (
								<div className="carouselWrapper indicatorTop indicatorLeft flexVert gapLarge">
									<Carousel
										value={valueByFilter()}
										itemTemplate={testbookTemplate}
										// page={page}
										// onPageChange={(e) => setPage(e.page)}
										containerClassName="vert"></Carousel>
								</div>
							)}
						</>
					)}
				</div>
			</main>
		</>
	);
}

export default TestBook;
