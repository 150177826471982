//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../components/general";
import Map from "../../components/Map";

//3rd Party Components
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { userAtom } from "../../atoms/UserAtom";
import { selectedBuildingAtom } from "../../atoms/BuildingAtom";
import { testBookTableFilterAtom } from "../../atoms/TestBookAtom";

//Helpers
import { formatDate, formatDateTime, isDurationBefore, today, isTestBefore } from "../../helpers/DateUtils";
import {
	healthOptions,
	resultTemplate,
	healthTemplate,
	resultFilterTemplate,
} from "../../helpers/datatable/SummaryDatatableUtils";
import { useApiRequest } from "../../helpers/NetworkUtils";
import { GET_ALL_BUILDINGS_WITH_DURATION_SUMMARY } from "../../helpers/Constants";

//Other
import { nav } from "../../config/navigation";

function Duration() {
	const { t } = useTranslation();
	const dt = useRef(null);
	const navigate = useNavigate();

	const appElement = document.getElementById("appWrapper");

	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const [selectedBuilding, setSelectedBuilding] = useRecoilState(selectedBuildingAtom);
	const setTestBookFilter = useSetRecoilState(testBookTableFilterAtom);
	const resetBuilding = useResetRecoilState(selectedBuildingAtom);

	const [viewType, setViewType] = useState(true);
	const [buildings, setBuildings] = useState();
	const [mappedBuildings, setMappedBuildings] = useState();
	const [filterFailed, setFilterFailed] = useState(false);
	const [filterOld, setFilterOld] = useState(false);
	const [filters, setFilters] = useState(null);

	const { data } = useApiRequest("get", GET_ALL_BUILDINGS_WITH_DURATION_SUMMARY, null);

	useEffect(() => {
		resetBuilding();
		setBuildings(null);
		initFilters();
		setLoading({ visible: true, message: "Loading Report Data" });
	}, [user.selectedProject]);

	useEffect(() => {
		data &&
			data.map((d) => {
				d.emergencySummary.lastDurationTestDate = new Date(d.emergencySummary.lastDurationTestDate);
				return d;
			});
		setBuildings(data);
	}, [data]);

	useEffect(() => {
		if (filterFailed && filterOld) {
			setMappedBuildings(buildings.filter((b) => b.emergencySummary.state !== "HEALTHY" && isDurationBefore(b)));
		} else if (filterFailed && !filterOld) {
			setMappedBuildings(
				buildings.filter(
					(b) => b.emergencySummary.state !== "HEALTHY" && !b.emergencySummary.state.startsWith("OVERDUE")
				)
			);
		} else if (!filterFailed && filterOld) {
			setMappedBuildings(buildings.filter((b) => isDurationBefore(b)));
		} else {
			setMappedBuildings(buildings);
		}
	}, [buildings, filterFailed, filterOld]);

	const initFilters = () => {
		setFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			"emergencySummary.state": { value: null, matchMode: FilterMatchMode.IN },
			"emergencySummary.lastDurationTestDate": {
				operator: FilterOperator.OR,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			"emergencySummary.totalCount": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			"emergencySummary.failCount": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			"emergencySummary.warnCount": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const handleFilterFailed = (value) => {
		setLoading({ visible: true, message: t("pageDuration.updatingMap") });
		setSelectedBuilding(null);
		setFilterFailed(value);
		setLoading({ visible: false, message: "" });
	};

	const handleFilterOld = (value) => {
		setLoading({ visible: true, message: t("pageDuration.updatingMap") });
		setSelectedBuilding(null);
		setFilterOld(value);
		setLoading({ visible: false, message: "" });
	};

	const durationDateRowTemplate = (rowData) => {
		return (
			<span
				className={
					isTestBefore(rowData.emergencySummary.lastDurationTestDate, rowData.overdueDuration)
						? "warn withTooltip"
						: undefined
				}
				aria-label={`${t("pageSummary.table.overdue")}: ${rowData.overdueDuration} days`}>
				{formatDateTime(rowData.emergencySummary.lastDurationTestDate)};
			</span>
		);
	};

	const healthRowTemplate = (rowData) => {
		return healthTemplate(rowData.emergencySummary.state, null, rowData.overdueDuration);
	};

	const healthFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={healthOptions}
				itemTemplate={resultFilterTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const basicNumberFilterTemplate = (options) => {
		return (
			<InputNumber
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				showButtons
			/>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				maxDate={today.toJSDate()}
				showButtonBar
				showTime={true}
				appendTo={appElement}
			/>
		);
	};

	const handleViewTestBook = () => {
		setTestBookFilter((prev) => ({
			...prev,
			subType: { value: ["DURATION_TEST"], matchMode: FilterMatchMode.IN },
		}));
		navigate(nav.emergency.testbook);
	};

	const viewTestBookBttn = (
		<Button
			className="feature"
			label="View Test Book"
			icon="pi pi-book"
			onClick={() => handleViewTestBook()}
			disabled={selectedBuilding === null ? true : false}
		/>
	);
	const toggleViewBttn = (
		<ToggleButton
			onLabel={t("common.toggleTable")}
			offLabel={t("common.toggleMap")}
			onIcon="pi pi-table"
			offIcon="pi pi-map-marker"
			checked={viewType}
			onChange={(e) => setViewType(e.value)}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageDuration.pageHeader")} />
			<PageHeader
				title={t("pageDuration.pageHeader")}
				featureBttn={viewTestBookBttn}
				secondaryBttn={toggleViewBttn}
			/>
			<main>
				<div className="mainContent">
					<div className="column3 horizGap">
						<div className="card cardSummary">
							<div className="column2 autoColumns">
								<h2>{t("pageDuration.summary.failure")}</h2>
								{viewType && (
									<InputSwitch
										checked={filterFailed}
										onChange={(e) => handleFilterFailed(e.value)}
										className="jSelfEnd"
									/>
								)}
							</div>
							{buildings && (
								<div>
									<p className="h1 double">
										{
											buildings.filter(
												(b) =>
													b.emergencySummary.state !== "HEALTHY" &&
													!b.emergencySummary.state.startsWith("OVERDUE")
											).length
										}{" "}
										<span className="h3 thin">{t("common.of")}</span> {buildings.length}
									</p>
								</div>
							)}
						</div>
						<div className="card cardSummary">
							<div className="column2 autoColumns">
								<h2>{t("pageDuration.summary.overdue")}</h2>
								{viewType && (
									<InputSwitch
										checked={filterOld}
										onChange={(e) => handleFilterOld(e.value)}
										className="jSelfEnd"
									/>
								)}
							</div>
							{buildings && (
								<div>
									<p className="h1 double">
										{buildings.filter((b) => isDurationBefore(b)).length}{" "}
										<span className="h3 thin">{t("common.of")}</span> {buildings.length}
									</p>
								</div>
							)}
						</div>
						<div className="card cardSummary">
							<h2>{t("pageDuration.summary.summary")}</h2>
							{selectedBuilding && selectedBuilding.emergencySummary ? (
								<div className="column2 autoColumns noVertGap">
									<span>{t("pageDuration.summary.name")}:</span>
									<span>{selectedBuilding.name}</span>
									<span>{t("pageDuration.summary.state")}:</span>
									<span>{resultTemplate(selectedBuilding.emergencySummary.state)}</span>
									<span>{t("pageDuration.summary.date")}:</span>
									<span>{formatDate(selectedBuilding.updatedAt)}</span>
								</div>
							) : (
								<p>{t("pageDuration.summary.noBuilding")}</p>
							)}
						</div>
					</div>
					{buildings && !viewType && (
						<div className="card">
							<DataTable
								ref={dt}
								value={buildings}
								emptyMessage={t("common.table.noData")}
								selectionMode="single"
								selection={selectedBuilding}
								onSelectionChange={(e) => setSelectedBuilding(e.value)}
								sortMode="multiple"
								removableSort
								filters={filters}
								filterDisplay="menu"
								autoLayout={true}
								paginator
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
								currentPageReportTemplate={t("common.table.paginatorTemplate")}
								rows={10}>
								<Column
									field="name"
									header={t("pageDuration.table.building")}
									sortable
									filter
									filterMatchMode="contains"
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.state"
									header={t("pageDuration.table.health")}
									body={healthRowTemplate}
									sortable
									filter
									showFilterMatchModes={false}
									filterElement={healthFilter}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.lastDurationTestDate"
									header={t("pageDuration.table.date")}
									body={durationDateRowTemplate}
									dataType="date"
									sortable
									filter
									filterElement={dateFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.totalCount"
									header={t("pageSummary.table.totalCount")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.failCount"
									header={t("pageSummary.table.failCount")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.warnCount"
									header={t("pageSummary.table.warnCount")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
							</DataTable>
						</div>
					)}
					{mappedBuildings && viewType && <Map places={mappedBuildings} />}
				</div>
			</main>
		</>
	);
}

export default Duration;
