import i18n from "../../i18n";

const getTypeIcon = (value) => {
	let icon;
	let colour;

	if (value === "FUNCION_TEST") {
		icon = "pi-cog";
		colour = "success";
	} else if (value === "DURATION_TEST") {
		icon = "pi-clock";
		colour = "info";
	} else if (value === "INSPECTION_TEST") {
		icon = "pi-search";
		colour = "error";
	} else {
		icon = "pi-exclamation-circle";
		colour = "warn";
	}

	return { icon, colour };
};

export const testSubTypeOptions = [
	{ value: "FUNCION_TEST", label: i18n.t("common.test.FUNCION_TEST") },
	{ value: "DURATION_TEST", label: i18n.t("common.test.DURATION_TEST") },
	{ value: "INSPECTION_TEST", label: i18n.t("common.test.INSPECTION_TEST") },
	{ value: "ADDRESSING", label: i18n.t("common.test.ADDRESSING") },
	{ value: "READDRESSING", label: i18n.t("common.test.READDRESSING") },
	{ value: "DELETING", label: i18n.t("common.test.DELETING") },
	{ value: "BLOCKING", label: i18n.t("common.test.BLOCKING") },
	{ value: "NOT_BLOCKED", label: i18n.t("common.test.NOT_BLOCKED") },
	{ value: "UNBLOCKING", label: i18n.t("common.test.UNBLOCKING") },
	{ value: "NOT_UNBLOCKED", label: i18n.t("common.test.NOT_UNBLOCKED") },
	{ value: "DC_MODE", label: i18n.t("common.test.DC_MODE") },
	{ value: "AC_MODE", label: i18n.t("common.test.AC_MODE") },
	{ value: "DEEP_CHARGE_STATE", label: i18n.t("common.test.DEEP_CHARGE_STATE") },
	{ value: "INFORMATION", label: i18n.t("common.test.INFORMATION") },
];

export const testSubTypeTemplate = (value) => {
	const { icon, colour } = getTypeIcon(value);

	return (
		<span className="withIcon">
			<i className={`pi ${icon} ${colour}`} />
			{i18n.t(`common.test.${value}`)}
		</span>
	);
};

export const testSubTypeFilterTemplate = (option) => {
	const { icon, colour } = getTypeIcon(option.value);

	return (
		<span className="withIcon">
			<i className={`pi ${icon} ${colour}`} />
			{option.label}
		</span>
	);
};

export const testTypeOptions = [
	{ value: "TEST", label: i18n.t("common.test.TEST") },
	{ value: "EVENT", label: i18n.t("common.test.EVENT") },
	{ value: "MANUAL_INFORMATION", label: i18n.t("common.test.MANUAL_INFORMATION") },
];

export const testTypeOptionsTemplate = (value) => {
	return testTypeOptions.find((t) => t.value === value).label;
};

export const testTypeFilterTemplate = (option) => {
	return option.label;
};

export const mannerOptions = [
	{ value: "MANUALLY", label: i18n.t("common.test.MANUALLY") },
	{ value: "AUTOMATIC", label: i18n.t("common.test.AUTOMATIC") },
];

export const mannerOptionsTemplate = (value) => {
	return mannerOptions.find((m) => m.value === value).label;
};

export const mannerFilterTemplate = (option) => {
	return option.label;
};
