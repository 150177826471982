import failMarker from "../assets/imgs/map/failMarker.svg";
import passMarker from "../assets/imgs/map/passMarker.svg";
import cancelledMarker from "../assets/imgs/map/cancelledMarker.svg";
import overdueMarker from "../assets/imgs/map/overdueMarker.svg";
import selectedMarker from "../assets/imgs/map/selectedMarker.svg";
import standardMarker from "../assets/imgs/map/standardMarker.svg";

const mode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
let mapStyles = [];
if (mode === "dark") {
	mapStyles = [
		// {
		// 	featureType: "landscape.natural",
		// 	elementType: "geometry.fill",
		// 	stylers: [
		// 		{
		// 			lightness: "-80",
		// 		},
		// 	],
		// },
		// {
		// 	featureType: "landscape.man_made",
		// 	elementType: "geometry.fill",
		// 	stylers: [
		// 		{
		// 			saturation: "-100",
		// 		},
		// 		{
		// 			lightness: "-65",
		// 		},
		// 	],
		// },
		// {
		// 	featureType: "poi.park",
		// 	elementType: "geometry.fill",
		// 	stylers: [
		// 		{
		// 			lightness: "-53",
		// 		},
		// 		{
		// 			saturation: "-72",
		// 		},
		// 	],
		// },
		// {
		// 	featureType: "road",
		// 	elementType: "geometry.fill",
		// 	stylers: [
		// 		{
		// 			lightness: "-70",
		// 		},
		// 		{
		// 			hue: "#00c9ff",
		// 		},
		// 		{
		// 			saturation: "-30",
		// 		},
		// 	],
		// },
		// {
		// 	featureType: "road",
		// 	elementType: "geometry.stroke",
		// 	stylers: [
		// 		{
		// 			hue: "#00c9ff",
		// 		},
		// 		{
		// 			saturation: "-30",
		// 		},
		// 		{
		// 			lightness: "-30",
		// 		},
		// 	],
		// },
		// {
		// 	featureType: "water",
		// 	elementType: "all",
		// 	stylers: [
		// 		{
		// 			lightness: "-56",
		// 		},
		// 		{
		// 			saturation: "-70",
		// 		},
		// 	],
		// },
	];
}

export const mapContainerStyle = {
	width: "100%",
	height: "100%",
};
export const mapZoom = 2;
export const mapCenter = {
	lat: 54.4,
	lng: -3.4,
};

export const mapOptions = {
	disableDefaultUI: true,
	zoomControl: true,
	styles: mapStyles,
};

export const clusterOptions = {
	enableRetinaIcons: true,
	minimumClusterSize: 3,
	imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

export function getIcon(result, selected) {
	let icon = "";

	if (selected) {
		icon = selectedMarker;
	} else if (result === "STANDARD") {
		icon = standardMarker;
	} else if (result === "SUCCESS" || result === "HEALTHY") {
		icon = passMarker;
	} else if (result === "FAILURE" || result === "FAILURES") {
		icon = failMarker;
	} else if (result === "OVERDUE_F" || result === "OVERDUE_D") {
		icon = overdueMarker;
	} else {
		icon = cancelledMarker;
	}

	return icon;
}
