//Node Modules
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Navigate, useLocation } from "react-router-dom";

//Pages

//BinaryForgeComponenets

//3rd Party Components

//Atoms
import { userAtom } from "../../atoms/UserAtom";
import { modulesAtom } from "../../atoms/ProjectAtom";

//Helpers
import { useIsValidSession } from "../../helpers/Auth";

//Other
import { nav } from "../../config/navigation";
// import { useLogout } from "../../services/BaseServices";

const AuthRoute = ({ module, children }) => {
	const location = useLocation();
	const isValidSession = useIsValidSession();

	//Local State
	const [isValid, setIsValid] = useState();

	//Recoil State
	const modules = useRecoilValue(modulesAtom);

	useEffect(() => {
		const validSession = async () => {
			setIsValid((await isValidUserSession()) && (await isValidModule()));
		};

		validSession();
	}, [children, isValidSession]);

	const isValidUserSession = async () => {
		return (await isValidSession()) ? true : false;
	};

	const isValidModule = async () => {
		return modules[module] === false ? false : true;
	};

	if (isValid === undefined) return null;
	return isValid ? children : <Navigate to={nav.guest.login} state={{ from: location }} replace />;
};

// function AuthRoute(props) {
// 	const navigate = useNavigate();

// 	const user = useRecoilValue(userAtom);
// 	const modules = useRecoilValue(modulesAtom);
// 	// const handleLogout = useLogout();

// 	// useEffect(() => {
// 	// 	async function checkIfSessionIsValid() {
// 	// 		try {
// 	// 			await Auth.currentAuthenticatedUser();
// 	// 		} catch (err) {
// 	// 			process.env.NODE_ENV === "development" && console.log("Auth Error: :", err);
// 	// 			handleLogout();
// 	// 		}
// 	// 	}
// 	// 	checkIfSessionIsValid();
// 	// }, [props]);

// 	if (process.env.REACT_APP_ENV === "development" && window.location.href.indexOf("showtranslations") > -1) {
// 		return <Route {...props} />;
// 	}

// 	if (modules[props.module] === false) {
// 		process.env.REACT_APP_ENV === "development" &&
// 			console.error("AuthRoute:", "Trying to access an in-active module.");
// 		return <Redirect to={nav.notfound} />;
// 	}

// 	if (!user.isLoggedIn && props.guest) {
// 		process.env.REACT_APP_ENV === "development" && console.log("AuthRoute:", 1);
// 		return <Route {...props} />;
// 	} else if (!user.isLoggedIn && props.auth && user.availableProjects) {
// 		process.env.REACT_APP_ENV === "development" && console.log("AuthRoute:", 2);
// 		history.replace(nav.general.projectSelect);
// 		return null;
// 	} else if (!user.isLoggedIn && props.auth) {
// 		process.env.REACT_APP_ENV === "development" && console.log("AuthRoute:", 3);
// 		history.replace(nav.general.login);
// 		return null;
// 	} else if (user.isLoggedIn && props.path === "/") {
// 		process.env.REACT_APP_ENV === "development" && console.log("AuthRoute:", 4);
// 		history.replace(nav.emergency.summary);
// 		return null;
// 	} else {
// 		process.env.REACT_APP_ENV === "development" && console.log("AuthRoute:", 5);
// 		return <Route {...props} />;
// 	}
// }

export default AuthRoute;
