import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";

import { Dropdown } from "primereact/dropdown";

import { dialogAtomFamily, isDialogOpenAtom } from "../../atoms/DialogAtom";
import { loadingAtom } from "../../atoms/LoadingAtom";

import { PresetDatatableComponent } from "../reports";
import { MultipleWarningMessages } from "../general";
import LineDateChart from "../charts/LineDate";

import { formatReportData, getColumnsForReportTable } from "../../helpers/datatable/ReportFunctions";
import { formatRangeForPresetReports } from "../../helpers/DateUtils";
import { formatIntervalForReportCriteria } from "../../helpers/chart/reportFilterOptions";
import { formatDataForDisplay, getFormattedMessages } from "../../helpers/reports/messages";

export default function PresetView({ getData, preset, isGraphView, getFilterSelection }) {
	const { t } = useTranslation();
	const location = useLocation();
	const [loading, setLoading] = useRecoilState(loadingAtom);
	const setWarningShow = useSetRecoilState(dialogAtomFamily("reportWarning"));
	const [data, setData] = useState(null);
	const [formattedData, setFormattedData] = useState([]);
	const [tableColumns, setTableColumns] = useState([]);
	const [filterSelection, setFilterSelection] = useState(null);
	const [showCriteria, setShowCriteria] = useState(false);
	const isOpen = useRecoilValue(isDialogOpenAtom);
	const [messages, setMessages] = useState(null);

	//It formats data for datatable
	const formatData = useCallback(() => {
		return formatReportData(data);
	}, [data]);

	//It generates datatable columns dynamically
	const getColumns = useCallback(() => {
		return getColumnsForReportTable(data);
	}, [data]);

	//It prepares the data for datatable
	useEffect(() => {
		if (data) {
			setFormattedData(formatData());
			setTableColumns(getColumns());
		}
	}, [data, formatData, getColumns]);

	//Get data to serve graph and table
	useEffect(() => {
		const fetchData = async () => {
			setLoading({ visible: true, message: "Loading Data" });
			const returnFilterData = await getFilterSelection(preset.query);
			const returnData = await getData(preset, returnFilterData);

			const {
				warningArr: { noData, maxData, error },
				dataArr,
			} = formatDataForDisplay(returnData);

			if (noData.length > 0 || maxData.length > 0 || error.length > 0) {
				setMessages(getFormattedMessages(location.pathname, { noData, maxData, error }));
				setWarningShow(true);
			}

			setData(dataArr.length > 0 ? dataArr : null);
			setFilterSelection(returnFilterData);
			setLoading({ visible: false, message: "" });
		};
		if (preset) {
			fetchData();
		}
	}, [getData, getFilterSelection, preset, setLoading, setWarningShow]);

	return (
		<>
			{preset && !loading.visible && (
				<>
					<div className="card">
						<div
							style={{ display: "flex", alignItems: "center" }}
							className={showCriteria ? "marginBottomMedium" : ""}>
							<i
								className={`${
									showCriteria ? "pi pi-chevron-down" : "pi pi-chevron-right"
								} marginRightSmall withPTooltip`}
								onClick={() => setShowCriteria((prevShowCriteria) => !prevShowCriteria)}
							/>
							<h3>{t("pageReportPreset.criteria")}</h3>
						</div>
						{showCriteria && (
							<div className="column2 gapColLarge gapRowMedium">
								<div className="column2 autoColumns gapSmall">
									{/* Datetime range */}
									<span>{t("pageReport.reportCriteria.range")}:</span>
									<span>{formatRangeForPresetReports(preset.query[0])}</span>
									{/* Interval */}
									<span>{t("pageReport.reportCriteria.interval")}:</span>
									<span>{formatIntervalForReportCriteria(preset.query[0].interval)}</span>
									{/* Aggregate */}
									<span>{t("pageReport.reportCriteria.aggregate")}:</span>
									<span>
										{t(`pageReport.aggregate.${preset.query[0].aggregate_type.toLowerCase()}`)}
									</span>
								</div>
								<div>
									<div className="column2 autoColumns gapSmall">
										{filterSelection &&
											filterSelection.map((query, index) => {
												return (
													<React.Fragment key={`${query.seriesName} - ${index}`}>
														<span>{query.seriesName}:</span>
														{query.dropdown ? (
															<span>
																<Dropdown
																	name="assignGateway"
																	options={query.dropdown}
																	optionLabel="name"
																	placeholder={`${t(
																		"pageReport.reportCriteria.filterType"
																	)} ${query.filterType}`}
																/>
															</span>
														) : (
															<span> {query.filterType}</span>
														)}
													</React.Fragment>
												);
											})}
									</div>
								</div>
							</div>
						)}
					</div>

					<div>
						{!isOpen && !loading.visible && (
							<>
								{data &&
									(isGraphView ? (
										<div className="card">
											<h2 className="marginBottomLarge">{preset.name}</h2>
											<LineDateChart data={data} unit="" />
										</div>
									) : (
										<PresetDatatableComponent
											formattedData={formattedData}
											reportName={""}
											columns={tableColumns}
										/>
									))}
								<MultipleWarningMessages messages={messages} />
							</>
						)}
					</div>
				</>
			)}
		</>
	);
}
