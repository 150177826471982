//Node Modules
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForgeComponenets
import { PageHelmet, PageHeader } from "../components/general";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms

//Services

//Helpers

//Other

function NotFound() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<>
			<PageHelmet title={t("pageNotFound.pageHeader")} />
			<PageHeader title={t("pageNotFound.pageHeader")} enableBack />
			<main>
				<div className="mainContent">
					<p>{t("pageNotFound.message")}</p>
					<p>{t("pageNotFound.contactAdmin")}</p>
					<Button id="goBack" label={t("common.back")} icon="pi pi-arrow-left" onClick={() => goBack()} />
				</div>
			</main>
		</>
	);
}

export default NotFound;
