//Node Modules
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";

//Pages

//BinaryForge Components
import { NoHudHeader, PageHelmet } from "../components/general";
import { InputUsername, InputPasswordConfirm, InputPasswordTemp } from "../components/user";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { toastAtom } from "../atoms/ToastAtom";
import { loadingAtom } from "../atoms/LoadingAtom";

//Services
import { useLoginUser } from "../services/BaseServices";

function CompleteRegistrationPage() {
	const { t } = useTranslation();
	const { login } = useLoginUser();
	//Recoil Atom States
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoading = useSetRecoilState(loadingAtom);

	//React Hook Form
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	const onSubmit = async (data) => {
		setLoading({ visible: true, message: t("pageRegistration.submit") });
		try {
			const userWithPendingStatus = await Auth.signIn(data.username, data.passwordTemp);
			await Auth.completeNewPassword(userWithPendingStatus, data.password);
			login();
		} catch (error) {
			console.errpr("onSubmit Error:", error);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageRegistration.toast.errorSummary"),
				detail: t("pageRegistration.toast.errorDetail"),
				life: 6000,
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	return (
		<div className="noHud">
			<PageHelmet title={t("pageRegistration.pageHeader")} />
			<NoHudHeader />

			<div className="noHudMain">
				<h2 className="marginBottomMedium">{t("pageRegistration.pageHeader")}</h2>
				<div className="divider marginBottomLarge"></div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<InputUsername register={register} errors={errors} />
					<InputPasswordTemp register={register} errors={errors} required />
					<InputPasswordConfirm register={register} errors={errors} values={getValues} required />

					<Button type="submit" label="Complete Registration" className="feature" />
				</form>
			</div>
		</div>
	);
}

export default CompleteRegistrationPage;
