//Node Modules
import React, { useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../../components/general";
import { InputUsername, InputPasswordConfirm, InputApiKey, ReadOnly } from "../../../components/account";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { loadingAtom } from "../../../atoms/LoadingAtom";
import { toastAtom } from "../../../atoms/ToastAtom";
import { selectedAccountAtom } from "../../../atoms/AccountAtom";

//Services

//Helpers
import { VERB_ACCOUNT } from "../../../helpers/Constants";
import { useMakeRequest } from "../../../helpers/NetworkUtils";

//Other
import { nav } from "../../../config/navigation";

function AccountEdit() {
	const { t } = useTranslation();
	const hiddenSubmit = useRef();
	const navigate = useNavigate();

	// Atom States
	const setLoading = useSetRecoilState(loadingAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const selectedAccount = useRecoilValue(selectedAccountAtom);

	const makeRequest = useMakeRequest();

	// React Hook Form
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	// On Form Submit
	const onSubmit = async (data) => {
		try {
			setLoading({
				visible: true,
				message: t("pageAccountEdit.loadingCreate"),
			});
			// Encrypt the password before sending
			const key = process.env.REACT_APP_CRYPTO_KEY;
			const encryptedPassword = CryptoJS.AES.encrypt(data.password, key).toString();
			data.password = encryptedPassword;
			data.type = selectedAccount.accountType.name;

			// Update the account
			delete data.passwordConfirm;
			await makeRequest("put", `${VERB_ACCOUNT}/${selectedAccount.id}`, data);
			setToast({
				...toast,
				severity: "success",
				summary: t("pageAccountEdit.toast.successSummary"),
				detail: t("pageAccountEdit.toast.successDetail"),
			});
		} catch (err) {
			console.error("BuildingEdit onSubmit Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageAccountEdit.toast.errorSummary"),
				detail: t("pageAccountEdit.toast.errorDetail", { err: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
			navigate(nav.admin.account.management);
		}
	};

	// Page Buttons
	const saveBttn = (
		<Button
			className="feature"
			label={t("pageAccountEdit.featureBttn")}
			icon="pi pi-save"
			onClick={() => hiddenSubmit?.current?.click()}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageAccountEdit.pageHeader")} />
			<PageHeader title={t("pageAccountEdit.pageHeader")} featureBttn={saveBttn} enableBack />
			<main>
				<div className="mainContent">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="card">
							<div className="formFieldsWrapper">
								<ReadOnly label="name" value={selectedAccount.name} register={register} />
								<ReadOnly label="type" value={selectedAccount.accountType.name} register={register} />
								<InputApiKey
									register={register}
									errors={errors}
									defaultValue={selectedAccount.apiKey}
								/>
								<InputUsername
									register={register}
									errors={errors}
									defaultValue={selectedAccount.username}
								/>
								<InputPasswordConfirm register={register} errors={errors} values={getValues} />
							</div>
						</div>

						<button type="submit" hidden={true} ref={hiddenSubmit} />
					</form>
				</div>
			</main>
		</>
	);
}

export default AccountEdit;
