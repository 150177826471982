//Node Modules
import { useRecoilValueLoadable } from "recoil";

//Pages

//BinaryForge Components
import { UserListTable } from "./";
import { MessageIcon } from "../general";

//3rd Party Components

//Atoms
import { usersSelector } from "../../atoms/UserAtom";
import { DatatableSkeleton } from "../skeleton";

//Helpers

//Other

const UserList = () => {
	//Recoil
	const { state, contents } = useRecoilValueLoadable(usersSelector);

	switch (state) {
		case "hasError":
			return (
				<MessageIcon
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle error"
				/>
			);
		case "loading":
			return <DatatableSkeleton columns={["username", "fullname", "email", "active"]} />;
		case "hasValue":
			return <UserListTable userData={contents} />;
		default:
			return null;
	}
};

export default UserList;
