//Node Modules
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { Dropdown } from "primereact/dropdown";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { selectedBuildingAtom } from "../../atoms/BuildingAtom";

//Services

//Helpers
import { GET_UNASSIGNED_GATEWAYS } from "../../helpers/Constants";
import { useMakeRequest } from "../../helpers/NetworkUtils";

//Other

function AssignGateway(props) {
	const { t } = useTranslation();
	const makeRequest = useMakeRequest();

	// Recoil Atoms State
	const setLoading = useSetRecoilState(loadingAtom);
	const selectedBuilding = useRecoilValue(selectedBuildingAtom);

	// Component State
	const [selectedGateway, setSelectedGateway] = useState();
	const [availableGateways, setAvailableGateways] = useState(null);

	const getUnassignedGateways = useCallback(
		async (currentGateway) => {
			console.log("Load");
			try {
				setLoading({ visible: true, message: t("pageBuildingMan.updatingGateways") });
				const gateways = await makeRequest("get", GET_UNASSIGNED_GATEWAYS, null);
				currentGateway ? setAvailableGateways([currentGateway, ...gateways]) : setAvailableGateways(gateways);
				if (gateways.length === 0 || !selectedGateway) props.handleAssignGatewayBttn(true);
			} catch (e) {
				console.error(e);
			} finally {
				setLoading({ visible: false, message: "" });
			}
		},
		[makeRequest, setLoading, setAvailableGateways, t]
	);

	useEffect(() => {
		setSelectedGateway(props.currentGateway ? props.currentGateway : null);
		if (!props.currentGateway || selectedGateway !== props.currentGateway)
			getUnassignedGateways(props.currentGateway);
	}, [getUnassignedGateways, setSelectedGateway, props.currentGateway]);

	const handleSelection = (value) => {
		setSelectedGateway(value);
		props.showMessage && props.handleAssignGatewayBttn(false);

		if (props.handleSelectGateway) {
			props.handleSelectGateway(value);
		}
	};

	const formatUnassignedGateways = (option) => {
		return (
			<span>
				{option.hardwareId} (<span>{option.scenecom.length}</span> / <span>{option.scenecomLicence * 5}</span>)
			</span>
		);
	};

	const formatUnassignedGatewaysValue = (option, props) => {
		return option ? formatUnassignedGateways(option) : props.placeholder;
	};

	return availableGateways === null ? (
		""
	) : availableGateways.length <= 0 ? (
		<p>{t("pageBuildingMan.dialog.assignGateway.none")}</p>
	) : (
		<>
			{props.showMessage && (
				<p className="marginBottomSmall">
					{t("pageBuildingMan.dialog.assignGateway.message", { building: selectedBuilding.name })}
				</p>
			)}
			<Dropdown
				name="assignGateway"
				value={selectedGateway}
				options={availableGateways}
				optionLabel="id"
				itemTemplate={formatUnassignedGateways}
				valueTemplate={formatUnassignedGatewaysValue}
				onChange={(e) => handleSelection(e.value)}
				placeholder="Select a Gateway"
			/>
		</>
	);
}

export default AssignGateway;
