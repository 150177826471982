export const scenecomCapacityTemplate = (scenecomLicence, scenecomCount, scenecomLoad, scenecomCapacity) => {
	let icon = "pi-check-circle";

	const scenecomLicenceCount = scenecomLicence * 5;

	if (scenecomCapacity === "error") {
		icon = "pi-times-circle";
	} else if (scenecomCapacity === "warn") {
		icon = "pi-exclamation-circle";
	}

	return (
		<span className={`withIcon withTooltip`} aria-label={`${scenecomLoad}%`}>
			<i className={`pi ${icon} ${scenecomCapacity}`} />
			{scenecomCount} / {scenecomLicenceCount}
		</span>
	);
};

export const scenecomCapacityFilterTemplate = (option) => {
	let icon = "pi-check-circle";

	if (option.value === "error") {
		icon = "pi-times-circle";
	} else if (option.value === "warn") {
		icon = "pi-exclamation-circle";
	}

	return (
		<span className={`withIcon`}>
			<i className={`pi ${icon} ${option.value}`} />
			{option.label}
		</span>
	);
};
