//Node Modules
import React, { useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../../components/general";
import { InputName, InputType, InputUsername, InputPasswordConfirm, InputApiKey } from "../../../components/account";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { loadingAtom } from "../../../atoms/LoadingAtom";
import { toastAtom } from "../../../atoms/ToastAtom";

//Services

//Helpers
import { CREATE_ACCOUNT, REFRESH_CLOUD_NETWORKS, REFRESH_CLOUD_DEVICES } from "../../../helpers/Constants";
import { useMakeRequest } from "../../../helpers/NetworkUtils";

//Other
import { nav } from "../../../config/navigation";

function AccountCreate() {
	const { t } = useTranslation();
	const hiddenSubmit = useRef();
	const navigate = useNavigate();

	// Atom States
	const setLoading = useSetRecoilState(loadingAtom);
	const [toast, setToast] = useRecoilState(toastAtom);

	const makeRequest = useMakeRequest();

	// React Hook Form
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
		control,
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	// On Form Submit
	const onSubmit = async (data) => {
		try {
			setLoading({
				visible: true,
				message: t("pageAccountCreate.loadingCreate"),
			});
			// Encrypt the password before sending
			const key = process.env.REACT_APP_CRYPTO_KEY;
			const encryptedPassword = CryptoJS.AES.encrypt(data.password, key).toString();
			data.password = encryptedPassword;

			// Create the account
			delete data.passwordConfirm;
			await makeRequest("post", CREATE_ACCOUNT, data);
			await refreshCloudData();
			navigate(nav.admin.account.management);
			setToast({
				...toast,
				severity: "success",
				summary: t("pageAccountCreate.toast.successSummary"),
				detail: t("pageAccountCreate.toast.successDetail"),
			});
		} catch (err) {
			console.error("AccountCreate onSubmit Error:", err.response.data);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageAccountCreate.toast.errorSummary"),
				detail: t(`pageAccountCreate.toast.errorDetail.${err.response.data.errorType}`, {
					data: data[err.response.data.errorField],
					field: err.response.data.errorField,
				}),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const refreshCloudData = async () => {
		try {
			await makeRequest("get", REFRESH_CLOUD_NETWORKS, null);
			await makeRequest("get", REFRESH_CLOUD_DEVICES, null);
		} catch (e) {
			console.error(e);
			setToast({
				...toast,
				severity: "error",
				summary: t("common.toast.loadDataFailedSummary"),
				detail: t("common.toast.loadDataFailedDetail"),
			});
		}
	};

	// Page Buttons
	const createBttn = (
		<Button
			className="feature"
			label={t("pageAccountCreate.featureBttn")}
			icon="pi pi-save"
			onClick={() => hiddenSubmit?.current?.click()}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageAccountCreate.pageHeader")} />
			<PageHeader title={t("pageAccountCreate.pageHeader")} featureBttn={createBttn} enableBack />
			<main>
				<div className="mainContent">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="card">
							<div className="formFieldsWrapper">
								<InputName register={register} errors={errors} />
								<InputType register={register} errors={errors} control={control} />
								<InputApiKey register={register} errors={errors} />
								<InputUsername register={register} errors={errors} />
								<InputPasswordConfirm register={register} errors={errors} values={getValues} />
							</div>
						</div>

						<button type="submit" hidden={true} ref={hiddenSubmit} />
					</form>
				</div>
			</main>
		</>
	);
}

export default AccountCreate;
