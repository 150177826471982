//Transform filter selection string into array of devices, buildings or network
export const formatFilter = (query) => {
	const preFormatQuery = query.slice(1, -1);
	return preFormatQuery.split("','");
};

//Map list of devices, buildings or network to its readable names
export const getQueryNames = (query, payload) => {
	return query.map((id) => {
		const foundItem = payload.find((item) => String(item.id) === id);
		return { id: foundItem.id, name: foundItem.name };
	});
};

//Filter Selection
export const filterSelection = {
	device: "unit_id",
	building: "building_id",
	network: "network_id",
	all: "unit_id",
};
