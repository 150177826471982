//Node Modules
import { useCallback } from "react";
import { useRecoilValue } from "recoil";

//Atoms
import { reportFilterAtom } from "../../atoms/ReportAtom";

import { useMakeRequest } from "../../helpers/NetworkUtils";
import { GET_REPORT_DATA, GET_DEVICE_NAMES_FOR_FORM } from "../../helpers/Constants";

import { filterSelection } from "../reports/filter";

export const useAddSeries = () => {
	const reportFilter = useRecoilValue(reportFilterAtom);
	const makeRequest = useMakeRequest();

	const onAddSeries = useCallback(
		async (id, data) => {
			const formattedUnits =
				data.filterSelection && "'" + data.filterSelection.map((u) => u.id).join("','") + "'";
			const params = {
				measureName: data.reportType,
				from: reportFilter.dateRange[0].toISOString(),
				to: reportFilter.dateRange[1].toISOString(),
				interval: reportFilter.interval ? reportFilter.interval : reportFilter.intervalCustom * 60,
				aggregate_type: reportFilter.interval === -1 ? "ALL" : data.aggregate,
				units: data.filterBy === "device" ? formattedUnits : null,
				networks: data.filterBy === "network" ? formattedUnits : null,
				buildings: data.filterBy === "building" ? formattedUnits : null,
			};

			process.env.NODE_ENV === "development" && console.log("Report Params:", params);

			try {
				const res = await makeRequest("post", GET_REPORT_DATA, params);

				//If there is no aggregation, add readable labels to devices, buildings or networks (Datatable)
				if (reportFilter.interval === -1 && Array.isArray(res)) {
					//Reuse readable filter names from form dropdown
					let filterReadableNames = data.filterSelection;
					//if user selected all, it doesn't have the previous information, therefore it must call and get the readable names
					if (data.filterBy === "all" && res) {
						const formattedUnits = res.map((u) => u.unit_id);
						filterReadableNames = await makeRequest("post", GET_DEVICE_NAMES_FOR_FORM, formattedUnits);
					}
					//Type of filter
					const filterType = filterSelection[data.filterBy];
					//Add label name
					res.forEach((obj) => {
						obj.label = filterReadableNames.find(
							(unit) =>
								unit.id === (filterType === "network_id" ? obj[filterType] : parseInt(obj[filterType]))
						);
					});
				}

				const newData = {
					id: id,
					data: res,
					name: data.seriesName,
					type: data.reportType,
					position: data.position,
					filterType: data.filterBy,
					aggregate_type: reportFilter.interval,
				};
				return newData;
			} catch (e) {
				process.env.NODE_ENV === "development" && console.error("useAddSeries", e);
			}
		},
		[makeRequest, reportFilter]
	);

	return onAddSeries;
};
