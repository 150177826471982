//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useSetRecoilState, useRecoilValue, useRecoilState, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

//Pages

//BinaryForge Components
import { PageHeader } from "../../components/general";
import ReportFilter from "../../components/charts/ReportFilter";
import ReportSeries from "../../components/charts/ReportSeries";
import { CustomViewComponent } from "../../components/charts";
import PresetSelectDialog from "../../components/reports/PresetSelectDialog";
import { AddReportForm } from "../../components/reports";

//atoms
import {
	reportTypeOptionsAtom,
	reportSeriesSelectorFamily,
	reportSeriesIdAtom,
	reportFilterAtom,
	reportTableFormattedDataAtom,
	reportDataAtom,
	customReportAddButtonStatus,
} from "../../atoms/ReportAtom";
import { presetSelectDialogAtom } from "../../atoms/DialogAtom";
import { userAtom } from "../../atoms/UserAtom";
import { loadingAtom } from "../../atoms/LoadingAtom";
import { toastAtom } from "../../atoms/ToastAtom";

//3rd Party Components
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

//Helpers
import { useApiRequest, useMakeRequest } from "../../helpers/NetworkUtils";
import { GET_DEVICES_TYPE, SAVE_PRESET_REPORT } from "../../helpers/Constants";
import { useHandleExport } from "../../helpers/ExportUtils";
import { getDatesInterval } from "../../helpers/DateUtils";

function Report() {
	const { t } = useTranslation();
	const hiddenSubmit = useRef();
	const moreBttnMenu = useRef(null);
	const appElement = document.getElementById("appWrapper");

	// BF Hooks
	const { data } = useApiRequest("get", GET_DEVICES_TYPE, null);
	const makeRequest = useMakeRequest();
	const handleFileExport = useHandleExport();

	// Atoms
	const user = useRecoilValue(userAtom);
	const setReportTypeOptions = useSetRecoilState(reportTypeOptionsAtom);
	const seriesId = useRecoilValue(reportSeriesIdAtom);
	const series = useRecoilValue(reportSeriesSelectorFamily(seriesId));
	const setShowDialog = useSetRecoilState(presetSelectDialogAtom);
	const reportFilter = useRecoilValue(reportFilterAtom);
	const setLoading = useSetRecoilState(loadingAtom);
	const [toast, setToast] = useRecoilState(toastAtom);

	const resetData = useResetRecoilState(reportDataAtom);
	const resetSeriesId = useResetRecoilState(reportSeriesIdAtom);
	const resetReportFilter = useResetRecoilState(reportFilterAtom);
	const resetDateRangeCustomErrorMsg = useResetRecoilState(customReportAddButtonStatus);

	// Local State
	const [viewType, setViewType] = useState(true);
	const [savedReport, setSavedReport] = useState(false);

	// Table State
	const tableFormattedData = useRecoilValue(reportTableFormattedDataAtom);

	const defaultValues = {
		reportName: "",
		description: "",
		privacy: "public",
	};

	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
		getValues,
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues,
	});

	//It formats query series to be load from report presets page.
	const prepareQueryReport = (data) => {
		const { filterSelection, reportType, filterBy, position } = data;
		const formattedUnits = filterSelection && "'" + filterSelection.map((u) => u.id).join("','") + "'";
		const formattedData = getDatesInterval(reportFilter.dateRange);

		const params = {
			seriesName: data.seriesName,
			measureName: reportType,
			timeRange: formattedData,
			interval: reportFilter.interval ? reportFilter.interval : reportFilter.intervalCustom * 60,
			aggregate_type: reportFilter.interval === -1 ? "ALL" : data.aggregate,
			units: filterBy === "device" ? formattedUnits : null,
			networks: filterBy === "network" ? formattedUnits : null,
			buildings: filterBy === "building" ? formattedUnits : null,
			filterBy: filterBy,
			position,
		};
		process.env.NODE_ENV === "development" && console.log("Report Params:", params);
		return params;
	};

	const handleSaveReport = async (formValues) => {
		try {
			//Prepare query to be saved into DB
			const query = series.map((obj) => prepareQueryReport(obj));
			const body = {
				name: formValues.reportName,
				description: formValues.description,
				private: formValues.privacy === "private",
				user: user.username,
				query,
			};
			setLoading({ visible: true, message: t("pageReport.savingReport") });
			//Save report into pushfusion-reports
			await makeRequest("post", SAVE_PRESET_REPORT, body);
			setToast({
				...toast,
				severity: "success",
				summary: t("pageReport.toast.successSaving"),
				detail: "",
			});
			//Reset form after saving it
			reset();
			//Close the dialog
			setSavedReport(true);
			setShowDialog(false);
		} catch (error) {
			console.error("error :", error);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageReport.toast.failedSaving"),
				detail: "",
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	//Save report button
	const savePresetBttn = (
		<Button
			className="feature"
			label={t("pageReport.featureBttn")}
			icon="pi pi-save"
			onClick={() => setShowDialog(true)}
			disabled={savedReport ? true : false}
		/>
	);

	const moreBttnItems = [
		{
			label: "View",
			items: [
				{
					label: viewType ? t("common.toggleTable") : t("common.toggleGraph"),
					icon: viewType ? "pi pi-table" : "pi pi-chart-bar",
					command: () => setViewType(!viewType),
				},
			],
		},
		!viewType && {
			label: "Export",
			items: [
				{
					label: "PDF",
					icon: "pi pi-file-pdf",
					command: () => handleFileExport("pdf", "Custom Report"),
					disabled: !viewType && tableFormattedData ? false : true,
				},
				{
					label: "Excel",
					icon: "pi pi-file-excel",
					command: () => handleFileExport("excel", "Custom Report"),
					disabled: !viewType && tableFormattedData ? false : true,
				},
				{
					label: "CSV",
					icon: "pi pi-file",
					command: () => handleFileExport("csv", "Custom Report"),
					disabled: !viewType && tableFormattedData ? false : true,
				},
			],
		},
	];

	const moreBttn = (
		<>
			<Button
				label={t("pageAccountMan.secondaryBttn")}
				icon="pi pi-angle-down"
				onClick={(event) => moreBttnMenu.current.toggle(event)}
				aria-label="More Actions"
				aria-controls="bttnMenu"
				aria-haspopup
			/>
			<Menu id="bttnMenu" popup ref={moreBttnMenu} model={moreBttnItems} appendTo={appElement} />
		</>
	);

	const dialogFooter = (
		<>
			<button onClick={() => setShowDialog(false)}>{t("common.cancel")}</button>
			<button
				className="feature"
				onClick={() => {
					hiddenSubmit?.current?.click();
				}}>
				{t("pageReport.saveReportForm.saveReportButton")}
			</button>
		</>
	);

	//Fetching report types from timestream down
	// useEffect(() => {
	// 	if (data && data.length) {
	// 		setReportTypeOptions(data.map(({ measure_name }) => measure_name).sort());
	// 	}
	// }, [data, setReportTypeOptions]);

	// //If user interacts with saved report, enable button again
	useEffect(() => {
		setSavedReport(false);
	}, [seriesId, series]);

	//Reset state on component unmount
	useEffect(() => {
		return () => {
			resetData();
			resetSeriesId();
			resetReportFilter();
			resetDateRangeCustomErrorMsg();
		};
	}, [resetData, resetSeriesId, resetReportFilter, resetDateRangeCustomErrorMsg]);

	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="small error">{errors[name].message}</span>;
	};

	return (
		<>
			<PageHeader
				title={t("pageReport.pageHeader")}
				subtitle={t("pageReport.pageSubHeader")}
				featureBttn={series.length > 0 && savePresetBttn}
				secondaryBttn={series.length > 0 && moreBttn}
			/>
			<main>
				<div className="mainContent">
					<div className="grid column2-1 gapLarge">
						<div className="card">
							<ReportFilter />
						</div>
						<div className="card">
							<ReportSeries />
						</div>
					</div>
					<CustomViewComponent isGraphView={viewType} />
				</div>
			</main>
			<PresetSelectDialog header={t("pageReport.featureBttn")} footer={dialogFooter}>
				<form onSubmit={handleSubmit(handleSaveReport)}>
					<AddReportForm control={control} getFormErrorMessage={getFormErrorMessage} getValues={getValues} />
					<button type="submit" hidden={true} ref={hiddenSubmit} />
				</form>
			</PresetSelectDialog>
		</>
	);
}

export default Report;
