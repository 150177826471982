import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";

const DatatableSkeleton = ({ columns }) => {
	const rows = Array.from({ length: 6 }, (v, i) => i);

	const skeletonTemplate = () => {
		return <Skeleton></Skeleton>;
	};

	return (
		<>
			<DataTable value={rows}>
				{columns.map((c, i) => {
					return <Column key={i} field={c} header={c} body={skeletonTemplate}></Column>;
				})}
			</DataTable>
			<div className="paginatorSkeleton jContent-spaceBetween">
				<Skeleton width="15%"></Skeleton>
				<Skeleton width="25%"></Skeleton>
			</div>
		</>
	);
};

export default DatatableSkeleton;
