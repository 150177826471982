import { useTranslation } from "react-i18next";
import { Skeleton } from "primereact/skeleton";

const UserDetailsSkeleton = () => {
	const { t } = useTranslation();

	return (
		<div className="grid column3 gapColMedium">
			<div className="grid columnLabel gapColMedium gapRowXSmall aItemsCenter aContentStart">
				<span>{t("user.details.form.fullname")}:</span>
				<span>
					<Skeleton width="75%" />
				</span>
				<span>{t("user.details.form.email")}:</span>
				<span>
					<Skeleton width="85%" />
				</span>
			</div>
			<div className="grid columnLabel gapColMedium gapRowXSmall aItemsCenter aContentStart">
				<span>{t("user.details.form.active")}:</span>
				<span>
					<Skeleton width="45%" />
				</span>
				<span>{t("user.details.form.status")}:</span>
				<span>
					<Skeleton width="85%" />
				</span>
				<span>{t("user.details.form.mfa")}:</span>
				<span>
					<Skeleton width="65%" />
				</span>
			</div>
			<div className="grid columnLabel gapColMedium gapRowXSmall aItemsCenter aContentStart">
				<span>{t("common.table.createdAt")}:</span>
				<span>
					<Skeleton width="75%" />
				</span>
				<span>{t("common.table.updatedAt")}:</span>
				<span>
					<Skeleton width="85%" />
				</span>
			</div>
		</div>
	);
};

export default UserDetailsSkeleton;
