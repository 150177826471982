import React from "react";
import { useTranslation } from "react-i18next";

function InputLat(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, disabled, updateMap } = props;

	const handleUpdate = (e) => {
		updateMap(e.target.name, e.target.value);
	};

	return (
		<div className="formField">
			<label htmlFor="latitude">{t("pageBuildingEdit.form.label.latitude")}</label>
			<input
				type="text"
				defaultValue={defaultValue}
				disabled={disabled}
				className={errors.latitude ? "error" : ""}
				{...register("latitude", { required: true, min: -90, max: 90, pattern: /^-?\d*\.?\d*$/ })}
				onBlur={handleUpdate}
			/>
			<ul className="errors">
				{errors?.latitude?.types?.required && <li className="error">{t("pageBuildingEdit.form.error.latitude.required")}</li>}
				{errors?.latitude?.types?.min && <li className="error">{t("pageBuildingEdit.form.error.latitude.min")}</li>}
				{errors?.latitude?.types?.max && <li className="error">{t("pageBuildingEdit.form.error.latitude.max")}</li>}
				{errors?.latitude?.types?.pattern && <li className="error">{t("pageBuildingEdit.form.error.latitude.pattern")}</li>}
			</ul>
		</div>
	);
}

export default InputLat;
