//Node Modules
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

//Pages

//BinaryForge Components
import { PageHeader, MessageIcon } from "../../../components/general";

//3rd Party Components
import { Button } from "primereact";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms

//Helpers
import { useApiRequest } from "../../../helpers/Api";

//Other
import UserForm from "../../../components/user/UserForm";
import { nav } from "../../../config/navigation";

const UserCreate = () => {
	//Hooks
	const { t } = useTranslation();
	const formRef = useRef();
	const navigate = useNavigate();
	const apiRequest = useApiRequest();

	const featureBttn = (
		<Button
			className="feature"
			label={t("user.userCreate.featureBttn")}
			icon="pi pi-save"
			onClick={() => formRef.current.initUserUpdate()}
		/>
	);

	const addUserToGroup = async (email) => {
		try {
			await apiRequest("post", "/admin/user/import", { email: email }, t(`user.userCreate.dupeUser.loading`));
			navigate(nav.admin.user.management);
		} catch (err) {
			console.error(err);
		}
	};

	const confirmAddUser = (email) => {
		confirmDialog({
			header: t("user.userCreate.dupeUser.header"),
			message: (
				<>
					<MessageIcon
						messageKey={t("user.userCreate.dupeUser.message", { email: email })}
						icon="pi pi-info-circle info"
					/>
					<MessageIcon
						wrapperStyle="marginTopSmall"
						messageKey={t("user.userCreate.dupeUser.warning")}
						messageStyle="small"
						icon="pi pi-exclamation-triangle warn"
					/>
				</>
			),
			acceptLabel: t("user.userCreate.dupeUser.accept"),
			rejectLabel: t("user.userCreate.dupeUser.reject"),
			acceptIcon: "pi pi-user-plus",
			acceptClassName: "success feature",
			rejectClassName: "error",
			accept: () => addUserToGroup(email),
			reject: () => {}, //Do Nothing,
		});
	};

	return (
		<>
			<PageHeader title={t("user.userCreate.pageTitle")} enableBack featureBttn={featureBttn} />
			<main>
				<div className="mainContent">
					<div className="card">
						<UserForm type="userCreate" ref={formRef} dupeUser={confirmAddUser} />
					</div>
				</div>
			</main>
		</>
	);
};

export default UserCreate;
