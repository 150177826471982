//Emergency
export const GET_BUILDINGS_WITH_SUMMARY = "/emergency/summary/get-buildings-with-summary";
export const GET_TEST_BOOK = "/emergency/testbook/get-for-building";
export const GET_ALL_BUILDINGS_WITH_DURATION_SUMMARY = "/emergency/summary/get-buildings-with-duration";

//Casambi
export const GET_ALL_NETWORKS = "/devices/network/get-all";
export const REFRESH_CLOUD_NETWORKS = "/vendors/casambi/cloud/get-network-sessions";
export const GET_BUILDINGS_HAS_DEVICES = "/building/get-has-devices";
export const REFRESH_CLOUD_DEVICES = "/vendors/casambi/cloud/get-network-info";
export const GET_DEVICES_BY_BUILDING = "/devices/get-by-building";
export const getDevicesState = ({ networkId, deviceId }) => {
	return `/vendors/casambi/cloud/${networkId}/device/${deviceId}/get-state`;
};
export const GET_ALL_BUILDINGS = "/building/get-all-map";
export const assignNetworkBuilding = (networkId) => {
	return `/devices/network/${networkId}/building`;
};
export const GET_DEVICE_NAMES_FOR_FORM = "/devices/get-names";

//Building
export const VERB_BUILDING = "/admin/building";
export const GET_BUILDINGS = "/building/get-all";
export const CREATE_BUILDING = "/admin/building/create";
export const UPDATE_BUILDING = "/building";

//Gateway
export const GET_UNASSIGNED_GATEWAYS = "/admin/gateway/get-unassigned";
export const assignGatewayToBuilding = (buildingId, gatewayId) => {
	return `/admin/gateway/${gatewayId}/assign-building/${buildingId}`;
};
export const gatewayLicenceRequest = (gatewayId) => {
	return `/admin/gateway/${gatewayId}/request-licence`;
};

//Accounts
export const GET_ALL_ACCOUNTS = "/admin/account/get-all";
export const CREATE_ACCOUNT = "/admin/account/create";
export const GET_ALL_ACCOUNT_TYPES = "/admin/account/account-type/get-all";
export const VERB_ACCOUNT = "/admin/account";

//Reports
export const VERB_REPORT_PRESET = "/reports/preset";
export const GET_REPORT_DATA = "/analytics/report/getdata";
export const getReportTypeDevices = (reportType) => {
	return `/analytics/report/${reportType}/get-devices`;
};
export const GET_DEVICES_TYPE = "/analytics/report/get-report-types";
export const GET_PRESET_REPORTS = "/reports/get-all-preset-reports";
export const SAVE_PRESET_REPORT = "/reports/save-report";
