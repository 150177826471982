//Node Modules
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { useTranslation, Trans } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

//Pages

//BinaryForge Components
import { PageHeader } from "../../components/general";
import PresetSelectDialog from "../../components/reports/PresetSelectDialog";

//3rd Party Components
import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";
import { Menu } from "primereact/menu";
import { Tag } from "primereact/tag";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { toastAtom } from "../../atoms/ToastAtom";
import { presetSelectDialogAtom } from "../../atoms/DialogAtom";
import { reportTableFormattedDataAtom } from "../../atoms/ReportAtom";
import { userAtom } from "../../atoms/UserAtom";

//Helpers
// import { presets } from "../../helpers/chart/presets";
import { GET_REPORT_DATA, GET_PRESET_REPORTS, VERB_REPORT_PRESET } from "../../helpers/Constants";
import { useMakeRequest } from "../../helpers/NetworkUtils";
import { PresetView } from "../../components/reports";
import { useHandleExport, buildFiltersNew } from "../../helpers/ExportUtils";
import { formatDateByInterval } from "../../helpers/DateUtils";
import { formatFilter, getQueryNames, filterSelection } from "../../helpers/reports/filter";
import { GET_DEVICE_NAMES_FOR_FORM, GET_BUILDINGS, GET_ALL_NETWORKS } from "../../helpers/Constants";

function ReportPreset() {
	const { t } = useTranslation();
	const makeRequest = useMakeRequest();
	const moreBttnMenu = useRef(null);

	//Recoil
	const setLoading = useSetRecoilState(loadingAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const setShowDialog = useSetRecoilState(presetSelectDialogAtom);
	const user = useRecoilValue(userAtom);

	//Table state
	const tableFormattedData = useRecoilValue(reportTableFormattedDataAtom);

	const appElement = document.getElementById("appWrapper");

	//State
	const [selectedReport, setSelectedReport] = useState();
	const [preset, setPreset] = useState(null);
	const [viewType, setViewType] = useState(true);
	const [presets, setPresets] = useState([]);

	const handleFileExport = useHandleExport();
	// const handleFileExport = async (type) => {
	// 	const tableName = preset.name;
	// 	const tableResource = "common.state";
	// 	const columns = dt.current.props.children.map((p) => p.props.header);
	// 	const fields = dt.current.props.children.map((p) => p.props.field);
	// 	const filters = buildFiltersNew(dt.current.state.filters, "pageReportPreset", "common.state");
	// 	const baseData = filteredData ? filteredData : buildings;

	// 	handleFileExport(
	// 		type,
	// 		tableName,
	// 		{ baseData: baseData, filters: filters, columns: columns, fields: fields },
	// 		tableResource
	// 	);
	// };

	const getPresetReports = useCallback(async () => {
		try {
			setLoading({ visible: true, message: t("pageReportPreset.loadingReports") });
			const res = await makeRequest("post", GET_PRESET_REPORTS, { user: user.username });
			const updatedSeries = res.map((report) => {
				report.query.map((series) => {
					const dateRange = formatDateByInterval(series.timeRange);
					series.from = dateRange[0].toISOString();
					series.to = dateRange[1].toISOString();
					series.id = uuidv4();
					return series;
				});

				return report;
			});

			setPresets(updatedSeries);
		} catch (error) {
			console.error("error", error);
		} finally {
			setLoading({ visible: false, message: "" });
		}
	}, [makeRequest, setLoading, t, user.username]);

	const moreBttnItems = [
		{
			label: "View",
			items: [
				{
					label: viewType ? t("common.toggleTable") : t("common.toggleGraph"),
					icon: viewType ? "pi pi-table" : "pi pi-chart-bar",
					command: () => setViewType(!viewType),
				},
			],
		},
		!viewType && {
			label: "Export",
			items: [
				{
					label: "PDF",
					icon: "pi pi-file-pdf",
					command: () => handleFileExport("pdf", preset.name),
					disabled: !viewType && tableFormattedData ? false : true,
				},
				{
					label: "Excel",
					icon: "pi pi-file-excel",
					command: () => handleFileExport("excel", preset.name),
					disabled: !viewType && tableFormattedData ? false : true,
				},
				{
					label: "CSV",
					icon: "pi pi-file",
					command: () => handleFileExport("csv", preset.name),
					disabled: !viewType && tableFormattedData ? false : true,
				},
			],
		},
	];

	const moreBttn = (
		<>
			<Button
				label={t("pageAccountMan.secondaryBttn")}
				icon="pi pi-angle-down"
				onClick={(event) => moreBttnMenu.current.toggle(event)}
				aria-label="More Actions"
				aria-controls="bttnMenu"
				aria-haspopup
			/>
			<Menu id="bttnMenu" popup ref={moreBttnMenu} model={moreBttnItems} appendTo={appElement} />
		</>
	);

	const getFilterSelection = useCallback(
		async (query) => {
			const filterData = [];
			try {
				const getFilterData = query.map(async (query) => {
					let filterType;
					if (query.buildings) {
						filterType = t("pageReport.filterBy.buildings");
						const buildingsArray = formatFilter(query.buildings);
						const resp = await makeRequest("get", GET_BUILDINGS, null);
						const buildings = getQueryNames(buildingsArray, resp);
						filterData.push({
							id: query.id,
							filterType,
							dropdown: buildings,
							seriesName: query.seriesName,
						});
					} else if (query.networks) {
						filterType = t("pageReport.filterBy.networks");
						const networksArray = formatFilter(query.networks);
						const resp = await makeRequest("get", GET_ALL_NETWORKS, null);
						const networks = getQueryNames(networksArray, resp);
						filterData.push({ id: query.id, filterType, dropdown: networks, seriesName: query.seriesName });
					} else if (query.units) {
						filterType = t("pageReport.filterBy.devices");
						const devicesArray = formatFilter(query.units);
						const devices = await makeRequest("post", GET_DEVICE_NAMES_FOR_FORM, devicesArray);
						filterData.push({ id: query.id, filterType, dropdown: devices, seriesName: query.seriesName });
					} else {
						filterType = t("pageReport.filterBy.all");
						filterData.push({ id: query.id, filterType, dropdown: null, seriesName: query.seriesName });
					}
				});
				await Promise.all(getFilterData);
				return filterData;
			} catch (error) {
				console.log("error getFilterSelection", error);
			}
		},
		[makeRequest, t]
	);

	//Get data for graph
	const getData = useCallback(
		async (preset, filteredData) => {
			const plotData = [];
			const dates = [];
			try {
				const getQueryData = preset.query.map(async (series) => {
					const res = await makeRequest("post", GET_REPORT_DATA, series);
					if (res.length) {
						if (res[0].timestamp) {
							dates.push(res[0].timestamp);
							dates.push(res[res.length - 1].timestamp);
						} else {
							res[0].timestamp = dates[0];
							res.push({
								timestamp: dates[1],
								measure_value: res[0].measure_value,
							});
						}
					}

					//If there is no aggregation, add readable labels to devices, buildings or networks (Datatable)
					if (series.interval === -1 && Array.isArray(res)) {
						//Reuse readable filter names from form dropdown
						let filterReadableNames = filteredData.filter(({ id }) => id === series.id);
						filterReadableNames = filterReadableNames[0].dropdown;

						//if user selected all, it doesn't have the previous information, therefore it must call and get the readable names
						if (series.filterBy === "all" && res) {
							const formattedUnits = res.map((u) => u.unit_id);
							filterReadableNames = await makeRequest("post", GET_DEVICE_NAMES_FOR_FORM, formattedUnits);
						}

						//Type of filter
						const filterType = filterSelection[series.filterBy];
						//Add label name
						res.forEach((obj) => {
							obj.label = filterReadableNames.find(
								(unit) =>
									unit.id ===
									(filterType === "network_id" ? obj[filterType] : parseInt(obj[filterType]))
							);
						});
					}

					const newData = {
						id: uuidv4(),
						data: res,
						name: series.seriesName,
						type: series.measureName,
						filterType: series.filterBy,
						aggregate_type: series.interval,
						position: series.position,
					};

					plotData.push(newData);
					return series;
				});
				await Promise.all(getQueryData);
				return plotData;
			} catch (e) {
				console.error("from preset", e);
			}
		},
		[makeRequest]
	);

	const dialogFooter = (
		<>
			<button onClick={() => setShowDialog(false)}>{t("common.cancel")}</button>
			<button
				className="error"
				disabled={!selectedReport || selectedReport.user !== user.username}
				onClick={() => {
					confirmDeletePreset();
				}}>
				{t("pageReportPreset.dialog.footer.delete")}
			</button>
			<button
				className="feature"
				onClick={() => {
					setPreset(selectedReport);
					setShowDialog(false);
				}}>
				{t("pageReportPreset.dialog.footer.yes")}
			</button>
		</>
	);

	const confirmDeletePreset = () => {
		confirmDialog({
			header: t("pageReportPreset.delete.confirm.header"),
			message: (
				<Trans
					i18nKey="pageReportPreset.delete.confirm.message"
					values={{ report: selectedReport.name }}
					components={{ note: <p className="light small" />, bold: <strong /> }}
				/>
			),
			acceptLabel: t("pageReportPreset.delete.confirm.accept"),
			rejectLabel: t("pageReportPreset.delete.confirm.reject"),
			acceptIcon: "pi pi-trash",
			rejectIcon: "",
			acceptClassName: "error",
			accept: () => handleDeletePreset(),
			reject: () => console.log("Do Nothing"),
		});
	};

	const handleDeletePreset = async () => {
		setLoading({ visible: true, message: t("pageReportPreset.delete.loading") });

		try {
			await makeRequest("del", `${VERB_REPORT_PRESET}/${selectedReport.id}`, {
				user: user.username,
			});

			setSelectedReport(null);
			setPreset(null);
			await getPresetReports();

			setToast({
				...toast,
				severity: "success",
				summary: t("pageReportPreset.toast.deleteSuccessSummary"),
				detail: t("pageReportPreset.toast.deleteSuccessDetail"),
			});
		} catch (err) {
			console.error("Delete Preset Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageReportPreset.toast.deleteErrorSummary"),
				detail: t("common.toast.errorDetail", { err: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const presetSelectTemplate = (option) => {
		const { name, description, private: level } = option;
		const reportLevel = level ? "private" : "public";

		return (
			<div>
				<div className="grid column2 autoColumns jSpaceBetween">
					<p className="bold">{name}</p>
					<Tag
						className={`p-tag ${reportLevel}`}
						icon={`pi ${level ? "pi-lock" : "pi-users"}`}
						value={t(`pageReportPreset.${reportLevel}`).toLowerCase()}></Tag>
				</div>
				<p className="noMargin" style={{ width: "60ch" }}>
					{description}
				</p>
			</div>
		);
	};

	const handleSelectReport = (e) => {
		if (e) {
			setSelectedReport(e);
		}
	};

	const selectPresetBttn = (
		<Button
			className="feature"
			label={t("pageReportPreset.featureBttn")}
			icon="pi pi-chart-line"
			onClick={() => setShowDialog(true)}
		/>
	);

	//Load saved reports from pushfusion-reports
	useEffect(() => {
		if (user.username) {
			getPresetReports();
		}
	}, [user, makeRequest, setLoading, t, getPresetReports]);

	return (
		<>
			<PageHeader
				title={t("pageReportPreset.pageHeader")}
				subtitle={t("pageReportPreset.pageSubHeader")}
				featureBttn={selectPresetBttn}
				secondaryBttn={preset && moreBttn}
			/>
			<main>
				<div className="mainContent">
					<PresetView
						getData={getData}
						preset={preset}
						isGraphView={viewType}
						getFilterSelection={getFilterSelection}
					/>
				</div>
			</main>
			<PresetSelectDialog header={t("pageReportPreset.dialog.header")} footer={dialogFooter}>
				<ListBox
					value={selectedReport}
					options={presets}
					optionLabel="name"
					itemTemplate={presetSelectTemplate}
					onChange={(e) => handleSelectReport(e.value)}
					listStyle={{ maxHeight: "50vh" }}
					filter
				/>
			</PresetSelectDialog>
		</>
	);
}

export default ReportPreset;
