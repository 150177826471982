import { atom, atomFamily, selectorFamily } from "recoil";

import { timeRangeIntervals, formatDateByInterval } from "../helpers/DateUtils";

export const reportSeriesIdAtom = atom({
	key: "reportSeriesIdAtom",
	default: [],
});

export const reportSeriesAtomFamily = atomFamily({
	key: "reportSeriesAtomFamily",
	default: {
		name: "",
		reportType: null,
		aggregate: "SUM",
		position: "left",
	},
});

export const reportSeriesSelectorFamily = selectorFamily({
	key: "reportSeriesSelectorFamily",
	get:
		(reportSeriesIds) =>
		({ get }) => {
			const array = [];
			reportSeriesIds.map((id) => {
				const seriesData = get(reportSeriesAtomFamily(id.id));
				array.push(seriesData);
				return seriesData;
			});
			return array;
		},
});

export const reportFilterAtom = atom({
	key: "reportFilterAtom",
	default: {
		dateRange: formatDateByInterval(timeRangeIntervals[2]),
		interval: -1,
		intervalCustom: 1,
	},
});

export const reportDataAtom = atom({
	key: "reportDataAtom",
	default: [],
});

export const reportTypeOptionsAtom = atom({
	key: "reportTypeOptionsAtom",
	default: JSON.parse(process.env.REACT_APP_REPORT_TYPE_OPTIONS),
});

export const reportTableFiltersAtom = atom({
	key: "reportTableFiltersAtom",
	default: null,
});

export const reportTableFilteredDataAtom = atom({
	key: "reportTableFilteredDataAtom",
	default: null,
});

export const reportTableFormattedDataAtom = atom({
	key: "reportTableFormattedDataAtom",
	default: null,
});

export const reportTableColumnsAtom = atom({
	key: "reportTableColumnsAtom",
	default: null,
});

export const customReportAddButtonStatus = atom({
	key: "customReportAddButtonStatus",
	default: false,
});
