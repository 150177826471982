//Node Modules
import React, { useState, useRef, useCallback } from "react";
import { useRecoilState } from "recoil";
import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";

//Atoms
import { selectedBuildingAtom } from "../atoms/BuildingAtom";
import { toastAtom } from "../atoms/ToastAtom";

//Services

//Helpers
import { mapContainerStyle, mapZoom, mapCenter, mapOptions, clusterOptions, getIcon } from "../helpers/MapUtils";

//Other

function Map(props) {
	const { places } = props;
	const { t } = useTranslation();
	const mapRef = useRef();

	const [selectedBuilding, setSelectedBuilding] = useRecoilState(selectedBuildingAtom);
	const [toast, setToast] = useRecoilState(toastAtom);

	const [mapLoaded, setMapLoaded] = useState(false);
	const [selectedSearchBuilding, setSelectedSearchBuilding] = useState(null);
	const [searchFilteredBuildings, setSearchFilteredBuildings] = useState(null);

	const onMapLoad = useCallback(
		(map) => {
			mapRef.current = map;

			if (places) {
				const bounds = new window.google.maps.LatLngBounds();
				places.map((place) => {
					const position = new window.google.maps.LatLng(place.latitude, place.longitude);
					bounds.extend(position);
					return place.id;
				});

				//Center map and adjust Zoom based on the position of all markers.
				mapRef.current.setCenter(bounds.getCenter());
				mapRef.current.fitBounds(bounds);
			}
		},
		[places]
	);

	const onIdleHandler = () => {
		//Only on initial load
		if (!mapLoaded) {
			//If the zoom level is too high then zoom out slightly.
			if (mapRef.current.zoom > 10) {
				mapRef.current.setZoom(10);
			} else if (mapRef.current.zoom < 2) {
				mapRef.current.setZoom(2);
			}
		}
		setMapLoaded(true);
	};

	const markerClickHandler = (event, place) => {
		setSelectedBuilding(place);
	};

	const isSelected = (placeId) => {
		let selected = false;

		if (selectedBuilding) {
			selected = selectedBuilding.id === placeId ? true : false;
		}

		return selected;
	};

	const searchBuildings = (event) => {
		setTimeout(() => {
			let _searchFilteredBuildings;
			if (!event.query.trim().length) {
				_searchFilteredBuildings = [...places];
			} else {
				_searchFilteredBuildings = places.filter((place) => {
					return place.name.toLowerCase().includes(event.query.toLowerCase());
				});
			}

			setSearchFilteredBuildings(_searchFilteredBuildings);
		}, 250);
	};

	const findBuilding = () => {
		if (selectedSearchBuilding.latitude === null || isNaN(selectedSearchBuilding.latitude)) {
			setToast({
				...toast,
				severity: "error",
				summary: t("pageSummary.toast.findErrorSummary"),
				detail: t("pageSummary.toast.findErrorDetail"),
			});
		} else {
			mapRef.current.setCenter({ lat: selectedSearchBuilding.latitude, lng: selectedSearchBuilding.longitude });
			mapRef.current.setZoom(10);
			setSelectedBuilding(selectedSearchBuilding);
		}
	};

	return (
		<div className="card noPadding map">
			<div className="mapSearchWrapper">
				<AutoComplete
					value={selectedSearchBuilding}
					suggestions={searchFilteredBuildings}
					completeMethod={searchBuildings}
					field="name"
					placeholder={t("common.map.searchPlaceholder")}
					onChange={(e) => setSelectedSearchBuilding(e.value)}
				/>
				<Button className="feature" icon="pi pi-search" onClick={() => findBuilding()} />
			</div>

			<GoogleMap
				mapContainerStyle={mapContainerStyle}
				zoom={mapZoom}
				center={mapCenter}
				options={mapOptions}
				onLoad={onMapLoad}
				onIdle={onIdleHandler}>
				<MarkerClusterer options={clusterOptions} averageCenter>
					{(clusterer) =>
						places.map((place) => (
							<Marker
								key={place.id}
								title={place.name}
								position={{ lat: place.latitude, lng: place.longitude }}
								onClick={(event) => markerClickHandler(event, place)}
								icon={{
									url: getIcon(
										place.emergencySummary ? place.emergencySummary.state : "STANDARD",
										isSelected(place.id)
									),
									scaledSize: new window.google.maps.Size(40, 60),
									origin: new window.google.maps.Point(0, 0),
									anchor: new window.google.maps.Point(20, 60),
								}}
								draggable={false}
								clusterer={clusterer}
							/>
						))
					}
				</MarkerClusterer>
			</GoogleMap>
		</div>
	);
}

export default Map;
