import i18n from "../../i18n";

//Other
import { nav } from "../../config/navigation";

//It formats warning and error messages
export const getFormattedMessages = (path, warningArr) => {
	const lf = new Intl.ListFormat(i18n.language);
	const { noData, maxData, error } = warningArr;
	const warnings = [];

	noData.length > 0 &&
		path === nav.report.preset &&
		warnings.push({
			type: "warn",
			message: i18n.t("pageReportPreset.noData", { count: noData.length, series: lf.format(noData) }),
		});
	noData.length > 0 &&
		path !== nav.report.preset &&
		warnings.push({
			type: "warn",
			message: i18n.t("pageReport.noData", { count: noData.length, series: lf.format(noData) }),
		});
	maxData.length > 0 &&
		warnings.push({
			type: "error",
			message: i18n.t("pageReport.maxDataExceeded", { count: maxData.length, series: lf.format(maxData) }),
		});
	error.length > 0 &&
		error.map((e) => {
			if (e.msg) {
				warnings.push({ type: "error", message: `Series ${e.name}: ${e.msg}` });
			} else {
				warnings.push({
					type: "error",
					message: i18n.t("pageReport.genericErrorMsg", { count: error.length, series: lf.format(e) }),
				});
			}
			return null;
		});

	return warnings;
};

export const formatDataForDisplay = (data) => {
	const warningArr = { noData: [], maxData: [], error: [] };
	const dataArr = [];

	if (data) {
		data.forEach((series) => {
			const { data, name } = series;
			if (data?.length === 0) {
				warningArr["noData"].push(name);
			} else if (data?.length > 0) {
				dataArr.push(series);
			} else if (data?.dataExceeded) {
				warningArr["maxData"].push(name);
			} else if (data?.error) {
				data.msg !== "Internal server error"
					? warningArr["error"].push({ name: name, msg: data.msg })
					: warningArr["error"].push(name);
			}
		});
	}

	return { warningArr, dataArr };
};
