//Node Modules
import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

//Atoms
import { themeAssetsAtom } from "../../atoms/ThemeAssetsAtom";

const NoHudHeader = () => {
  const { t } = useTranslation();

  //Theme
  const { appName, logoHoriz } = useRecoilValue(themeAssetsAtom);

  return (
    <div className="noHudTitle">
      <div className="appLogo">
        <div className="logoImg wide">
          <img src={logoHoriz} alt="Logo" />
        </div>
        {appName !== "pushfusion" && (
          <div className="appNameSub">
            {t("common.poweredBy")} {t("common.companyShort")}
          </div>
        )}
      </div>
    </div>
  );
};

export default NoHudHeader;
