import React from "react";
//Node Modules
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
//3rd Party Components
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { SelectButton } from "primereact/selectbutton";

export default function AddReportForm({ control, getFormErrorMessage }) {
	const { t } = useTranslation();

	return (
		<div className="formFieldsWrapper">
			<div className="formField">
				<label htmlFor="reportName">{t("pageReport.saveReportForm.reportName")}</label>
				<Controller
					name="reportName"
					control={control}
					rules={{
						required: {
							value: true,
							message: t("pageReport.saveReportForm.nameRequired"),
						},
						maxLength: {
							value: 50,
							message: t("pageReport.saveReportForm.nameMaxLength"),
						},
					}}
					render={({ field, fieldState }) => {
						return (
							<InputText
								placeholder={t("pageReport.series.form.seriesName.placeholder")}
								{...field}
								className={classNames({ error: fieldState.invalid })}
							/>
						);
					}}
				/>
				{getFormErrorMessage("reportName")}
			</div>
			<div className="formField">
				<label htmlFor="description">{t("pageReport.saveReportForm.reportDescription")}</label>
				<Controller
					name="description"
					control={control}
					rules={{
						required: {
							value: true,
							message: t("pageReport.saveReportForm.descriptionRequired"),
						},
					}}
					render={({ field, fieldState }) => {
						return (
							<InputTextarea
								maxLength={150}
								rows={3}
								columns={30}
								placeholder={t("pageReport.series.form.seriesName.placeholder")}
								{...field}
								className={classNames({ error: fieldState.invalid })}
							/>
						);
					}}
				/>
				{getFormErrorMessage("description")}
			</div>
			<div className="formField">
				<label htmlFor="privacy">{t("pageReport.saveReportForm.reportPrivacy")}</label>
				<Controller
					name="privacy"
					control={control}
					render={({ field, fieldState }) => (
						<SelectButton
							placeholder={t("pageReport.saveReportForm.reportPrivacy")}
							value={field.value}
							options={[
								{ value: "public", label: t("pageReport.saveReportForm.public") },
								{ value: "private", label: t("pageReport.saveReportForm.private") },
							]}
							onChange={(e) => field.onChange(e.value)}
							{...field}
							optionValue="value"
							className={classNames({ error: fieldState.invalid })}
						/>
					)}
				/>
			</div>
		</div>
	);
}
