//Node Modules
import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { SummaryItem } from "../../components/general";
import { ExpandableCard, DeviceDetailsExpanded } from "../../components/device";

//3rd Party Components
import { Tooltip } from "primereact/tooltip";

//Atoms

//Services

//Helpers

//Other

const DeviceDetails = ({ deviceDetails, basics }) => {
	const { t } = useTranslation();

	const getUnitStatus = () => {
		const online = deviceDetails.stdData.online ? "Online" : "Offline";
		const on = deviceDetails.stdData.on ? "On" : "Off";
		const dim =
			deviceDetails.stdData.dimLevel !== undefined
				? ` (${Math.round(deviceDetails.stdData.dimLevel * 100)}%)`
				: "";

		return `${online} - ${on} ${dim}`;
	};

	const getStatusIcon = () => {
		const online = deviceDetails.stdData.online ? "icon-online" : "icon-offline";
		const on = deviceDetails.stdData.on ? "success" : "error";

		return `${online} ${on}`;
	};

	const getTypeIcon = () => {
		const type = deviceDetails.stdData.type;
		let icon = "icon-device";

		if (type === "Luminaire") {
			icon = "icon-light warn";
		} else if (type === "BatterySwitch") {
			icon = "icon-switch info";
		} else if (type === "Gateway") {
			icon = "icon-gateway success";
		} else if (type === "Driver") {
			icon = "icon-driver success";
		}

		return icon;
	};

	return (
		deviceDetails && (
			<>
				<Tooltip target=".withPTooltip" autoHide={false} hideDelay={500} />

				{basics && <SummaryItem label="Selected Device:">{deviceDetails.stdData.name}</SummaryItem>}

				<div className="card column2 gapMedium">
					<SummaryItem label={t("pageDeviceDetails.properties.state")} icon={getStatusIcon()}>
						{getUnitStatus()}
					</SummaryItem>
					{deviceDetails.stdData.details && (
						<SummaryItem label="OEM:" icon="pi pi-cog">
							{deviceDetails.stdData.details.OEM}{" "}
						</SummaryItem>
					)}
					<SummaryItem label={t("pageDeviceDetails.properties.type")} icon={getTypeIcon()}>
						{deviceDetails.stdData.type}
					</SummaryItem>
					{deviceDetails.stdData.details && (
						<SummaryItem label={t("pageDeviceDetails.properties.fixture")} icon="icon-fixture">
							{deviceDetails.stdData.details.fixture_model}
						</SummaryItem>
					)}
					{deviceDetails.energyData && (
						<SummaryItem label={t("pageDeviceDetails.properties.energy")} icon="icon-energy info">
							{deviceDetails.energyData.energy_total.toFixed(2)} <span className="small light">Wh</span>
						</SummaryItem>
					)}
					<SummaryItem label={t("pageDeviceDetails.properties.firmware")} icon="icon-code">
						{deviceDetails.stdData.firmwareVersion}
					</SummaryItem>
				</div>

				{deviceDetails.daliData &&
					(!Array.isArray(deviceDetails.daliData) ? (
						<div className="card summaryCard">
							<header className="marginBottomMedium">
								<div className="large">
									<span className="icon icon-driver success"></span>
									<h2>{`${t("pageDeviceDetails.properties.address")} ${
										deviceDetails.daliData.address
									}`}</h2>
								</div>
							</header>

							<DeviceDetailsExpanded
								details={deviceDetails.daliData}
								energy={deviceDetails.energyData}
								type={deviceDetails.stdData.type}
							/>
						</div>
					) : (
						<div className="column3 gapMedium">
							{deviceDetails.daliData.map((daliDevice) => {
								return (
									<ExpandableCard
										key={daliDevice.serial}
										id={daliDevice.serial}
										title={`${t("pageDeviceDetails.properties.address")} ${daliDevice.address}`}
										icon="icon-driver"
										details={daliDevice}
										type={deviceDetails.stdData.type}
									/>
								);
							})}
						</div>
					))}
			</>
		)
	);
};

export default DeviceDetails;
