import i18n from "../../i18n";

const getResultIcon = (value) => {
	let icon;
	let colour;

	if (value === "SUCCESS" || value === "HEALTHY") {
		icon = "pi-check-circle";
		colour = "success";
	} else if (value === "INCOMPLETE") {
		icon = "pi-clock";
		colour = "warn";
	} else if (value === "FAILURE" || value === "FAILURES") {
		icon = "pi-times-circle";
		colour = "error";
	} else if (value === "CANCELED") {
		icon = "pi-exclamation-circle";
		colour = "warn";
	} else if (value === "WARNING") {
		icon = "pi-exclamation-circle";
		colour = "warn";
	} else if (value === "OVERDUE_F" || value === "OVERDUE_D") {
		icon = "pi-clock";
		colour = "warn";
	} else if (value === "---" || value === null) {
		icon = "pi-exclamation-circle";
		colour = "warn";
	} else if (!value) {
		icon = "";
		colour = "error";
	} else {
		icon = "pi-info-circle";
		colour = "info";
	}

	return { icon, colour };
};

export const healthOptions = [
	{ value: "HEALTHY", label: i18n.t("common.state.HEALTHY") },
	{ value: "FAILURES", label: i18n.t("common.state.FAILURES") },
	{ value: "WARNING", label: i18n.t("common.state.WARNING") },
	{ value: "OVERDUE_F", label: i18n.t("common.state.OVERDUE_F") },
	{ value: "OVERDUE_D", label: i18n.t("common.state.OVERDUE_D") },
];

export const resultOptions = [
	{ value: "SUCCESS", label: i18n.t("common.state.SUCCESS") },
	{ value: "INCOMPLETE", label: i18n.t("common.state.INCOMPLETE") },
	{ value: "FAILURE", label: i18n.t("common.state.FAILURE") },
	{ value: "CANCELED", label: i18n.t("common.state.CANCELED") },
	{ value: "DELAYED", label: i18n.t("common.state.DELAYED") },
	{ value: "DELAYED1", label: i18n.t("common.state.DELAYED1") },
	{ value: "DELAYED2", label: i18n.t("common.state.DELAYED2") },
	{ value: "DELAYED3", label: i18n.t("common.state.DELAYED3") },
	{ value: "DELAYED4", label: i18n.t("common.state.DELAYED4") },
	{ value: "NOT_STARTED", label: i18n.t("common.state.NOT_STARTED") },
	{ value: "---", label: i18n.t("common.state.---") },
];

export const healthTemplate = (value, overdueF, overdueD) => {
	const { icon, colour } = getResultIcon(value);
	const overdue = value.endsWith("F") ? overdueF : overdueD;

	return (
		<span className={`withIcon ${value.startsWith("OVERDUE") && "withTooltip"}`} aria-label={`${i18n.t("pageSummary.table.overdue")}: ${overdue} days`}>
			<i className={`pi ${icon} ${colour}`} />
			{i18n.t(`common.state.${value}`)}
		</span>
	);
};

export const resultTemplate = (value) => {
	const { icon, colour } = getResultIcon(value);

	return (
		<span className="withIcon">
			<i className={`pi ${icon} ${colour}`} />
			{i18n.t(`common.state.${value}`)}
		</span>
	);
};

export const resultFilterTemplate = (option) => {
	const { icon, colour } = getResultIcon(option.value);

	return (
		<span className="withIcon">
			<i className={`pi ${icon} ${colour}`} />
			{option.label}
		</span>
	);
};
