import { atom, atomFamily, selectorFamily } from "recoil";
import { FilterMatchMode, FilterOperator } from "primereact/api";

export const testBookTableAtom = atom({
	key: "testBookTableAtom",
	// default: '{"first":0,"rows":10,"filters":{"result":{"value":["FAILURE"],"matchMode":"in"}}}',
	default: [],
});

export const testBookTableFilterAtom = atom({
	key: "testBookTableFilterAtom",
	default: {
		timestamp: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
		},
		type: { value: null, matchMode: FilterMatchMode.IN },
		subType: { value: null, matchMode: FilterMatchMode.IN },
		manner: { value: null, matchMode: FilterMatchMode.IN },
		path: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
		},
		result: { value: null, matchMode: FilterMatchMode.IN },
	},
});

export const testBookSeriesIdAtom = atom({
	key: "testBookSeriesIdAtom",
	default: [],
});

export const testBookStateAtomFamily = atomFamily({
	key: "testBookStateAtomFamily",
	default: null,
});

export const testBookStateSelectorFamily = selectorFamily({
	key: "testBookStateSelectorFamily",
	get:
		(testBookSeriesIds) =>
		({ get }) => {
			const array = [];
			testBookSeriesIds.map((id) => {
				const seriesData = get(testBookStateAtomFamily(id));
				array.push(seriesData);
				return seriesData;
			});
			return array;
		},
});

export const testBookDataAtomFamily = atomFamily({
	key: "testBookDataAtomFamily",
	default: null,
});

export const testBookDataSelectorFamily = selectorFamily({
	key: "testBookDataSelectorFamily",
	get:
		(testBookSeriesIds) =>
		({ get }) => {
			const array = [];
			testBookSeriesIds.map((id) => {
				const seriesData = get(testBookDataAtomFamily(id));
				array.push(seriesData);
				return seriesData;
			});
			return array;
		},
});
