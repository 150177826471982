//Node Modules
import React, { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { Auth } from "aws-amplify";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForgeComponenets
import { PageHeader, PageHelmet } from "../components/general";
import { InputEmail, InputFullname, InputPasswordConfirm, InputPasswordCurrent } from "../components/user";

//3rd Party Components
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

//Atoms
import { toastAtom } from "../atoms/ToastAtom";
import { userAtom } from "../atoms/UserAtom";

//Services

//Helpers

//Other

function UserProfile() {
	const { t } = useTranslation();
	const hiddenSubmit = useRef();

	const [toast, setToast] = useRecoilState(toastAtom);
	const [user, setUser] = useRecoilState(userAtom);
	const [emailCode, setEmailCode] = useState("");

	const {
		register,
		control,
		handleSubmit,
		getValues,
		watch,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	const onSubmit = async (data) => {
		const newAttributes = {
			email: data.email,
			name: data.fullname,
			"custom:em_email": data.emEmail ? "1" : "0",
			"custom:em_email_faults": data.emEmailFaults && data.emEmail ? "1" : "0",
		};
		const passwordAttributes = {
			previousPassword: data.passwordCurrent,
			proposedPassword: data.password,
		};

		try {
			//Get user from session
			const user = await Auth.currentAuthenticatedUser();

			//If required, update password
			if (passwordAttributes.proposedPassword) {
				await Auth.changePassword(
					user,
					passwordAttributes.previousPassword,
					passwordAttributes.proposedPassword
				);
			}
			//Update user (if password change is required, it must be successful to update user)
			await Auth.updateUserAttributes(user, newAttributes);

			//Get session and update user in state
			const session = await Auth.currentSession();
			setUser((prevState) => ({
				...prevState,
				attr: session.getIdToken().payload,
			}));

			//Show successful toast
			setToast({
				...toast,
				severity: "success",
				summary: t("pageProfile.toast.updateSuccessSummary"),
				detail: t("pageProfile.toast.updateSuccessDetail"),
			});
		} catch (error) {
			console.error("Error :", error);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageProfile.toast.updateErrorSummary"),
				detail: t("pageProfile.toast.updateErrorDetail", {
					err: error.message,
				}),
			});
		} finally {
			reset({
				...getValues(),
				passwordCurrent: "",
				password: "",
				passwordConfirm: "",
			});
		}
	};

	const verifyEmail = async () => {
		try {
			await Auth.verifyCurrentUserAttributeSubmit("email", emailCode);
			await Auth.currentAuthenticatedUser({ bypassCache: true });

			const session = await Auth.currentSession();
			setUser((prevState) => ({
				...prevState,
				attr: session.getIdToken().payload,
			}));

			setToast({
				...toast,
				severity: "success",
				summary: t("pageProfile.toast.emailSuccessSummary"),
				detail: t("pageProfile.toast.emailSuccessDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("pageProfile.toast.emailErrorSummary"),
				detail: t("pageProfile.toast.emailErrorDetail", { err: err.message }),
			});
		}
	};

	const saveProfileBttn = (
		<Button
			className="feature"
			label={t("pageProfile.featureBttn")}
			icon="pi pi-save"
			onClick={() => hiddenSubmit?.current?.click()}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageProfile.pageHeader")} />
			<PageHeader title={t("pageProfile.pageHeader")} featureBttn={saveProfileBttn} />
			<main>
				<div className="mainContent">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="grid column2 horizGap">
							<div className="card">
								<h2 className="marginBottomMedium">
									{t("pageProfile.user")}: {user.username}
								</h2>
								<div className="formFieldsWrapper">
									<InputEmail register={register} errors={errors} defaultValue={user.attr.email} />
									{(user.attr.email_verified === "false" || user.attr.email_verified === false) && (
										<>
											<div className="grid column2 autoColumns small paddingLeftMedium">
												<i className="pi pi-info-circle info large" />
												<p>{t("pageProfile.changeWarning")}</p>
												<i className="pi pi-exclamation-circle error large" />
												<p>
													<b>{t("pageProfile.verifyWarning")}</b>
												</p>
											</div>
											<div className="grid column2 double paddingLeftMedium">
												<input
													type="text"
													value={emailCode}
													placeholder={t("pageProfile.form.label.code")}
													onChange={(e) => setEmailCode(e.target.value)}
												/>
												<button type="button" onClick={verifyEmail}>
													{t("pageProfile.verifyEmailBttn")}
												</button>
											</div>
										</>
									)}
									<InputFullname register={register} errors={errors} defaultValue={user.attr.name} />
									<div className="formField">
										<label htmlFor="emEmails">{t("pageProfile.form.label.emEmail")}</label>
										<div className="flex">
											<Controller
												name="emEmail"
												control={control}
												defaultValue={user.attr["custom:em_email"] > 0 ? true : false}
												render={({ field }) => (
													<Checkbox
														inputId={field.name}
														onChange={(e) => field.onChange(e.checked)}
														checked={field.value}
													/>
												)}
											/>
											<span className="small light">
												{t("pageProfile.form.label.emEmailDesc")}
											</span>
										</div>
									</div>

									<div className="formField">
										<label htmlFor="emEmailsFaults">
											{t("pageProfile.form.label.emEmailFaults")}
										</label>
										<div className="flex">
											<Controller
												name="emEmailFaults"
												control={control}
												defaultValue={user.attr["custom:em_email_faults"] > 0 ? true : false}
												render={({ field }) => (
													<Checkbox
														inputId={field.name}
														onChange={(e) => field.onChange(e.checked)}
														checked={field.value && watch("emEmail")}
														disabled={!watch("emEmail")}
													/>
												)}
											/>
											<span className="small light">
												{t("pageProfile.form.label.emEmailFaultsDesc")}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="card">
								<h2 className="marginBottomMedium">{t("pageProfile.updatePass")}</h2>
								<div className="formFieldsWrapper">
									<InputPasswordCurrent
										register={register}
										errors={errors}
										required={getValues("password")}
									/>
									<InputPasswordConfirm register={register} errors={errors} values={getValues} />
								</div>
							</div>
						</div>
						<button type="submit" hidden={true} ref={hiddenSubmit} />
					</form>
				</div>
			</main>
		</>
	);
}

export default UserProfile;
