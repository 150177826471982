import { useNavigate, useLocation } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState, useResetRecoilState } from "recoil";

//Atoms
import { loadingAtom } from "../atoms/LoadingAtom";
import { toastAtom } from "../atoms/ToastAtom";
import { userAtom } from "../atoms/UserAtom";
import { modulesAtom } from "../atoms/ProjectAtom";

//Other
import { nav } from "../config/navigation";

export const setupAxiosInterceptor = () => {};

export const useLoginUser = () => {
	const { t } = useTranslation();
	const setUser = useSetRecoilState(userAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoading = useSetRecoilState(loadingAtom);
	const setModules = useSetRecoilState(modulesAtom);
	const navigate = useNavigate();
	const location = useLocation();

	const login = async () => {
		try {
			const session = await Auth.currentSession();
			if (session && process.env.NODE_ENV === "development") {
				console.log("Session:", session);
			}
			const groups = session.idToken.payload["cognito:groups"];
			if (!groups) {
				await Auth.signOut();
				setToast({
					...toast,
					severity: "error",
					summary: t("pageLogin.toast.noProjectsSummary"),
					detail: t("pageLogin.toast.noProjectsDetail"),
					life: 6000,
				});
				//If user confirmed registration but got no projects assigned, send to login screen
				if (location.pathname === nav.general.completeReg) {
					navigate(nav.general.login);
				}
			} else if (groups.length === 1) {
				setLoading({ visible: true, message: t("pageProjectSelect.loading") });
				const modules = await getAllModules(groups[0].toLowerCase());
				setModules(modules);
				setUser((prevState) => ({
					...prevState,
					isLoggedIn: true,
					username: session.idToken.payload["cognito:username"],
					attr: session.getIdToken().payload,
					availableProjects: session.idToken.payload["cognito:groups"],
					selectedProject: groups[0],
				}));
				navigate(nav.emergency.summary);
			} else {
				setUser((prevState) => ({
					...prevState,
					isLoggedIn: true,
					username: session.idToken.payload["cognito:username"],
					attr: session.getIdToken().payload,
					availableProjects: session.idToken.payload["cognito:groups"],
				}));
				navigate(nav.general.projectSelect);
			}
		} catch (error) {
			console.error("useLoginUser error :", error);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageLogin.toast.loginFailSummary"),
				detail: t("pageLogin.toast.loginFailDetail"),
				life: 6000,
			});
		}
	};

	return { login };
};

//Refactor to a single function
export const getAllModules = async (project) => {
	try {
		const additionalHeader = {
			headers: {
				"X-BinaryForge-Project": project,
			},
		};
		return await API.get("pushfusionMooreAPI", "/admin/ghost/modules", additionalHeader);
	} catch (err) {
		console.error("err", err);
	}
};

export const useLogout = () => {
	const navigate = useNavigate();
	const resetUser = useResetRecoilState(userAtom);

	// const series = useRecoilValue(reportSeriesSelectorFamily(seriesId));

	const handleLogout = async () => {
		try {
			// <Redirect to={`/logout`} />;
			await Auth.signOut();
			navigate(nav.general.logout);
		} catch (error) {
			console.error("handleLogout", error);
			resetUser();
		}
	};

	return handleLogout;
};
