//Node Modules
import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";

import * as maptalks from "maptalks";

//Pages

//BinaryForge Components
import { PageHeader } from "../../components/general";

//3rd Party Components
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { ProgressSpinner } from "primereact/progressspinner";

//Atoms

//Helpers
import { getDevicesState } from "../../helpers/Constants";
import { useMakeRequest } from "../../helpers/NetworkUtils";

//Other
// import floorPlan from "../../assets/imgs/MyHouse.png";
import floorPlan from "../../assets/imgs/map/floorPlan/indoor.jpg";

import driverIcon from "../../assets/imgs/map/device/driver.json";
import gatewayIcon from "../../assets/imgs/map/device/gateway.json";
import lightIcon from "../../assets/imgs/map/device/light.json";
import switchIcon from "../../assets/imgs/map/device/switch.json";

import devicesCampus from "../../assets/data/devicesIndoor.json";
import { DeviceDetails } from "../../components/device";

const appElement = document.getElementById("appWrapper");

const markerDefaultSize = 40;
const markerSmallSize = markerDefaultSize / 2;
const markerDefaultOpacity = 1;
const markerSmallOpacity = 0.6;
const animationDuration = 500;

const getMarkerIcon = (type) => {
	switch (type) {
		case "Driver":
			return { icon: driverIcon, colour: "#00956d" };
		case "Gateway":
			return { icon: gatewayIcon, colour: "#00956d" };
		case "Luminaire":
			return { icon: lightIcon, colour: "#ffbb00" };
		case "BatterySwitch":
			return { icon: switchIcon, colour: "#007acc" };
		default:
			return { icon: driverIcon, colour: "#000" };
	}
};

const DeviceMapAlt = () => {
	const deviceTypesMenu = useRef(null);
	const makeRequest = useMakeRequest();

	// const { data } = useApiRequest("get", `${GET_DEVICES_BY_BUILDING}/2`, null);

	const [dims, setDims] = useState();
	const [map, setMap] = useState();
	const [devices, setDevices] = useState(devicesCampus);
	const [deviceTypes, setDeviceTypes] = useState();
	const [hoverDevice, setHoverDevice] = useState();
	const [selectedDevice, setSelectedDevice] = useState();
	const [deviceData, setDeviceData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getFloorPlanDims();
		getUniqueDeviceTypes();
	}, []);

	// useEffect(() => {
	// 	data &&
	// 		data.map((d) => {
	// 			d.createdAt = new Date(d.createdAt);
	// 			d.updatedAt = new Date(d.updatedAt);
	// 			d.firmwareVersion = parseFloat(d.firmwareVersion);
	// 			return d;
	// 		});
	// 	console.log(JSON.stringify(data));
	// }, [data]);

	useEffect(() => {
		if (map) {
			console.log("Map already exists ::", map);
			map.remove();
		}
		if (dims) {
			setMap(initMap());
		}
	}, [dims]);

	useEffect(() => {
		if (selectedDevice) {
			console.log(selectedDevice);
			getDeviceData();
		} else {
			setDeviceData(null);
		}
	}, [selectedDevice]);

	const getDeviceData = async () => {
		setIsLoading(true);
		setDeviceData(
			await makeRequest(
				"get",
				getDevicesState({
					networkId: selectedDevice.networkId,
					deviceId: selectedDevice.deviceId,
				}),
				null
			)
		);
		setIsLoading(false);
	};

	const getUniqueDeviceTypes = () => {
		const unique = _.uniq(devices.map((d) => d.type));
		setDeviceTypes(unique);
	};

	const getFloorPlanDims = async () => {
		const floorPlanImg = new Image();
		floorPlanImg.src = floorPlan;

		await floorPlanImg.decode();
		setDims({ w: floorPlanImg.width, h: floorPlanImg.height });
	};

	const initMap = () => {
		// Setup the map
		const map = new maptalks.Map("floorPlanMap", {
			center: [0, 0],
			zoom: 1,
			minZoom: 2,
			maxZoom: 5,
			zoomControl: {
				position: { top: 10, left: 10 },
				slider: true,
				zoomLevel: false,
			},
			attribution: false,
			spatialReference: {
				projection: "EPSG:4326",
			},
		});

		// Set the extent of the pan
		map.setMaxExtent(map.getExtent());
		const mapSize = map.getSize();

		// Calculate new image dimensions to fit within the container
		const imgModifier =
			dims.w > dims.h ? (1 / dims.w) * (mapSize.width * 0.9) : (1 / dims.h) * (mapSize.height * 0.9);
		const newImgHeight = dims.h * imgModifier;
		const newImgWidth = dims.w * imgModifier;

		// Get the points to place the map within the container
		const imgPoints = [
			{ x: mapSize.width / 2 - newImgWidth / 2, y: mapSize.height / 2 - newImgHeight / 2 },
			{ x: mapSize.width / 2 + newImgWidth / 2, y: mapSize.height / 2 + newImgHeight / 2 },
		];

		// Convert the points to coordinates
		const imgCoords = [map.containerPointToCoordinate(imgPoints[0]), map.containerPointToCoordinate(imgPoints[1])];

		// Build the floor plan image layer and add the floor plan
		var floorPlanLayer = new maptalks.ImageLayer("floorPlanLayer", [
			{
				url: floorPlan,
				extent: [imgCoords[0].x, imgCoords[0].y, imgCoords[1].x, imgCoords[1].y],
			},
		]);

		const handleDragEnd = (e) => {
			console.log("drag", e.coordinate.x, e.coordinate.y);
		};

		const handleHoverDevice = (e) => {
			const hoverDevice = {
				name: e.target.properties.deviceName,
				type: e.target.properties.deviceType,
			};
			setHoverDevice(hoverDevice);
		};

		// Create the marker layer
		var markerLayer = new maptalks.VectorLayer("markerLayer");

		// Prepare data
		// console.log("Devices ::", JSON.stringify(devices));
		const markers = [];
		for (const device of devices) {
			const { icon, colour } = getMarkerIcon(device.type);

			const newMarker = new maptalks.Marker([device.coord.x, device.coord.y], {
				draggable: true,
				symbol: [
					{
						markerType: "ellipse",
						markerFill: colour,
						markerLineWidth: 0,
						markerWidth: markerDefaultSize + 5,
						markerHeight: markerDefaultSize + 5,
						opacity: 0.6,
					},
					{
						markerType: "ellipse",
						markerFill: "#0e98fa",
						markerFillOpacity: markerDefaultOpacity,
						markerLineWidth: 1,
						markerLineColor: "#FFF",
						markerWidth: markerDefaultSize,
						markerHeight: markerDefaultSize,
					},
					{
						markerType: "path",
						markerPath: icon,
						markerPathWidth: 256,
						markerPathHeight: 256,
						markerWidth: 24,
						markerHeight: 24,
						markerDy: 12,
						markerFill: "#FFF",
					},
				],
				properties: {
					deviceId: device.id,
					deviceName: device.name,
					networkId: device.network.id,
					deviceType: device.type,
				},
			})
				.on("dragend", (e) => handleDragEnd(e))
				.on("mouseenter", (e) => handleHoverDevice(e))
				.on("mouseout", (e) => setHoverDevice(null));

			markers.push(newMarker);
		}
		markerLayer.addGeometry(markers);

		// Highlight the active marker
		map.on("click", function (e) {
			setSelectedDevice(null);
			// Reset non-active markers
			markerLayer.forEach(function (marker) {
				marker.animate(
					{
						symbol: [
							{
								markerWidth: markerDefaultSize + 5,
								markerHeight: markerDefaultSize + 5,
							},
							{
								markerWidth: 40,
								markerHeight: 40,
							},
							{
								markerWidth: 24,
								markerHeight: 24,
								markerDy: 12,
							},
						],
					},
					{ duration: 100, easing: "out" }
				);
			});
			// Identify selected marker using clicked coordinates
			map.identify(
				{
					coordinate: e.coordinate,
					layers: [markerLayer],
				},
				function (geos) {
					if (geos.length === 0) {
						console.log("Nothing");
						// markerLayer.forEach(function (feature) {
						// 	feature.animate(
						// 		{
						// 			symbol: [
						// 				{
						// 					markerWidth: markerDefaultSize + 5,
						// 					markerHeight: markerDefaultSize + 5,
						// 					opacity: 0.6,
						// 				},
						// 				{
						// 					markerWidth: markerDefaultSize,
						// 					markerHeight: markerDefaultSize,
						// 					markerFillOpacity: markerDefaultOpacity,
						// 				},
						// 				{
						// 					markerWidth: 30,
						// 					markerHeight: 30,
						// 					markerDy: 15,
						// 				},
						// 			],
						// 		},
						// 		{ duration: animationDuration }
						// 	);
						// });
						return;
					}
					const g = geos[0];
					console.log("selected", g);
					g.animate(
						{
							symbol: [
								{
									markerWidth: markerDefaultSize + 30,
									markerHeight: markerDefaultSize + 30,
								},
								{
									markerWidth: 50,
									markerHeight: 50,
								},
								{
									markerWidth: 30,
									markerHeight: 30,
									markerDy: 15,
								},
							],
						},
						{ duration: 100, easing: "out" }
					);
					setSelectedDevice({ deviceId: g.properties.deviceId, networkId: g.properties.networkId });
				}
			);
		});

		// const markerObject = new maptalks.Marker([0, 0], {
		// 	id: "floorPlan",
		// 	draggable: true,
		// 	dragend: handleDragEnd(),
		// 	symbol: {
		// 		markerFile: marker,
		// 		markerWidth: 40 * 0.8,
		// 		markerHeight: 50 * 0.8,
		// 	},
		// }).addTo(markerLayer);

		// markerObject.on("dragend", function (m) {
		// 	console.log(m);
		// });

		map.addLayer(floorPlanLayer);
		map.addLayer(markerLayer);

		// const changeView = async () => {
		// 	map.animateTo(
		// 		{
		// 			center: [0, 0],
		// 			zoom: 2,
		// 			pitch: 0,
		// 			bearing: 0,
		// 		},
		// 		{
		// 			duration: 5000,
		// 		}
		// 	);
		// };

		// changeView();

		return map;
	};

	const handleHighlightDeviceType = (type) => {
		const markerLayer = map.getLayer("markerLayer");

		if (type === "All") {
			markerLayer.forEach(function (feature) {
				feature.animate(
					{
						symbol: [
							{
								markerWidth: markerDefaultSize + 5,
								markerHeight: markerDefaultSize + 5,
								opacity: 0.6,
							},
							{
								markerWidth: markerDefaultSize,
								markerHeight: markerDefaultSize,
								markerFillOpacity: markerDefaultOpacity,
							},
							{
								markerWidth: 24,
								markerHeight: 24,
								markerDy: 12,
							},
						],
					},
					{ duration: animationDuration }
				);
			});
		} else {
			markerLayer.filter(["==", "deviceType", type]).forEach(function (feature) {
				feature.animate(
					{
						symbol: [
							{
								markerWidth: markerDefaultSize + 5,
								markerHeight: markerDefaultSize + 5,
								opacity: 0.6,
							},
							{
								markerWidth: markerDefaultSize,
								markerHeight: markerDefaultSize,
								markerFillOpacity: markerDefaultOpacity,
							},
							{
								markerWidth: 24,
								markerHeight: 24,
								markerDy: 12,
							},
						],
					},
					{ duration: animationDuration }
				);
			});
			markerLayer.filter(["!=", "deviceType", type]).forEach(function (feature) {
				feature.animate(
					{
						symbol: [
							{
								markerWidth: markerSmallSize + 5,
								markerHeight: markerSmallSize + 5,
								opacity: 0,
							},
							{
								markerWidth: markerSmallSize,
								markerHeight: markerSmallSize,
								markerFillOpacity: markerSmallOpacity,
							},
							{
								markerWidth: 12,
								markerHeight: 12,
								markerDy: 6,
							},
						],
					},
					{ duration: animationDuration }
				);
			});
		}
	};

	const deviceTypesFilterItems = [
		{
			label: "Filter Device Type",
			items: _.flatten([
				{
					label: "All",
					command: () => handleHighlightDeviceType("All"),
				},
				deviceTypes && deviceTypes.map((dt) => ({ label: dt, command: () => handleHighlightDeviceType(dt) })),
			]),
		},
	];

	return (
		<>
			<PageHeader title="Mapping" subtitle="Business Design Centre" />
			<main>
				<div className="mainContent">
					<div className="floorPlanMapWrapper">
						<div id="floorPlanMap"></div>
						<div className="hoverDevice">
							{hoverDevice && (
								<span>
									{hoverDevice.name} - {hoverDevice.type}
								</span>
							)}
						</div>
						{deviceTypes && (
							<div className="typeFilter">
								<Button
									icon="pi pi-filter"
									className="round large feature"
									onClick={(event) => deviceTypesMenu.current.toggle(event)}
									aria-label="Device Types Filter"
									aria-controls="deviceTypesMenu"
									aria-haspopup
								/>
								<Menu
									id="deviceTypesMenu"
									popup
									ref={deviceTypesMenu}
									model={deviceTypesFilterItems}
									appendTo={appElement}
								/>
							</div>
						)}
					</div>

					{isLoading && (
						<>
							<ProgressSpinner strokeWidth="4" />
							<p className="message">
								Loading Device Details
								<span className="loadingDot">.</span>
								<span className="loadingDot">.</span>
								<span className="loadingDot">.</span>
							</p>
						</>
					)}
					{deviceData && <DeviceDetails deviceDetails={deviceData} basics={true} />}
				</div>
			</main>
		</>
	);
};

export default DeviceMapAlt;
