import React from "react";
import { useRecoilState } from "recoil";

import { Dialog } from "primereact/dialog";

import { idleDialogAtom } from "../../atoms/DialogAtom";

function IdleTimeoutDialog(props) {
	const { header, footer } = props;
	const appElement = document.getElementById("appWrapper");

	const [show, setShow] = useRecoilState(idleDialogAtom);

	return (
		<Dialog header={header} footer={footer} visible={show} closable={false} onHide={() => setShow(false)} appendTo={appElement} maskClassName="mask">
			{props.children}
		</Dialog>
	);
}

export default IdleTimeoutDialog;
