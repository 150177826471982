export const nav = {
	general: {
		notfound: "/notFound",
		login: "/login",
		logout: "/logout",
		projectSelect: "/projectSelect",
		completeReg: "/completeRegistration",
		forgotPassword: "/forgotPassword",
		verifyAttribute: "/verifyAttribute",
	},
	admin: {
		building: {
			buildingManagement: "/admin/building/management",
			buildingCreate: "/admin/building/create",
			buildingEdit: "/admin/building/edit",
		},
		account: {
			management: "/admin/account/management",
			create: "/admin/account/create",
			edit: "/admin/account/edit",
		},
		user: {
			management: "/admin/user/management",
			details: "/admin/user",
			create: "/admin/user/create",
			update: "/admin/user/update",
		},
		profile: "/profile",
	},
	emergency: {
		summary: "/emergency/summary",
		duration: "/emergency/duration",
		testbook: "/emergency/testbook",
	},
	device: {
		networks: "/device/networks",
		networkBuilding: "/device/network/building",
		units: "/device/units",
		buildingUnits: "/device/buildingUnits",
		unitDetails: "/device/unit/details",
		mapping: {
			indoor: "/device/map/indoor",
			outdoor: "/device/map/outdoor",
		},
	},
	report: {
		preset: "/reports/preset",
		custom: "/reports/custom",
	},
};

export const guestRoutes = Object.values(nav.general);
