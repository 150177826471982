import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { reportSeriesIdAtom, reportSeriesSelectorFamily } from "../../atoms/ReportAtom";

export const useSeries = () => {
	const [dataSeries, setDataSeries] = useState([]);
	const seriesId = useRecoilValue(reportSeriesIdAtom);
	const series = useRecoilValue(reportSeriesSelectorFamily(seriesId));

	useEffect(() => {
		setDataSeries(series);
	}, [series, seriesId]);

	return { dataSeries };
};
