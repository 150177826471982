import React from "react";
import { useTranslation } from "react-i18next";

function InputOverdueFunc(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, disabled } = props;

	return (
		<div className="formField">
			<label htmlFor="overdueFunctional">{t("pageBuildingEdit.form.label.overdueFunc")}</label>
			<input
				type="number"
				defaultValue={defaultValue}
				disabled={disabled}
				className={errors.overdueFunctional ? "error" : ""}
				{...register("overdueFunctional", { required: true, min: 1, max: 365 })}
			/>
			<ul className="errors">
				{errors?.overdueFunctional?.types?.required && <li className="error">{t("pageBuildingEdit.form.error.overdueFunc.required")}</li>}
				{errors?.overdueFunctional?.types?.min && <li className="error">{t("pageBuildingEdit.form.error.overdueFunc.min")}</li>}
				{errors?.overdueFunctional?.types?.max && <li className="error">{t("pageBuildingEdit.form.error.overdueFunc.max")}</li>}
			</ul>
		</div>
	);
}

export default InputOverdueFunc;
