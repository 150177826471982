//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { NoHudHeader, PageHelmet } from "../components/general";
import ProjectSelector from "../components/ProjectSelector";

//3rd Party Components

//Atoms

//Services

//Helpers

//Other

function ProjectSelect() {
  const { t } = useTranslation();

  return (
    <div className="noHud">
      <PageHelmet title={t("pageProjectSelect.pageHeader")} />
      <NoHudHeader />

      <div className="noHudMain">
        <h2 className="marginBottomMedium">
          {t("pageProjectSelect.pageHeader")}
        </h2>
        <div className="divider marginBottomLarge"></div>
        <ProjectSelector />
      </div>
    </div>
  );
}

export default ProjectSelect;
