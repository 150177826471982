//Node Modules
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/general";
import { UserDetailsSkeleton } from "../../../components/skeleton";
import { MessageIcon } from "../../../components/general";
import { UserDetails as UserData } from "../../../components/user";

//3rd Party Components
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

//Atoms
import { selectedUserAtom, selectedUserSelector, userAtom } from "../../../atoms/UserAtom";

//Helpers
import { useConfirmUserAction } from "../../../helpers/user";

//Other
import { nav } from "../../../config/navigation";

const UserDetails = () => {
	//Hooks
	const { t } = useTranslation();
	const { username } = useLoaderData();
	const navigate = useNavigate();
	const moreBttnMenu = useRef(null);
	const appElement = document.getElementById("appWrapper");
	const { confirmDeleteUser, confirmResetPassword, confirmResendInvite } = useConfirmUserAction();

	//Recoil
	const loggedInUser = useRecoilValue(userAtom);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const { state: selectedUserState, contents: selectedUserData } = useRecoilValueLoadable(selectedUserSelector);

	//On Page Load
	useEffect(() => {
		if (!selectedUser) {
			setSelectedUser({ username: username });
		}
	}, []);

	//Render the User Details
	const PageBody = () => {
		switch (selectedUserState) {
			case "hasError":
				return (
					<MessageIcon
						messageKey={`Error: ${selectedUserData.message ? selectedUserData.message : selectedUserData}`}
						icon="pi-exclamation-circle error"
					/>
				);
			case "loading":
				return <UserDetailsSkeleton />;
			case "hasValue":
				return <UserData userData={selectedUserData} />;
			default:
				return null;
		}
	};

	const featureBttn = (
		<Button
			className="feature"
			label={t("user.details.featureBttn")}
			icon="pi pi-user-edit"
			onClick={() =>
				loggedInUser.username === username
					? navigate(nav.admin.profile)
					: navigate(`${nav.admin.user.update}/${username}`)
			}
		/>
	);

	const moreBttnItems = [
		{
			label: t("user.management.actions.create"),
			icon: "pi pi-user-plus",
			command: () => navigate(nav.admin.user.create),
		},
		{
			label: t("user.management.actions.delete"),
			icon: "pi pi-user-minus",
			command: () => confirmDeleteUser(),
			disabled: username === loggedInUser.username,
		},
		{
			label: t("user.management.actions.password"),
			icon: "pi pi-lock",
			command: () => confirmResetPassword(),
			disabled: username === loggedInUser.username,
		},
		{
			label: t("user.management.actions.invite"),
			icon: "pi pi-envelope",
			command: () => confirmResendInvite(),
			disabled: username === loggedInUser.username,
		},
	];

	const moreBttn = (
		<>
			<Button
				label={t("user.details.secondaryBttn")}
				icon="pi pi-angle-down"
				onClick={(event) => moreBttnMenu.current.toggle(event)}
				aria-label="More Actions"
				aria-controls="bttnMenu"
				aria-haspopup
			/>
			<Menu id="bttnMenu" popup ref={moreBttnMenu} model={moreBttnItems} appendTo={appElement} />
		</>
	);

	return (
		<>
			<PageHeader
				title={t("user.details.pageTitle")}
				subtitle={username}
				enableBack
				featureBttn={featureBttn}
				secondaryBttn={moreBttn}
			/>
			<main>
				<div className="mainContent">
					<div className="card">
						<PageBody />
					</div>
				</div>
			</main>
		</>
	);
};

export default UserDetails;
