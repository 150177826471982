//Node Modules
import React from "react";
import { Helmet } from "react-helmet";

const PageHelmet = (props) => {
	const { title } = props;

	return (
		<Helmet>
			<title>pushfusion | {title}</title>
		</Helmet>
	);
};

export default PageHelmet;
