import React from "react";
import { useRecoilState } from "recoil";

import { Dialog } from "primereact/dialog";

import { dialogAtomFamily } from "../../atoms/DialogAtom";

function FusionDialog(props) {
	const { header, footer, id } = props;
	const appElement = document.getElementById("appWrapper");

	const [show, setShow] = useRecoilState(dialogAtomFamily(id));

	return (
		<Dialog header={header} footer={footer} visible={show} closable={true} onHide={() => setShow(false)} appendTo={appElement} maskClassName="mask">
			{props.children}
		</Dialog>
	);
}

export default FusionDialog;
