import React from "react";
import { useTranslation } from "react-i18next";

function InputPasswordCurrent(props) {
	const { t } = useTranslation();
	const { register, errors, disabled, required } = props;

	const isRequired = required ? true : false;

	return (
		<div className="formField">
			<label htmlFor="passwordCurrent">{t("pageProfile.form.label.currentPass")}</label>
			<input type="password" disabled={disabled} className={errors.passwordCurrent ? "error" : ""} {...register("passwordCurrent", { required: isRequired })} />
			<ul className="errors">{errors?.passwordCurrent?.types?.required && <li className="error">{t("pageProfile.form.error.currentPass.required")}</li>}</ul>
		</div>
	);
}

export default InputPasswordCurrent;
