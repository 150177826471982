//Node Modules
import React, { useEffect, useRef, useState } from "react";

//Pages

//BinaryForge Components

//3rd Party Components
import { Chart, registerables } from "chart.js";

//Atoms

//Services

//Helpers

//Other
import chartConfig from "../../config/chart.json";

function LineChart({ data }) {
  const chart = useRef(null);
  Chart.register(...registerables);

  const [myChart, setMyChart] = useState();

  useEffect(() => {
    createChart();
  }, []);

  useEffect(() => {
    if (myChart) {
      const ctx = chart.current.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
      gradient.addColorStop(0, chartConfig.line.backgroundColour.start);
      gradient.addColorStop(1, chartConfig.line.backgroundColour.end);
      myChart.data.datasets[0].backgroundColor = gradient;
      myChart.update();
    }
  }, [myChart]);

  const createChart = () => {
    if (myChart) {
      myChart.destroy();
    }

    const ctx = chart.current.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    gradient.addColorStop(0, chartConfig.line.backgroundColour.start);
    gradient.addColorStop(1, chartConfig.line.backgroundColour.end);

    setMyChart(
      new Chart(ctx, {
        type: "line",
        data: {
          datasets: [
            {
              data: data,
              fill: true,
              borderColor: chartConfig.line.borderColour,
              backgroundColor: gradient,
              tension: 0.25,
              radius: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          parsing: {
            xAxisKey: "graphDate",
            yAxisKey: "graphValue",
          },
          plugins: {
            legend: {
              display: false,
            },
            // 	tooltip: {
            // 		callbacks: {
            // 			label: function (context) {
            // 				return `${propertyType} - min: ${context.raw[0]}, max: ${context.raw[1]}`;
            // 			},
            // 		},
            // 	},
            // },
            // scales: {
            // 	x: {
            // 		grid: {
            // 			borderColor: "rgba(135, 160, 190,0.75)",
            // 			borderWidth: 3,
            // 			color: "rgba(135, 160, 190,0.5)",
            // 		},
            // 		ticks: {
            // 			color: `rgba(${config.color},1)`,
            // 			font: {
            // 				family: "Poppins",
            // 			},
            // 		},
            // 	},
            // 	y: {
            // 		grid: {
            // 			borderColor: "rgba(135, 160, 190,0.75)",
            // 			borderWidth: 3,
            // 			display: false,
            // 		},
            // 		ticks: {
            // 			color: `rgba(${config.color},1)`,
            // 			font: {
            // 				family: "Poppins",
            // 			},
            // 		},
            // 	},
          },
        },
      })
    );
  };

  return (
    <div className="chartContainer">
      <canvas id="myChart" ref={chart}></canvas>
    </div>
  );
}

export default LineChart;
