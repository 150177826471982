//Node Modules
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { NoHudHeader, PageHelmet } from "../components/general";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

//Atoms
import { toastAtom } from "../atoms/ToastAtom";

//Other
import { nav } from "../config/navigation";

function VerifyAttribute() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// Recoil State
	const [toast, setToast] = useRecoilState(toastAtom);

	// Local State
	const [user, setUser] = useState();

	//React Hook Form
	const defaultValues = {
		code: "",
		username: "",
		password: "",
	};
	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	useEffect(() => {
		console.log("Verify Attribute");
		const getCurrentUser = async () => {
			try {
				const currentUser = await Auth.currentAuthenticatedUser();
				setUser(currentUser);
			} catch (err) {
				console.warn("No Current User");
			}
		};

		getCurrentUser();
	}, []);

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="error small">{errors[name].message}</span>;
	};

	// Login Form Submit
	const doVerifyAttribute = async (data) => {
		try {
			if (user) {
				await Auth.verifyCurrentUserAttributeSubmit("email", data.code);
				await Auth.currentAuthenticatedUser({ bypassCache: true });
			} else {
				const newUser = await Auth.signIn(data.username, data.password);
				await Auth.verifyUserAttributeSubmit(newUser, "email", data.code);
				await Auth.signOut();
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("profile.email.toast.successSummary"),
				detail: t("profile.email.toast.successDetail"),
			});

			navigate(nav.home);
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("verifyAttribute.toast.errorSummary"),
				detail: t("verifyAttribute.toast.errorDetail", { error: err.message }),
			});
		}
	};

	return (
		<div className="noHud">
			<PageHelmet title={t("verifyAttribute.pageTitle")} />
			<NoHudHeader />

			<div className="noHudMain">
				<h2 className="marginBottomMedium">{t("verifyAttribute.pageTitle")}</h2>
				<div className="divider marginBottomLarge"></div>
				<form onSubmit={handleSubmit(doVerifyAttribute)}>
					<div className="formFieldsWrapper">
						{!user && (
							<>
								<div className="formField">
									<label htmlFor="username">{t("verifyAttribute.form.username.label")}</label>
									<Controller
										name="username"
										control={control}
										rules={{ required: t("common.form.error.required") }}
										render={({ field, fieldState }) => (
											<InputText
												id={field.username}
												{...field}
												className={classNames({ error: fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("username")}
								</div>
								<div className="formField">
									<label htmlFor="password">{t("verifyAttribute.form.password.label")}</label>
									<Controller
										name="password"
										control={control}
										rules={{ required: t("verifyAttribute.form.password.required") }}
										render={({ field, fieldState }) => (
											<Password
												id={field.password}
												{...field}
												feedback={false}
												toggleMask={true}
												className={classNames({ error: fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("password")}
								</div>
							</>
						)}
						<div className="formField">
							<label htmlFor="code">{t("verifyAttribute.form.email.title")}</label>
							<Controller
								name="code"
								control={control}
								rules={{ required: t("common.form.error.required") }}
								render={({ field, fieldState }) => (
									<InputText
										id={field.code}
										{...field}
										className={classNames({ error: fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("code")}
						</div>
					</div>

					<Button
						label={isSubmitting ? t("verifyAttribute.submitting") : t("verifyAttribute.submit")}
						type="submit"
						className="feature"
						disabled={isSubmitting}
					/>
				</form>
			</div>
		</div>
	);
}

export default VerifyAttribute;
