const DatatableItem = ({ value, type, wrapperStyle, icon }) => {
	return (
		<div className={`flex gapXSmall small ${wrapperStyle} datatableItem`}>
			{type !== "text" && <i className={`pi ${icon}`} />}
			{type !== "icon" && <span>{value}</span>}
		</div>
	);
};

export default DatatableItem;
