import React from "react";
import { useTranslation } from "react-i18next";

function InputPasswordTemp(props) {
	const { t } = useTranslation();
	const { register, errors, disabled, required } = props;

	const isRequired = required ? true : false;

	return (
		<div className="formField">
			<label htmlFor="passwordTemp">{t("pageRegistration.form.label.tempPassword")}</label>
			<input type="password" disabled={disabled} className={errors.passwordTemp ? "error" : ""} {...register("passwordTemp", { required: isRequired })} />
			<ul className="errors">{errors?.passwordTemp?.types?.required && <li className="error">{t("pageRegistration.form.error.tempPassword.required")}</li>}</ul>
		</div>
	);
}

export default InputPasswordTemp;
