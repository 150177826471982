import React from "react";
import { useTranslation } from "react-i18next";

function InputUsername(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, disabled } = props;

	return (
		<div className="formField">
			<label htmlFor="username">{t("common.form.label.username")}</label>
			<input
				type="text"
				defaultValue={defaultValue}
				disabled={disabled}
				className={errors.username ? "error" : ""}
				{...register("username", { required: !disabled, maxLength: 50 })}
			/>
			<ul className="errors">
				{errors?.username?.types?.required && <li className="error">{t("common.form.error.username.required")}</li>}
				{errors?.username?.types?.maxLength && <li className="error">{t("common.form.error.username.length")}</li>}
			</ul>
		</div>
	);
}

export default InputUsername;
