import { atom, atomFamily } from "recoil";

export const warningDialogAtom = atom({
	key: "warningDialogAtom",
	default: false,
});

export const projectSwitchDialogAtom = atom({
	key: "projectSwitchDialogAtom",
	default: false,
});

export const idleDialogAtom = atom({
	key: "idleDialogAtom",
	default: false,
});

export const reportSeriesEditDialogAtom = atom({
	key: "reportSeriesEditDialogAtom",
	default: false,
});

export const presetSelectDialogAtom = atom({
	key: "presetSelectDialog",
	default: false,
});

export const dialogAtomFamily = atomFamily({
	key: "dialogAtomFamily",
	default: false,
});

export const isDialogOpenAtom = atom({
	key: "isDialogOpenAtom",
	default: false,
});
