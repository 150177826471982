//Node Modules
import React, { useState } from "react";
import { useRecoilState, useSetRecoilState, useResetRecoilState } from "recoil";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";

//Pages

//BinaryForge Components

//3rd Party Components
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

//Atoms
import { loadingAtom } from "../atoms/LoadingAtom";
import { userAtom } from "../atoms/UserAtom";
import { modulesAtom } from "../atoms/ProjectAtom";
import { projectSwitchDialogAtom } from "../atoms/DialogAtom";

//Helpers
import { getAllModules } from "../services/BaseServices";

//Other
import { nav } from "../config/navigation";

function ProjectSelector() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	// Atom States
	const setLoading = useSetRecoilState(loadingAtom);
	const [user, setUser] = useRecoilState(userAtom);
	const setShow = useSetRecoilState(projectSwitchDialogAtom);
	const setModules = useSetRecoilState(modulesAtom);
	const resetUser = useResetRecoilState(userAtom);

	// Component States
	const [selectedProject, setSelectedProject] = useState(user.selectedProject);

	const handleRedirect = (path, isModuleEnabled) => {
		if (isModuleEnabled) {
			navigate(path);
		} else {
			navigate(nav.emergency.summary);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			location.pathname === nav.general.projectSelect &&
				setLoading({ visible: true, message: t("pageProjectSelect.loading") });

			if (user.selectedProject !== selectedProject) {
				setUser((prevState) => ({
					...prevState,
					selectedProject: selectedProject,
				}));

				const modules = await getAllModules(selectedProject);
				process.env.NODE_ENV === "development" && console.log("Modules:", modules);
				setModules(modules);

				const { emergency, casambi, report } = modules;
				//After changing the project, check if module is enabled for the new project and redirect to the main page of module
				switch (location.pathname.split("/")[1]) {
					case "emergency":
						handleRedirect(nav.emergency.summary, emergency);
						break;
					case "device":
						handleRedirect(nav.device.networks, casambi);
						break;
					case "report":
						handleRedirect(nav.report.preset, report);
						break;
					case "admin":
						if (location.pathname.split("/")[2] === "building") {
							navigate(nav.admin.building.buildingManagement);
						} else {
							navigate(nav.admin.account.management);
						}
						break;
					default:
						navigate(nav.emergency.summary);
				}
			}
		} catch (error) {
			console.error("No Session project selector:", error);
			setLoading({ visible: false, message: "" });
			resetUser();
			await Auth.signOut();
			return redirect(nav.general.logout);
		} finally {
			setShow(false);
		}
	};

	const formatProjectName = (option) => {
		return option.replace(/_/g, " ");
	};

	const formatSelectedProjectName = (option, props) => {
		if (option) return formatProjectName(option);
	};

	return (
		<form onSubmit={handleSubmit}>
			<Dropdown
				value={selectedProject}
				options={user.availableProjects}
				itemTemplate={formatProjectName}
				valueTemplate={formatSelectedProjectName}
				onChange={(e) => setSelectedProject(e.value)}
				placeholder="Select a Project"
			/>
			<Button
				type="submit"
				label={t("pageProjectSelect.submit")}
				className="feature"
				disabled={!selectedProject}
			/>
		</form>
	);
}

export default ProjectSelector;
