const MessageIcon = (props) => {
	const { icon, messageKey, messageStyle, wrapperStyle } = props;

	return (
		<div className={`grid column2 autoColumns gapSmall jContentStart ${wrapperStyle}`}>
			<i className={`pi large ${icon}`} />
			<p className={messageStyle}>{messageKey}</p>
		</div>
	);
};

export default MessageIcon;
