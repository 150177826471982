//Node Modules
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

//Pages

//BinaryForge Components
import ReportSeriesItem from "./ReportSeriesItem";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { reportDataAtom, reportSeriesIdAtom, customReportAddButtonStatus } from "../../atoms/ReportAtom";
import { isDialogOpenAtom } from "../../atoms/DialogAtom";

//Services

//Helpers

//Other

function ReportSeries() {
	const { t } = useTranslation();

	//Recoil State
	const [series, setSeries] = useRecoilState(reportSeriesIdAtom);
	const [reportData, setReportData] = useRecoilState(reportDataAtom);
	const [isOpen, setIsOpen] = useRecoilState(isDialogOpenAtom);
	const addButtonStatus = useRecoilValue(customReportAddButtonStatus);

	const addSeries = () => {
		const newSeries = { id: `series-${uuidv4()}` };
		setSeries((series) => [...series, newSeries]);
		setIsOpen(true);
	};

	const removeSeries = (id) => {
		const keepSeries = series.filter((s) => s.id !== id);
		setSeries(keepSeries);

		//remove from the chart
		const keepData = reportData.filter((r) => r.id !== id);
		setReportData(keepData);
	};

	process.env.NODE_ENV === "development" && console.log("Series : ", series);

	return (
		<>
			<header className="grid column2 autoColumns jSpaceBetween aItemsCenter marginBottomMedium">
				<h2>Data</h2>
				<Button
					type="submit"
					icon="pi pi-plus"
					label={t("pageReport.series.add")}
					className="feature"
					onClick={addSeries}
					disabled={series.length >= 3 || addButtonStatus}
				/>
			</header>
			<div className="flexVert gapMedium">
				{series.map((s, index) => {
					console.log("SERIES ITEM ::", s);
					return (
						<ReportSeriesItem
							key={s.id}
							id={s.id}
							removeSeries={removeSeries}
							//It avoids showing the series before user selects the report type
							showSeries={!(isOpen && index === series.length - 1)}
						/>
					);
				})}
			</div>
		</>
	);
}

export default ReportSeries;
