export const SummaryItem = ({ label, icon, children }) => {
	return (
		<div className="summaryItemWrapper">
			<div className="summaryItemLabel light">{label}</div>
			<div className="summaryItemContent">
				{icon && <span className={`summaryItemIcon ${icon}`}></span>}
				<div className="summaryItemValue">{children}</div>
			</div>
		</div>
	);
};
