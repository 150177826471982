import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";

function PageHeader(props) {
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<div className="pageHeader">
			<div className="pageHeaderContent">
				<div className="pageTitleWrapper">
					{props.enableBack && (
						<Button
							id="goBack"
							icon="pi pi-arrow-left"
							onClick={() => goBack()}
							className="round feature"
						/>
					)}
					<div className="pageTitle">
						<h1 className="">{props.title}</h1>
						<h3 className="pageSubtitle">{props.subtitle}</h3>
					</div>
				</div>
				<div className="pageTools">
					{props.secondaryBttn}
					{props.durationBttn}
					{props.featureBttn}
				</div>
			</div>
		</div>
	);
}

export default PageHeader;
