//Node Modules
import React from "react";
import { useRecoilState } from "recoil";
import { animateScroll as scroll } from "react-scroll";

//Pages

//BinaryForge Components
import { DeviceDetailsExpanded, DeviceDetailsCollapsed } from "./";

//3rd Party Components

//Atoms
import { expandableCardAtomFamily } from "../../atoms/ExpandableCardAtom";

//Services

//Helpers

//Other

function Expandable({ id, title, icon, details, type }) {
	const [card, setCard] = useRecoilState(expandableCardAtomFamily(id));

	const handleClick = (e) => {
		setCard({ active: !card.active });
		scroll.scrollTo(e.pageY);
	};

	return (
		<>
			<div className={`card summaryCard expandable expand-${card.active}`} onClick={(e) => handleClick(e)}>
				<header className="marginBottomMedium">
					<div className="large">
						{icon && <span className={`icon ${icon}`}></span>}
						<h2>{title}</h2>
					</div>
					{!card.active && (
						<div className="medium">
							<span className={`icon icon-diagnostics ${details.failure_status > 0 ? "error" : "success"}`}></span>
							<span className={`icon icon-light ${details.lamp_fail_status > 0 ? "error" : "success"}`}></span>
						</div>
					)}
				</header>

				{!card.active ? <DeviceDetailsCollapsed details={details} type={type} /> : <DeviceDetailsExpanded details={details} type={type} />}
			</div>
		</>
	);
}

export default Expandable;
