import i18n from "../../i18n";

export const getFails = (code, type) => {
	const fails = getFailMessages(code, type);
	return fails.length ? (
		fails.map((fail) => (
			<li key={fail.id} className="withPTooltip failItem" data-pr-tooltip={fail.tooltip}>
				{fail.message}
			</li>
		))
	) : (
		<li className="successItem">None</li>
	);
};

const getFailMessages = (code, type) => {
	const fails = [];
	const bits = code.toString(2).split("").reverse();
	bits.map((bit, idx) => {
		if (bit === "1") {
			if (type === "diag") fails.push(getDiagFailDesc(parseInt(idx)));
			else if (type === "light") fails.push(getLightFailDesc(parseInt(idx)));
		}
		return idx;
	});

	return fails;
};

const getDiagFailDesc = (bit) => {
	let desc = null;

	switch (bit) {
		case 0:
			desc = { id: `fail${bit}`, tooltip: i18n.t("dali.status.fail.0.full"), message: i18n.t("dali.status.fail.0.short") };
			break;
		case 1:
			desc = { id: `fail${bit}`, tooltip: i18n.t("dali.status.fail.1.full"), message: i18n.t("dali.status.fail.1.short") };
			break;
		case 2:
			desc = { id: `fail${bit}`, tooltip: i18n.t("dali.status.fail.2.full"), message: i18n.t("dali.status.fail.2.short") };
			break;
		case 3:
			desc = { id: `fail${bit}`, tooltip: i18n.t("dali.status.fail.3.full"), message: i18n.t("dali.status.fail.3.short") };
			break;
		case 4:
			desc = { id: `fail${bit}`, tooltip: i18n.t("dali.status.fail.4.full"), message: i18n.t("dali.status.fail.4.short") };
			break;
		case 5:
			desc = { id: `fail${bit}`, tooltip: i18n.t("dali.status.fail.5.full"), message: i18n.t("dali.status.fail.5.short") };
			break;
		default:
			desc = { message: i18n.t("dali.status.fail.none") };
	}

	return desc;
};

const getLightFailDesc = (bit) => {
	let desc = null;

	switch (bit) {
		case 0:
			desc = { id: `lfail${bit}`, tooltip: i18n.t("dali.status.lightfail.0.full"), message: i18n.t("dali.status.lightfail.0.short") };
			break;
		case 1:
			desc = { id: `lfail${bit}`, tooltip: i18n.t("dali.status.lightfail.1.full"), message: i18n.t("dali.status.lightfail.1.short") };
			break;
		case 2:
			desc = { id: `lfail${bit}`, tooltip: i18n.t("dali.status.lightfail.2.full"), message: i18n.t("dali.status.lightfail.2.short") };
			break;
		case 3:
			desc = { id: `lfail${bit}`, tooltip: i18n.t("dali.status.lightfail.3.full"), message: i18n.t("dali.status.lightfail.3.short") };
			break;
		case 4:
			desc = { id: `lfail${bit}`, tooltip: i18n.t("dali.status.lightfail.4.full"), message: i18n.t("dali.status.lightfail.4.short") };
			break;
		default:
			desc = { message: i18n.t("dali.status.fail.none") };
	}

	return desc;
};
