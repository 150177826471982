//Node Modules
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router";
import _ from "lodash";

//BinaryForge Components

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { classNames } from "primereact/utils";

//Atoms
import { selectedUserAtom, usersAtom } from "../../atoms/UserAtom";
// import { triggerSubmitAtom } from "../../../atoms/form";
import { toastAtom } from "../../atoms/ToastAtom";

//Helpers
import { useApiRequest } from "../../helpers/Api";

//Other
import { emailValidation, usernameValidation } from "../../config/user";
import { nav } from "../../config/navigation";

const UserForm = ({ type, userData, dupeUser }, ref) => {
	// Hooks
	const { t } = useTranslation();
	const navigate = useNavigate();
	const apiRequest = useApiRequest();

	// Recoil
	const [toast, setToast] = useRecoilState(toastAtom);
	// const [triggerSubmit, setTriggerSubmit] = useRecoilState(triggerSubmitAtom);
	const setUsers = useSetRecoilState(usersAtom);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);

	// Form Default Values
	const defaultValues = {
		username: "",
		fullname: "",
		email: "",
		emailConfirm: "",
		active: true,
	};

	// Form Init
	const {
		control,
		getValues,
		watch,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="error small">{errors[name].message}</span>;
	};

	// Form Handle Submit
	// useEffect(() => {
	// 	if (triggerSubmit) {
	// 		handleSubmit(onSubmit)();
	// 		setTriggerSubmit(false);
	// 	}
	// }, [triggerSubmit]);

	// Fetch the user if one is available
	useEffect(() => {
		if (type === "userEdit" && userData) {
			reset({
				username: userData.username,
				fullname: userData.fullname,
				email: userData.email,
				emailConfirm: userData.email,
				active: userData.active,
			});
		}
	}, [userData, type]);

	useImperativeHandle(ref, () => ({
		initUserUpdate() {
			handleSubmit(onSubmit)();
		},
	}));

	const onSubmit = async (data) => {
		console.log("Form Data ::", data);

		try {
			const apiMethod = type === "userCreate" ? "post" : "put";
			const apiEndpoint = type === "userCreate" ? "/admin/user" : `/admin/user/${selectedUser.username}`;
			const navigateTo =
				type === "userCreate" ? nav.admin.user.management : `${nav.admin.user.details}/${userData.username}`;
			const user = await apiRequest(apiMethod, apiEndpoint, data, t(`user.${type}.loading`));
			type === "userEdit" && setSelectedUser(user);

			setToast({
				...toast,
				severity: "success",
				summary: t(`user.${type}.successSummary`),
				detail: t(`user.${type}.successDetail`, { username: data.username, email: data.email }),
			});

			navigate(navigateTo, { replace: true });
		} catch (err) {
			if (type === "userCreate" && err.response.data.message === "user.userError.emailExists") {
				dupeUser(data.email);
			} else {
				setToast({
					...toast,
					severity: "error",
					summary: t(`user.${type}.errorSummary`),
					detail: t(`user.${type}.errorDetail`, {
						error: err.response.data.message,
						data: JSON.stringify(data),
					}),
				});
			}
		}
	};

	return (
		<form>
			<div className="grid columns-2 gapMedium">
				<div className="formField">
					<label htmlFor="username">{t("user.userForm.username.label")}</label>
					<Controller
						name="username"
						control={control}
						rules={{
							required: t("common.form.error.required"),
							pattern: { value: usernameValidation, message: t("user.userForm.username.pattern") },
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.username}
								{...field}
								readOnly={type === "userCreate" ? false : true}
								className={classNames({ error: fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("username")}
				</div>
				<div className="formField">
					<label htmlFor="fullname">{t("user.userForm.fullname.label")}</label>
					<Controller
						name="fullname"
						control={control}
						rules={{
							required: t("common.form.error.required"),
							pattern: { value: /^[A-Za-z\- ]+$/i, message: t("user.userForm.fullname.pattern") },
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.fullname}
								{...field}
								// readOnly={type === "userCreate" ? false : true}
								className={classNames({ error: fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("fullname")}
				</div>
				<div className="formField">
					<label htmlFor="email">{t("user.userForm.email.label")}</label>
					<Controller
						name="email"
						control={control}
						rules={{
							required: t("common.form.error.required"),
							pattern: {
								value: emailValidation,
								message: t("user.userForm.email.pattern"),
							},
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.email}
								{...field}
								className={classNames({ error: fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("email")}
				</div>
				<div className="formField">
					<label htmlFor="emailConfirm">{t("user.userForm.emailConfirm.label")}</label>
					<Controller
						name="emailConfirm"
						control={control}
						rules={{
							required: t("common.form.error.required"),
							pattern: {
								value: emailValidation,
								message: t("user.userForm.emailConfirm.pattern"),
							},
							validate: {
								match: (value) => getValues("email") === value || t("user.userForm.emailConfirm.match"),
							},
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.emailConfirm}
								{...field}
								disabled={userData && userData.email === watch("email") && type === "userEdit"}
								className={classNames({ error: fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("emailConfirm")}
				</div>
				<div className="formField">
					<label htmlFor="active">{t("user.userForm.active.label")}</label>
					<Controller
						name="active"
						control={control}
						render={({ field, fieldState }) => (
							<InputSwitch
								id={field.active}
								{...field}
								checked={field.value}
								onChange={(e) => field.onChange(e.value)}
								className={classNames({ error: fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("active")}
				</div>
			</div>
		</form>
	);
};

export default forwardRef(UserForm);
