import React from "react";
import { useTranslation } from "react-i18next";

function InputPasswordConfirm(props) {
	const { t } = useTranslation();
	const { register, errors, disabled, disabledConfirm, values, required } = props;

	return (
		<>
			<div className="formField">
				<label htmlFor="password">{t("pageRegistration.form.label.newPassword")}</label>
				<input
					type="password"
					disabled={disabled}
					className={errors.password ? "error" : ""}
					{...register("password", { required: required, minLength: 8, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-]).{8,}$/ })}
				/>
				<ul className="errors">
					{errors?.password?.types?.required && <li className="error">{t("pageRegistration.form.error.newPassword.required")}</li>}
					{errors?.password?.types?.minLength && <li className="error">{t("pageRegistration.form.error.newPassword.length")}</li>}
					{errors?.password?.types?.pattern && <li className="error">{t("pageRegistration.form.error.newPassword.pattern")}</li>}
				</ul>
			</div>
			<div className="formField">
				<label htmlFor="passwordConfirm">{t("pageRegistration.form.label.confirmPassword")}</label>
				<input
					type="password"
					disabled={disabledConfirm}
					className={errors.passwordConfirm ? "error" : ""}
					{...register("passwordConfirm", { validate: { match: (value) => values("password") === value } })}
				/>
				<ul className="errors">{errors?.passwordConfirm?.types?.match && <li className="error">{t("pageRegistration.form.error.confirmPassword.match")}</li>}</ul>
			</div>
		</>
	);
}

export default InputPasswordConfirm;
