import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { nav, guestRoutes } from "../../config/navigation";
import { useIsValidSession } from "../../helpers/Auth";

const GuestRoute = ({ children }) => {
	let location = useLocation();
	const isValidSession = useIsValidSession();

	const [isValid, setIsValid] = useState();

	useEffect(() => {
		const validSession = async () => {
			setIsValid((await isValidSession()) && guestRoutes.includes(location.pathname) ? true : false);
		};

		validSession();
	}, [location, children, isValidSession]);

	if (isValid === undefined) return null;

	return isValid ? <Navigate to="/" replace /> : children;
};

export default GuestRoute;
