//Node Modules
import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

//Pages

//BinaryForgeComponenets
import { PageHeader, PageHelmet } from "../../../components/general";
import { BuildingFormContent } from "../../../components/building";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { loadingAtom } from "../../../atoms/LoadingAtom";
import { toastAtom } from "../../../atoms/ToastAtom";
import { selectedBuildingAtom } from "../../../atoms/BuildingAtom";

//Helpers
import { CREATE_BUILDING } from "../../../helpers/Constants";
import { useMakeRequest } from "../../../helpers/NetworkUtils";

//Other
import { nav } from "../../../config/navigation";

function BuildingCreate() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const hiddenSubmit = useRef();
	const makeRequest = useMakeRequest();

	// Atom States
	const setLoading = useSetRecoilState(loadingAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const [selectedBuilding, setSelectedBuilding] = useRecoilState(selectedBuildingAtom);
	// const resetBuilding = useResetRecoilState(selectedBuildingAtom);

	useEffect(() => {
		// Resetting the building would be the best thing to do here but it does not cause a component render
		// resetBuilding();
		setSelectedBuilding({
			name: null,
			latitude: 0,
			longitude: 0,
			overdueFunctional: 30,
			overdueDuration: 365,
		});
	}, []);

	// React Hook Form
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	// On Form Submit
	const onSubmit = async (data) => {
		try {
			setLoading({ visible: true, message: "Saving Building Details" });
			await makeRequest("post", CREATE_BUILDING, {
				...data,
				gatewayId: selectedBuilding.gateway ? selectedBuilding.gateway.id : null,
			});
			setToast({
				...toast,
				severity: "success",
				summary: t("pageBuildingCreate.toast.successSummary"),
				detail: t("pageBuildingCreate.toast.successDetail"),
			});
			navigate(nav.admin.building.buildingManagement);
		} catch (err) {
			console.error("BuildingCreate onSubmit Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageBuildingCreate.toast.errorSummary"),
				detail: t("pageBuildingCreate.toast.errorDetail", { err: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	// Page Buttons
	const saveBuildingBttn = (
		<Button
			className="feature"
			label={t("pageBuildingCreate.featureBttn")}
			icon="pi pi-save"
			onClick={() => hiddenSubmit?.current?.click()}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageBuildingCreate.pageHeader")} />
			<PageHeader enableBack title={t("pageBuildingCreate.pageHeader")} featureBttn={saveBuildingBttn} />
			<main>
				<div className="mainContent">
					<form onSubmit={handleSubmit(onSubmit)}>
						{!selectedBuilding.name && (
							<BuildingFormContent register={register} errors={errors} setValue={setValue} />
						)}
						<button type="submit" hidden={true} ref={hiddenSubmit} />
					</form>
				</div>
			</main>
		</>
	);
}

export default BuildingCreate;
