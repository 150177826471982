//Node Modules
import React, { useEffect, useRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState, useRecoilState, useResetRecoilState } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

//Helpers
import { today, formatDateTimeWithSeconds } from "../../helpers/DateUtils";
import { reportTypeIcon } from "../../helpers/chart/reportFilterOptions";
import {
	reportTableFiltersAtom,
	reportTableFilteredDataAtom,
	reportTableFormattedDataAtom,
	reportTableColumnsAtom,
} from "../../atoms/ReportAtom";

//Other
const appElement = document.getElementById("appWrapper");

export default function PresetDatatableComponent({ formattedData, reportName, columns }) {
	const dt = useRef(null);
	const { t } = useTranslation();
	const [tableFormattedData, setTableFormattedData] = useRecoilState(reportTableFormattedDataAtom);
	const setReportTableFilter = useSetRecoilState(reportTableFiltersAtom);
	const setReportTableColumns = useSetRecoilState(reportTableColumnsAtom);
	const [filteredData, setFilteredData] = useRecoilState(reportTableFilteredDataAtom);

	//Reset table global state
	const resetFilters = useResetRecoilState(reportTableFiltersAtom);
	const resetFilteredData = useResetRecoilState(reportTableFilteredDataAtom);
	const resetTblFormattedData = useResetRecoilState(reportTableFormattedDataAtom);
	const resetTblColumns = useResetRecoilState(reportTableColumnsAtom);

	const [filters, setFilters] = useState(null);

	const resetTblGlobalState = useCallback(() => {
		resetFilters();
		resetFilteredData();
		resetTblFormattedData();
		resetTblColumns();
	}, [resetFilteredData, resetFilters, resetTblColumns, resetTblFormattedData]);

	const initFilters = useCallback(() => {
		const dataColumns = columns.map(({ name }) => {
			return {
				[name]: {
					operator: FilterOperator.AND,
					constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
				},
			};
		});
		const items = Object.assign(...dataColumns);

		setFilters({
			timestamp: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			...items,
		});
	}, [columns]);

	useEffect(() => {
		initFilters();
		//Set global tbl state for every render parent to use in order to export data
		if (formattedData) {
			setTableFormattedData(formattedData);
			setReportTableColumns(columns);
		}

		return () => {
			//Cleanup tbl state before a re-render
			resetTblGlobalState();
		};
	}, [columns, formattedData, initFilters, resetTblGlobalState, setReportTableColumns, setTableFormattedData]);

	useEffect(() => {
		//Set filters and filtered data for parent to use in order to export data
		setReportTableFilter(dt.current.state.filters);
	}, [setReportTableFilter, filteredData]);

	const functionDateRowTemplate = (rowData) => {
		return formatDateTimeWithSeconds(rowData.timestamp);
	};

	const formatMeasureValue = (rowData, columnName, id) => {
		if (rowData.label) {
			return (
				<>
					<Tooltip target=".unitLabel" mouseTrack mouseTrackLeft={10} />
					<p className="unitLabel" data-pr-tooltip={rowData.label}>
						{rowData[columnName]}
					</p>
				</>
			);
		} else {
			return <p>{rowData[columnName]}</p>;
		}
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const basicNumberFilterTemplate = (options) => {
		return (
			<InputNumber
				value={options.value}
				mode="decimal"
				minFractionDigits={2}
				showButtons
				onChange={(e) => options.filterCallback(e.value, options.index)}
			/>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				maxDate={today.toJSDate()}
				showButtonBar
				showTime
				showSeconds
				appendTo={appElement}
			/>
		);
	};

	return (
		<div className="card">
			{reportName && <h2 className="marginBottomLarge customClassName">{reportName}</h2>}
			<DataTable
				ref={dt}
				value={tableFormattedData}
				emptyMessage={t("common.table.noData")}
				sortMode="multiple"
				removableSort
				filters={filters}
				filterDisplay="menu"
				autoLayout={true}
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
				currentPageReportTemplate={t("common.table.paginatorTemplate")}
				rows={10}
				onValueChange={(filteredData) => setFilteredData(filteredData)}>
				<Column
					field="timestamp"
					header={t("pageReport.table.timestamp")}
					body={functionDateRowTemplate}
					dataType="date"
					sortable
					filter
					filterElement={dateFilterTemplate}
					filterApply={filterApplyTemplate}
				/>
				{columns.map((column) => {
					return (
						<Column
							key={column.id}
							field={column.name}
							header={
								<>
									<Tooltip target=".reportTypeIcon" mouseTrack mouseTrackLeft={10} />
									<span className="p-column-title">
										<i
											className={`icon ${
												reportTypeIcon[column.type]
											} small marginRightXSmall reportTypeIcon`}
											data-pr-tooltip={t(`pageReport.reportTypes.${column.type}`)}
											data-pr-position="top"
										/>
										{column.name}
									</span>
								</>
							}
							dataType="numeric"
							sortable
							filter
							filterElement={basicNumberFilterTemplate}
							filterApply={filterApplyTemplate}
							body={(rowData) => formatMeasureValue(rowData, column.name, column.id)}
						/>
					);
				})}
			</DataTable>
		</div>
	);
}
