//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../components/general";

//3rd Party Components
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { toastAtom } from "../../atoms/ToastAtom";
import { userAtom } from "../../atoms/UserAtom";
import { selectedNetworkAtom } from "../../atoms/NetworkAtom";

//Services

//Helpers
import { formatDateTime, today } from "../../helpers/DateUtils";
import { useApiRequest, useMakeRequest } from "../../helpers/NetworkUtils";
import { GET_ALL_NETWORKS, REFRESH_CLOUD_NETWORKS, GET_ALL_ACCOUNT_TYPES } from "../../helpers/Constants";

//Other
import { nav } from "../../config/navigation";
const appElement = document.getElementById("appWrapper");

function Networks() {
	const { t } = useTranslation();
	const dt = useRef(null);
	const navigate = useNavigate();

	// const setLoading = useSetRecoilState(loadingAtom);
	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const [selectedNetwork, setSelectedNetwork] = useRecoilState(selectedNetworkAtom);
	const resetNetwork = useResetRecoilState(selectedNetworkAtom);

	const [networks, setNetworks] = useState();
	const [endpoint, setEndpoint] = useState(GET_ALL_NETWORKS);
	const [filters, setFilters] = useState(null);
	const [accountTypeOptions, setAccountTypeOptions] = useState([]);

	const { data } = useApiRequest("get", endpoint, null);
	const makeRequest = useMakeRequest();

	useEffect(() => {
		resetNetwork();
		setNetworks(null);
		initFilters();
		setLoading({ visible: true, message: t("pageNetworks.loading") });
	}, [user]);

	useEffect(() => {
		data &&
			data.map((d) => {
				d.createdAt = new Date(d.createdAt);
				d.updatedAt = new Date(d.updatedAt);
				return d;
			});
		setNetworks(data);
		setEndpoint(null);
	}, [data]);

	useEffect(() => {
		getAccountTypes();
	}, []);

	const getAccountTypes = async () => {
		const resp = await makeRequest("get", GET_ALL_ACCOUNT_TYPES, null);
		setAccountTypeOptions(resp);
	};

	const refreshCloudData = async () => {
		try {
			setLoading({
				visible: true,
				message: t("pageNetworks.refreshCloudData"),
			});

			setEndpoint(REFRESH_CLOUD_NETWORKS);
		} catch (e) {
			console.error(e);
			setToast({
				...toast,
				severity: "error",
				summary: t("common.toast.loadDataFailedSummary"),
				detail: t("common.toast.loadDataFailedDetail"),
			});
		}
	};

	const initFilters = () => {
		setFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			id: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			"account.accountType.name": { value: null, matchMode: FilterMatchMode.IN },
			"building.name": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			createdAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			updatedAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				maxDate={today.toJSDate()}
				showButtonBar
				showTime={true}
				appendTo={appElement}
			/>
		);
	};

	const typeFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={accountTypeOptions}
				optionLabel="name"
				optionValue="name"
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const idRowTemplate = (rowData) => {
		return (
			<div className="withTooltip" aria-label={rowData.id} onClick={(e) => copyId(e)}>
				{rowData.id.substr(0, 8)}...
			</div>
		);
	};

	const copyId = (e) => {
		navigator.clipboard.writeText(e.target.ariaLabel);
		setToast({
			...toast,
			severity: "success",
			summary: t("pageNetworks.toast.copyIdSummary"),
			detail: t("pageNetworks.toast.copyIdDetail"),
		});
	};

	const createdAtRowTemplate = (rowData) => {
		return formatDateTime(rowData.createdAt);
	};

	const updatedAtRowTemplate = (rowData) => {
		return formatDateTime(rowData.updatedAt);
	};

	const refreshCloudDataBttn = (
		<Button
			className="feature"
			label={t("pageNetworks.featureBttn")}
			icon="pi pi-refresh"
			onClick={refreshCloudData}
		/>
	);
	const assignBuildingBttn = (
		<Button
			label={t("pageNetworks.secondaryBttn")}
			icon="pi pi-home"
			onClick={() => navigate(nav.device.networkBuilding)}
			disabled={!selectedNetwork}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageNetworks.pageHeader")} />
			<PageHeader
				title={t("pageNetworks.pageHeader")}
				featureBttn={refreshCloudDataBttn}
				secondaryBttn={assignBuildingBttn}
			/>
			<main>
				<div className="mainContent">
					<div className="card">
						<DataTable
							ref={dt}
							value={networks}
							emptyMessage={t("common.table.noData")}
							selectionMode="single"
							selection={selectedNetwork}
							onSelectionChange={(e) => setSelectedNetwork(e.value)}
							sortMode="multiple"
							removableSort
							filters={filters}
							filterDisplay="menu"
							autoLayout={true}
							paginator
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
							currentPageReportTemplate={t("common.table.paginatorTemplate")}
							rows={10}
							dataKey="id">
							<Column
								field="name"
								header={t("common.table.name")}
								sortable
								filter
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="id"
								header="ID"
								body={idRowTemplate}
								sortable
								filter
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="account.accountType.name"
								header={t("pageNetworks.table.system")}
								sortable
								filter
								showFilterMatchModes={false}
								filterElement={typeFilter}
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="building.name"
								header={t("pageNetworks.table.building")}
								sortable
								filter
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="createdAt"
								header={t("common.table.addedAt")}
								body={createdAtRowTemplate}
								dataType="date"
								sortable
								filter
								filterElement={dateFilterTemplate}
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="updatedAt"
								header={t("common.table.updatedAt")}
								body={updatedAtRowTemplate}
								dataType="date"
								sortable
								filter
								filterElement={dateFilterTemplate}
								filterApply={filterApplyTemplate}
							/>
						</DataTable>
					</div>
				</div>
			</main>
		</>
	);
}

export default Networks;
