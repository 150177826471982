//Node Modules
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet, SummaryItem } from "../../components/general";
import { ExpandableCard, DeviceDetailsExpanded } from "../../components/device";

//3rd Party Components
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { userAtom } from "../../atoms/UserAtom";
import { selectedDeviceAtom } from "../../atoms/DeviceAtom";

//Services

//Helpers
import { getDevicesState } from "../../helpers/Constants";
import { useApiRequest } from "../../helpers/NetworkUtils";

//Other

function DevicesBuilding() {
	const { t } = useTranslation();

	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const selectedDevice = useRecoilValue(selectedDeviceAtom);

	const [deviceDetails, setDeviceDetails] = useState();

	// If using static device data make sure lines 42 - 44 are uncommented
	// If using live device data make sure lines 42 - 44 are commented out
	// useEffect(() => {
	// 	setDeviceDetails(testData);
	// }, []);

	// If using static device data make sure lines 48 - 56 are commented out
	// If using live device data make sure lines 48 - 56 are uncommented
	// const data = useGetData(getDeviceState, { networkId: selectedDevice.networkId, deviceId: selectedDevice.id });
	const { data, apiRequest } = useApiRequest(
		"get",
		getDevicesState({
			networkId: selectedDevice.network.id,
			deviceId: selectedDevice.id,
		}),
		null
	);

	useEffect(() => {
		setLoading({ visible: true, message: t("pageDevices.loading") });
	}, [user]);

	useEffect(() => {
		setDeviceDetails(data);
	}, [data]);

	const refreshData = () => {
		setLoading({ visible: true, message: t("pageDevices.loading") });
		apiRequest();
	};

	const getUnitStatus = () => {
		const online = deviceDetails.stdData.online ? "Online" : "Offline";
		const on = deviceDetails.stdData.on ? "On" : "Off";
		const dim =
			deviceDetails.stdData.dimLevel !== undefined
				? ` (${Math.round(deviceDetails.stdData.dimLevel * 100)}%)`
				: "";

		return `${online} - ${on} ${dim}`;
	};

	const getStatusIcon = () => {
		const online = deviceDetails.stdData.online ? "icon-online" : "icon-offline";
		const on = deviceDetails.stdData.on ? "success" : "error";

		return `${online} ${on}`;
	};

	const getTypeIcon = () => {
		const type = deviceDetails.stdData.type;
		let icon = "icon-device";

		if (type === "Luminaire") {
			icon = "icon-light warn";
		} else if (type === "BatterySwitch") {
			icon = "icon-switch info";
		} else if (type === "Gateway") {
			icon = "icon-gateway success";
		} else if (type === "Driver") {
			icon = "icon-driver success";
		}

		return icon;
	};

	const viewDataBttn = (
		<Button
			className="feature"
			label={t("pageDeviceDetails.featureBttn")}
			icon="pi pi-refresh"
			onClick={refreshData}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageDeviceDetails.pageHeader")} />
			<PageHeader
				title={t("pageDeviceDetails.pageHeader")}
				subtitle={`${t("pageDeviceDetails.pageSubTitle")}: ${selectedDevice.name}`}
				featureBttn={viewDataBttn}
				enableBack
			/>
			<main>
				{deviceDetails && (
					<>
						<div className="mainContent">
							<Tooltip target=".withPTooltip" autoHide={false} hideDelay={500} />

							<div className="card column2 gapMedium">
								<SummaryItem label={t("pageDeviceDetails.properties.state")} icon={getStatusIcon()}>
									{getUnitStatus()}
								</SummaryItem>
								{deviceDetails.stdData.details && (
									<SummaryItem label="OEM:" icon="pi pi-cog">
										{deviceDetails.stdData.details.OEM}{" "}
									</SummaryItem>
								)}
								<SummaryItem label={t("pageDeviceDetails.properties.type")} icon={getTypeIcon()}>
									{deviceDetails.stdData.type}
								</SummaryItem>
								{deviceDetails.stdData.details && (
									<SummaryItem label={t("pageDeviceDetails.properties.fixture")} icon="icon-fixture">
										{deviceDetails.stdData.details.fixture_model}
									</SummaryItem>
								)}
								{deviceDetails.energyData && (
									<SummaryItem
										label={t("pageDeviceDetails.properties.energy")}
										icon="icon-energy info">
										{deviceDetails.energyData?.energy_total?.toFixed(2)}{" "}
										<span className="small light">Wh</span>
									</SummaryItem>
								)}
								<SummaryItem label={t("pageDeviceDetails.properties.firmware")} icon="icon-code">
									{deviceDetails.stdData.firmwareVersion}
								</SummaryItem>
							</div>

							{deviceDetails.daliData &&
								(!Array.isArray(deviceDetails.daliData) ? (
									<div className="card summaryCard">
										<header className="marginBottomMedium">
											<div className="large">
												<span className="icon icon-driver success"></span>
												<h2>{`${t("pageDeviceDetails.properties.address")} ${
													deviceDetails.daliData.address
												}`}</h2>
											</div>
										</header>

										<DeviceDetailsExpanded
											details={deviceDetails.daliData}
											energy={deviceDetails.energyData}
											type={deviceDetails.stdData.type}
										/>
									</div>
								) : (
									<div className="column3 gapMedium">
										{deviceDetails.daliData.map((daliDevice) => {
											return (
												<ExpandableCard
													key={daliDevice.serial}
													id={daliDevice.serial}
													title={`${t("pageDeviceDetails.properties.address")} ${
														daliDevice.address
													}`}
													icon="icon-driver"
													details={daliDevice}
													type={deviceDetails.stdData.type}
												/>
											);
										})}
									</div>
								))}
						</div>
					</>
				)}
			</main>
		</>
	);
}

export default DevicesBuilding;
