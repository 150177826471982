export const formatReportData = (data) => {
	//Check if report is aggregating values
	const isAggregating = data[0].aggregate_type;
	return data.reduce((acc, series) => {
		series.data.forEach((log, index) => {
			const getTimestampIdx = acc.findIndex((obj) => {
				//If there is a building or a device and values have no aggregation
				if (isAggregating === -1) {
					return false;
				} else {
					return obj.timestamp.toString() === new Date(log.timestamp).toString();
				}
			});
			if (getTimestampIdx === -1) {
				const newObj = { timestamp: new Date(log.timestamp), [series.name]: log.measure_value };
				if (isAggregating === -1) {
					newObj.label = log.label.name;
				}
				acc.push(newObj);
			} else {
				const existingObj = acc[getTimestampIdx];
				existingObj[series.name] = log.measure_value;
			}
		});
		return acc;
	}, []);
};

export const getColumnsForReportTable = (data) => {
	return data.map(({ id, type, name }) => ({ id, type, name }));
};
