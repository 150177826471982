//Node Modules
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

//Atoms
import { testBookStateAtomFamily, testBookDataAtomFamily, testBookTableFilterAtom } from "../../atoms/TestBookAtom";

//Helpers
import { formatDateTime, today } from "../../helpers/DateUtils";
import {
	testSubTypeOptions,
	testSubTypeTemplate,
	testSubTypeFilterTemplate,
	testTypeOptions,
	testTypeOptionsTemplate,
	testTypeFilterTemplate,
	mannerOptions,
	mannerOptionsTemplate,
	mannerFilterTemplate,
} from "../../helpers/datatable/TestBookDatatableUtils";
import { resultOptions, resultTemplate, resultFilterTemplate } from "../../helpers/datatable/SummaryDatatableUtils";

//Other
const appElement = document.getElementById("appWrapper");

const TestBookData = ({ data }) => {
	const { t } = useTranslation();
	const dt = useRef(null);

	// Local State
	const [expandedRows, setExpandedRows] = useState(null);

	const [filterState, setFilterState] = useRecoilState(testBookTableFilterAtom);
	const setTableState = useSetRecoilState(testBookStateAtomFamily(data.hardwareId));
	const setTableData = useSetRecoilState(testBookDataAtomFamily(data.hardwareId));

	useEffect(() => {
		setTableState({
			title: ["Test Book Data", `${data.name} - ${data.hardwareId}`],
			tableFilters: filterState,
			tableProps: dt.current.props,
		});
	}, [filterState]);

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const rowExpansionTemplate = (data) => {
		return (
			<div className="marginLeftLarge">
				<span className="marginBottomMedium bold">{t("pageTestBook.table.expHeader")}</span>
				{data.testBookSubEntry.map((entry, index) => {
					return (
						<div
							key={`${entry.entryNo}${entry.subEntryNo}-${index}`}
							className="column2 jContentStart autoColumns gapRowXSmall gapColSmall marginBottomMedium">
							<span>{t("pageTestBook.table.path")}:</span>
							<span>{entry.path}</span>
							<span>{t("pageTestBook.table.result")}:</span>
							<span>{entry.result}</span>
							<span>{t("pageTestBook.table.details")}:</span>
							<span>{entry.detailResult}</span>
						</div>
					);
				})}
			</div>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				maxDate={today.toJSDate()}
				showButtonBar
				showTime={true}
				appendTo={appElement}
			/>
		);
	};

	const dateTimeRowTemplate = (rowData) => {
		return formatDateTime(rowData.timestamp);
	};

	const testTypeRowTemplate = (rowData) => {
		return testTypeOptionsTemplate(rowData.type);
	};

	const mannerRowTemplate = (rowData) => {
		return mannerOptionsTemplate(rowData.manner);
	};

	const testSubTypeRowTemplate = (rowData) => {
		return testSubTypeTemplate(rowData.subType);
	};

	const resultRowTemplate = (rowData) => {
		return resultTemplate(rowData.result);
	};

	const testTypeFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={testTypeOptions}
				itemTemplate={testTypeFilterTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const mannerFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={mannerOptions}
				itemTemplate={mannerFilterTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const testSubTypeFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={testSubTypeOptions}
				itemTemplate={testSubTypeFilterTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const resultsFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={resultOptions}
				itemTemplate={resultFilterTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const rowClass = (rowData) => {
		return {
			hideExpander: rowData.testBookSubEntry.length <= 0,
		};
	};

	return (
		<>
			<div className="card">
				<div className="column2 autoColumns gapColSmall jContentStart bold marginBottomMedium">
					<span className="bold">{t("pageTestBook.scenecom")} Name:</span>
					<span>{data.name}</span>
					<span>{t("pageTestBook.scenecom")} Id:</span>
					<span>{data.hardwareId}</span>
				</div>
				<div className="column2 gapColLarge gapRowMedium">
					<div className="column2 autoColumns gapSmall">
						<span>{t("pageTestBook.updated")}:</span>
						<span>{formatDateTime(data.updatedAt)}</span>
						<span>{t("pageTestBook.lastFunction")}:</span>
						<span className="flexVertCentre">
							{resultTemplate(data.emergencySummary.lastFunctionTest)}
							<span>-</span>
							{formatDateTime(data.emergencySummary.lastFunctionTestDate)}
						</span>
						<span>{t("pageTestBook.lastDuration")}:</span>
						<span className="flexVertCentre">
							{resultTemplate(data.emergencySummary.lastDurationTest)}
							<span>-</span>
							{formatDateTime(data.emergencySummary.lastDurationTestDate)}
						</span>
					</div>
					<div className="column2 autoColumns gapRowSmall">
						<span>{t("pageTestBook.numFaulty")}:</span>
						<span>{data.emergencySummary.numberOfFaultyLuminaires}</span>
						<span>{t("pageTestBook.numLocked")}:</span>
						<span>{data.emergencySummary.numberOfLockedLuminaires}</span>
						<span>{t("pageTestBook.numEmOp")}:</span>
						<span>{data.emergencySummary.numberOfLuminairesInEmergencyOperation}</span>
						<span>{t("pageTestBook.numMainsOp")}:</span>
						<span>{data.emergencySummary.numberOfLuminairesInMainsOperation}</span>
					</div>
				</div>
			</div>
			<div className="card">
				<DataTable
					ref={dt}
					value={data.testBookEntry}
					emptyMessage={t("common.table.noData")}
					sortMode="multiple"
					removableSort
					paginator
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
					currentPageReportTemplate={t("common.table.paginatorTemplate")}
					rows={10}
					expandedRows={expandedRows}
					onRowToggle={(e) => setExpandedRows(e.data)}
					rowExpansionTemplate={rowExpansionTemplate}
					dataKey="entryNo"
					rowClassName={rowClass}
					onValueChange={(filteredData) => setTableData(filteredData)}
					filters={filterState}
					filterDisplay="menu"
					onFilter={(e) => setFilterState(e.filters)}>
					<Column expander style={{ width: "3em" }} />
					{/* <Column field="entryNo" header={t("pageTestBook.table.entry")} sortable filter filterMatchMode="contains" /> */}
					<Column
						field="timestamp"
						header={t("pageTestBook.table.timestamp")}
						body={dateTimeRowTemplate}
						dataType="date"
						sortable
						filter
						filterElement={dateFilterTemplate}
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="type"
						header={t("pageTestBook.table.type")}
						body={testTypeRowTemplate}
						sortable
						filter
						showFilterMatchModes={false}
						filterElement={testTypeFilter}
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="subType"
						header={t("pageTestBook.table.subType")}
						body={testSubTypeRowTemplate}
						sortable
						filter
						showFilterMatchModes={false}
						filterElement={testSubTypeFilter}
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="manner"
						header={t("pageTestBook.table.manner")}
						body={mannerRowTemplate}
						sortable
						filter
						showFilterMatchModes={false}
						filterElement={mannerFilter}
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="path"
						header={t("pageTestBook.table.path")}
						sortable
						filter
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="result"
						header={t("pageTestBook.table.result")}
						body={resultRowTemplate}
						sortable
						filter
						showFilterMatchModes={false}
						filterElement={resultsFilter}
						filterApply={filterApplyTemplate}
					/>
					{/* <Column field="duration" header={t("pageTestBook.table.duration")} body={durationRowTemplate} sortable filter filterMatchMode="contains" /> */}
				</DataTable>
			</div>
		</>
	);
};

export default TestBookData;
