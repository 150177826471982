import React from "react";
import { useTranslation } from "react-i18next";

function InputPasswordConfirm(props) {
	const { t } = useTranslation();
	const { register, errors, disabled, disabledConfirm, values } = props;

	return (
		<>
			<div className="formField">
				<label htmlFor="password">{t("pageAccountCreate.form.label.password")}</label>
				<input type="password" disabled={disabled} className={errors.password ? "error" : ""} {...register("password", { required: true })} />
				<ul className="errors">{errors?.password?.types?.required && <li className="error">{t("pageAccountCreate.form.error.password.required")}</li>}</ul>
			</div>
			<div className="formField">
				<label htmlFor="passwordConfirm">{t("pageAccountCreate.form.label.confirmPassword")}</label>
				<input
					type="password"
					disabled={disabledConfirm}
					className={errors.passwordConfirm ? "error" : ""}
					{...register("passwordConfirm", { validate: { match: (value) => values("password") === value } })}
				/>
				<ul className="errors">{errors?.passwordConfirm?.types?.match && <li className="error">{t("pageAccountCreate.form.error.password.match")}</li>}</ul>
			</div>
		</>
	);
}

export default InputPasswordConfirm;
