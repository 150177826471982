import { atom } from "recoil";

export const loadingAtom = atom({
	key: "loadingAtom",
	default: {
		visible: false,
		message: "saving user",
	},
});

export const refreshDataAtom = atom({
	key: "refreshDataAtom",
	default: false,
});
