//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Services

//Helpers
import { formatDateTime, toTimeBasic } from "../../helpers/DateUtils";
import { getFails } from "../../helpers/device/Status";

//Other
const GTIN_URL_PREFIX =
  "https://www.dali-alliance.org/products?Default_submitted=1&brand_id=&part_number=&product_name=&family_products%5B%5D=&product_id=&gtin=";
const GTIN_URL_SUFFIX = "&Default-submit=Search";

function DeviceDetailsExpanded({ details, energy, type }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="column3 gapLarge">
        <div className="column2 autoColumns listGap">
          <span>{t("pageDeviceDetails.properties.GTIN")}</span>
          <span>
            <a
              href={`${GTIN_URL_PREFIX}${details.GTIN}${GTIN_URL_SUFFIX}`}
              target="_blank"
              rel="noreferrer"
            >
              {details.GTIN}
            </a>
          </span>
          <span>{t("pageDeviceDetails.properties.serial")}</span>
          <span>{details.serial}</span>
          <span>{t("pageDeviceDetails.properties.model")}</span>
          <span>{details.model ? details.model : "N/A"}</span>
        </div>
        <div className="column2 autoColumns listGap">
          <span>{t("pageDeviceDetails.properties.type")}</span>
          <span>{details.device_type ? details.device_type : "N/A"}</span>
          <span>{t("pageDeviceDetails.properties.status")}</span>
          <span>{details.status ? details.status : "N/A"}</span>
        </div>
        <div className="column2 autoColumns listGap">
          <span>{t("pageDeviceDetails.properties.opTime")}</span>
          <span>
            {details.operating_time
              ? toTimeBasic(details.operating_time, "seconds", "days")
              : "N/A"}
          </span>
          <span>{t("pageDeviceDetails.properties.manDate")}</span>
          <span>
            {details.manufacture_date
              ? formatDateTime(details.manufacture_date)
              : "N/A"}
          </span>
        </div>
      </div>
      {details.device_type && details.device_type.includes("51") && (
        <div className="column3 gapLarge marginTopLarge">
          <div>
            <header className="marginBottomMedium">
              <div className="large">
                <span className={`icon icon-diagnostics error`}></span>
                <h2>Diagnostics</h2>
              </div>
            </header>
            <div className="column2 autoColumns listGap">
              <span>{t("pageDeviceDetails.properties.temp")}</span>
              {details.temperature !== undefined ? (
                <span>
                  {details.temperature}{" "}
                  <span className="small light">&deg;C</span>
                </span>
              ) : (
                <span>N/A</span>
              )}
              <span>{t("pageDeviceDetails.properties.sysStarts")}</span>
              <span>
                {details.temperature !== undefined
                  ? details.system_starts
                  : "N/A"}
              </span>
              <span>{t("pageDeviceDetails.properties.failCount")}</span>
              <span>
                {details.failure_count !== undefined
                  ? details.failure_count
                  : "N/A"}
              </span>
              {details.failure_count !== undefined && (
                <>
                  <span>{t("pageDeviceDetails.properties.failStatus")}</span>
                  <ul className="failList">
                    {getFails(details.failure_status, "diag")}
                  </ul>
                </>
              )}
            </div>
          </div>
          <div>
            <header className="marginBottomMedium">
              <div className="large">
                <span className={`icon icon-power info`}></span>
                <h2>Power Data</h2>
              </div>
            </header>
            <div className="column2 autoColumns listGap">
              <span>{t("pageDeviceDetails.properties.outPower")}</span>
              {energy.output_power !== undefined ? (
                <span>
                  {energy.output_power.toFixed(2)}{" "}
                  <span className="small light">W/VA</span>{" "}
                  {details.output_power_p &&
                    `(${details.output_power_p * 100}%)`}
                </span>
              ) : (
                <span>N/A</span>
              )}
              <span>{t("pageDeviceDetails.properties.outVolt")}</span>
              {details.output_voltage !== undefined ? (
                <span>
                  {details.output_voltage}
                  <span className="small light">V</span>
                </span>
              ) : (
                <span>N/A</span>
              )}
              <span>{t("pageDeviceDetails.properties.outCurr")}</span>
              {details.output_current !== undefined ? (
                <span>
                  {details.output_current}
                  <span className="small light">A</span> (
                  {details.output_current_p * 100}%)
                </span>
              ) : (
                <span>N/A</span>
              )}
              <span>{t("pageDeviceDetails.properties.inVolt")}</span>
              {details.input_voltage !== undefined ? (
                <span>
                  {details.input_voltage}
                  <span className="small light">V</span>
                </span>
              ) : (
                <span>N/A</span>
              )}
            </div>
          </div>
          {(type === "Luminaire" || type === "Driver") && (
            <div>
              <header className="marginBottomMedium">
                <div className="large">
                  <span className={`icon icon-light warn`}></span>
                  <h2>Lamp Data</h2>
                </div>
              </header>
              <div className="column2 autoColumns listGap">
                <span>{t("pageDeviceDetails.properties.lampTime")}</span>
                <span>
                  {toTimeBasic(details.lamp_time, "seconds", "hours")}
                </span>
                <span>{t("pageDeviceDetails.properties.lampStarts")}</span>
                <span>{details.lamp_starts}</span>
                {details.lamp_fail_count !== undefined && (
                  <>
                    <span>{t("pageDeviceDetails.properties.failCount")}</span>
                    <span>{details.lamp_fail_count}</span>
                    <span>{t("pageDeviceDetails.properties.failStatus")}</span>
                    <ul className="failList">
                      {getFails(details.lamp_fail_status, "light")}
                    </ul>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default DeviceDetailsExpanded;
