import { DateTime } from "luxon";

export const handleSetTickType = (start, end) => {
	const startDateTime = DateTime.fromISO(start);
	const endDateTime = DateTime.fromISO(end);
	const diffInMillis = endDateTime.diff(startDateTime);
	let type = "";

	const { milliseconds } = diffInMillis.values;

	if (milliseconds <= 60 * 60 * 1000) {
		type = "second";
	} else if (milliseconds <= 1 * 24 * 60 * 60 * 1000) {
		type = "minute";
	} else if (milliseconds <= 3 * 24 * 60 * 60 * 1000) {
		type = "hour";
	} else if (milliseconds <= 7 * 24 * 60 * 60 * 1000) {
		type = "day";
	} else if (milliseconds <= 31 * 24 * 60 * 60 * 1000) {
		type = "week";
	} else if (milliseconds <= 90 * 24 * 60 * 60 * 1000) {
		type = "month";
	} else if (milliseconds <= 365 * 24 * 60 * 60 * 1000) {
		type = "quarter";
	} else {
		type = "year";
	}
	return type;
};

export function getUnitComparingTimestamps(data) {
	let from = data[0].data[0].timestamp;
	let to = data[0].data[data[0].data.length - 1].timestamp;

	if (data.length > 1) {
		data.forEach(({ data }) => {
			if (data[0].timestamp < from) {
				from = data[0].timestamp;
			} else if (data[data.length - 1].timestamp > to) {
				to = data[data.length - 1].timestamp;
			}
		});
	}
	return handleSetTickType(from, to);
}
