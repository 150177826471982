import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { today } from "../../helpers/DateUtils";
import { useTranslation } from "react-i18next";

function DtDatepickerFilter(props) {
	const { t } = useTranslation();
	const appElement = document.getElementById("appWrapper");

	const [calendar, setCalendar] = useState();

	const updatedAtFilterChange = (e) => {
		props.dtRef.current.filter(e.value, props.column, "custom");
		setCalendar(e.value);
	};

	return (
		<Calendar
			value={calendar}
			placeholder={t("common.table.dateRange")}
			onChange={updatedAtFilterChange}
			dateFormat="dd M yy"
			selectionMode="range"
			readOnlyInput
			maxDate={today.toJSDate()}
			showButtonBar
			showTime={props.showTime ? true : false}
			panelClassName="columnFilter"
			appendTo={appElement}
		/>
	);
}

export default DtDatepickerFilter;
