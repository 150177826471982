//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useTranslation, Trans } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../../components/general";

//3rd Party Components
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { confirmDialog } from "primereact/confirmdialog";
import { Calendar } from "primereact/calendar";

//Atoms
import { loadingAtom } from "../../../atoms/LoadingAtom";
import { toastAtom } from "../../../atoms/ToastAtom";
import { userAtom } from "../../../atoms/UserAtom";
import { selectedAccountAtom } from "../../../atoms/AccountAtom";

//Services

//Helpers
// import { useGetData } from "../../../helpers/GeneralUtils";
import { formatDateTime, today } from "../../../helpers/DateUtils";
import { useApiRequest, useMakeRequest } from "../../../helpers/NetworkUtils";
import { GET_ALL_ACCOUNTS, VERB_ACCOUNT, GET_ALL_ACCOUNT_TYPES } from "../../../helpers/Constants";

//Other
import { nav } from "../../../config/navigation";
const appElement = document.getElementById("appWrapper");

function AccountManagement() {
	const { t } = useTranslation();
	const dt = useRef(null);
	const bttnMenu = useRef(null);
	const navigate = useNavigate();

	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const [selectedAccount, setSelectedAccount] = useRecoilState(selectedAccountAtom);
	const resetSelectedAccount = useResetRecoilState(selectedAccountAtom);
	const [accounts, setAccounts] = useState();
	const [filters, setFilters] = useState(null);
	const [accountTypeOptions, setAccountTypeOptions] = useState([]);

	const { data } = useApiRequest("get", GET_ALL_ACCOUNTS, null);
	const makeRequest = useMakeRequest();

	useEffect(() => {
		resetSelectedAccount();
		setAccounts(null);
		initFilters();
		setLoading({ visible: true, message: t("pageAccountMan.loading") });
	}, [user]);

	useEffect(() => {
		data &&
			data.map((d) => {
				d.createdAt = new Date(d.createdAt);
				d.updatedAt = new Date(d.updatedAt);
				return d;
			});
		setAccounts(data);
	}, [data]);

	useEffect(() => {
		getAccountTypes();
	}, []);

	const getAccountTypes = async () => {
		const resp = await makeRequest("get", GET_ALL_ACCOUNT_TYPES, null);
		setAccountTypeOptions(resp);
	};

	const initFilters = () => {
		setFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			"accountType.name": { value: null, matchMode: FilterMatchMode.IN },
			apiKey: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			username: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			createdAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			updatedAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const typeFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={accountTypeOptions}
				optionLabel="name"
				optionValue="name"
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				maxDate={today.toJSDate()}
				showButtonBar
				showTime={true}
				appendTo={appElement}
			/>
		);
	};

	const apiKeyRowTemplate = (rowData) => {
		return (
			rowData.apiKey && (
				<div className="withTooltip" aria-label={rowData.apiKey} onClick={(e) => copyApiKey(e)}>
					{rowData.apiKey.substr(0, 8)}...
				</div>
			)
		);
	};

	const copyApiKey = (e) => {
		navigator.clipboard.writeText(e.target.ariaLabel);
		setToast({
			...toast,
			severity: "success",
			summary: t("pageAccountMan.toast.copyApiSummary"),
			detail: t("pageAccountMan.toast.copyApiDetail"),
		});
	};

	const createdAtRowTemplate = (rowData) => {
		return formatDateTime(rowData.createdAt);
	};

	const updatedAtRowTemplate = (rowData) => {
		return formatDateTime(rowData.updatedAt);
	};

	const bttnItems = [
		{
			items: [
				{
					label: t("pageAccountMan.secondaryBttnMenu.item1"),
					icon: "pi pi-pencil",
					command: () => {
						navigate(nav.admin.account.edit);
					},
				},
				{
					label: t("pageAccountMan.secondaryBttnMenu.item2"),
					icon: "pi pi-trash",
					command: () => {
						confirmDeleteAccount();
					},
				},
			],
		},
	];

	const createAccountBttn = (
		<Button
			className="feature"
			label={t("pageAccountMan.featureBttn")}
			icon="pi pi-lock"
			onClick={() => navigate(nav.admin.account.create)}
		/>
	);
	const editAccountBttn = (
		<>
			<Button
				label={t("pageAccountMan.secondaryBttn")}
				icon="pi pi-angle-down"
				disabled={!selectedAccount}
				onClick={(event) => bttnMenu.current.toggle(event)}
				aria-label="More Actions"
				aria-controls="bttnMenu"
				aria-haspopup
			/>
			<Menu id="bttnMenu" popup className="flat" ref={bttnMenu} model={bttnItems} appendTo={appElement} />
		</>
	);

	const confirmDeleteAccount = () => {
		confirmDialog({
			header: t("pageAccountMan.delete.confirm.header"),
			message: (
				<Trans
					i18nKey="pageAccountMan.delete.confirm.message"
					values={{ account: selectedAccount.name }}
					components={{ note: <p className="light small" />, bold: <strong /> }}
				/>
			),
			acceptLabel: t("pageAccountMan.delete.confirm.accept"),
			rejectLabel: t("pageAccountMan.delete.confirm.reject"),
			acceptIcon: "pi pi-trash",
			rejectIcon: "",
			acceptClassName: "error",
			accept: () => handleDeleteAccount(),
			reject: () => console.log("Do Nothing"),
		});
	};

	const handleDeleteAccount = async () => {
		const params = {};
		params.name = selectedAccount.name;
		params.type = selectedAccount.accountType.name;
		setLoading({ visible: true, message: t("pageAccountMan.delete.loading") });

		try {
			const resp = await makeRequest("del", `${VERB_ACCOUNT}/${selectedAccount.id}`, params);

			setAccounts(resp);
			resetSelectedAccount();
			setToast({
				...toast,
				severity: "success",
				summary: t("pageAccountMan.toast.deleteSuccessSummary"),
				detail: t("pageAccountMan.toast.deleteSuccessDetail"),
			});
		} catch (err) {
			console.error("BuildingEdit onSubmit Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageAccountMan.toast.deleteErrorSummary"),
				detail: t("common.toast.errorDetail", { err: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	return (
		<>
			<PageHelmet title={t("pageAccountMan.pageHeader")} />
			<PageHeader
				title={t("pageAccountMan.pageHeader")}
				featureBttn={createAccountBttn}
				secondaryBttn={editAccountBttn}
			/>
			<main>
				<div className="mainContent">
					{accounts && (
						<div className="card">
							<DataTable
								ref={dt}
								value={accounts}
								emptyMessage={t("common.table.noData")}
								selectionMode="single"
								selection={selectedAccount}
								onSelectionChange={(e) => setSelectedAccount(e.value)}
								sortMode="multiple"
								removableSort
								filters={filters}
								filterDisplay="menu"
								autoLayout={true}
								paginator
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
								currentPageReportTemplate={t("common.table.paginatorTemplate")}
								rows={10}
								dataKey="id">
								{/* <Column field="id" header="ID" sortable filter filterMatchMode="contains" /> */}
								<Column
									field="name"
									header={t("common.table.name")}
									sortable
									filter
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="accountType.name"
									header={t("pageAccountMan.table.type")}
									sortable
									filter
									showFilterMatchModes={false}
									filterElement={typeFilter}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="apiKey"
									header={t("pageAccountMan.table.apiKey")}
									body={apiKeyRowTemplate}
									sortable
									filter
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="username"
									header={t("pageAccountMan.table.username")}
									sortable
									filter
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="createdAt"
									header={t("common.table.createdAt")}
									body={createdAtRowTemplate}
									dataType="date"
									sortable
									filter
									filterElement={dateFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="updatedAt"
									header={t("common.table.updatedAt")}
									body={updatedAtRowTemplate}
									dataType="date"
									sortable
									filter
									filterElement={dateFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
							</DataTable>
						</div>
					)}
				</div>
			</main>
		</>
	);
}

export default AccountManagement;
