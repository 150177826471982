//Node Modules
import { useState, useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import _ from "lodash";

//Pages

//BinaryForge Components
import { DatatableItem, BooleanItem } from "../datatable";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";

//Atoms
import { selectedUserAtom, usersAtom } from "../../atoms/UserAtom";

//Helpers

//Other

const UserListTable = ({ userData }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Recoil
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const [users, setUsers] = useRecoilState(usersAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	const initFilters = () => {
		setDtFilters({
			username: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			fullname: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			email: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			active: { value: null, matchMode: FilterMatchMode.IN },
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const usernameRowTemplate = (rowData) => {
		let username = rowData.username;
		rowData.status === "FORCE_CHANGE_PASSWORD" &&
			(username = <DatatableItem value={rowData.username} type="text" wrapperStyle="error" />);
		return username;
	};

	const emailRowTemplate = (rowData) => {
		let email = rowData.email;
		rowData.email_verified === "false" &&
			(email = <DatatableItem value={rowData.email} type="text" wrapperStyle="error" />);
		return email;
	};

	const activeRowTemplate = (rowData) => {
		return (
			<DatatableItem
				type="full"
				value={rowData.active ? t("common.boolean.active") : t("common.boolean.inactive")}
				icon={rowData.active ? "pi-check-circle" : "pi-times-circle"}
				wrapperStyle={rowData.active ? "success" : "error"}
			/>
		);
	};

	const activeFilterItemTemplate = (option) => {
		return <BooleanItem value={option} type="full" options="active-inactive" />;
	};

	const activeFilterSelectedTemplate = (option) => {
		if (option !== undefined) {
			return <BooleanItem value={option} type="full" options="active-inactive" />;
		}
	};

	const activeFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={[true, false]}
				itemTemplate={activeFilterItemTemplate}
				selectedItemTemplate={activeFilterSelectedTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	useEffect(() => {
		if (userData) {
			setUsers(userData);
			initFilters();
		}
	}, [userData]);

	return (
		<DataTable
			ref={dt}
			value={users}
			emptyMessage={t("common.table.noData")}
			selectionMode="single"
			selection={selectedUser}
			onSelectionChange={(e) => setSelectedUser(e.value)}
			sortMode="multiple"
			removableSort
			filters={dtFilters}
			filterDisplay="menu"
			paginator
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
			currentPageReportTemplate={t("common.table.paginatorTemplate")}
			rows={10}
			dataKey="username">
			<Column
				field="username"
				header={t("user.management.table.username")}
				body={usernameRowTemplate}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="fullname"
				header={t("user.management.table.fullname")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="email"
				header={t("user.management.table.email")}
				body={emailRowTemplate}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="active"
				header={t("user.management.table.active")}
				body={activeRowTemplate}
				sortable
				filter
				showFilterMatchModes={false}
				filterElement={activeFilterTemplate}
				filterApply={filterApplyTemplate}
			/>
		</DataTable>
	);
};

export default UserListTable;
