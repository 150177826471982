//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Services

//Helpers
import { formatDateTime, toTimeBasic } from "../../helpers/DateUtils";

//Other
const GTIN_URL_PREFIX = "https://www.dali-alliance.org/products?Default_submitted=1&brand_id=&part_number=&product_name=&family_products%5B%5D=&product_id=&gtin=";
const GTIN_URL_SUFFIX = "&Default-submit=Search";

function DeviceDetailsCollapsed({ details }) {
	const { t } = useTranslation();

	return (
		<div className="column2 autoColumns listGap">
			{details.model && (
				<>
					<span>{t("pageDeviceDetails.properties.model")}</span>
					<span>{formatDateTime(details.model)}</span>
				</>
			)}
			<span>{t("pageDeviceDetails.properties.GTIN")}</span>
			<span>
				<a href={`${GTIN_URL_PREFIX}${details.GTIN}${GTIN_URL_SUFFIX}`} target="_blank" rel="noreferrer">
					{details.GTIN}
				</a>
			</span>
			<span>{t("pageDeviceDetails.properties.serial")}</span>
			<span>{details.serial}</span>
			<span>{t("pageDeviceDetails.properties.type")}</span>
			<span>{details.device_type}</span>
			<span>{t("pageDeviceDetails.properties.opTime")}</span>
			<span>{toTimeBasic(details.operating_time, "seconds", "days")}</span>
			{details.manufacture_date && (
				<>
					<span>{t("pageDeviceDetails.properties.manDate")}:</span>
					<span>{formatDateTime(details.manufacture_date)}</span>
				</>
			)}
		</div>
	);
}

export default DeviceDetailsCollapsed;
