import React from "react";
import { useTranslation } from "react-i18next";

function InputName(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, disabled } = props;

	return (
		<div className="formField">
			<label htmlFor="name">{t("pageBuildingEdit.form.label.name")}</label>
			<input type="text" defaultValue={defaultValue} disabled={disabled} className={errors.name ? "error" : ""} {...register("name", { required: true, maxLength: 100 })} />
			<ul className="errors">{errors?.name?.types?.required && <li className="error">{t("pageBuildingEdit.form.error.name.required")}</li>}</ul>
			<ul className="errors">{errors?.name?.types?.maxLength && <li className="error">{t("pageBuildingEdit.form.error.name.max")}</li>}</ul>
		</div>
	);
}

export default InputName;
