//Node Modules
import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForgeComponenets

//3rd Party Components
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import {
	reportFilterAtom,
	reportDataAtom,
	reportSeriesIdAtom,
	reportSeriesSelectorFamily,
	customReportAddButtonStatus,
} from "../../atoms/ReportAtom";

//Helpers
import { today, timeRangeOptions, timeRangeIntervals, formatDateByInterval } from "../../helpers/DateUtils";
import { intervalOptions } from "../../helpers/chart/reportFilterOptions";
import { useAddSeries } from "../../helpers/chart/hooks";

//Other

function ReportFilter() {
	const { t } = useTranslation();
	const setLoading = useSetRecoilState(loadingAtom);
	const seriesId = useRecoilValue(reportSeriesIdAtom);
	const series = useRecoilValue(reportSeriesSelectorFamily(seriesId));
	const [reportFilter, setReportFilter] = useRecoilState(reportFilterAtom);
	const [addButtonStatus, setAddButtonStatus] = useRecoilState(customReportAddButtonStatus);
	const setReportData = useSetRecoilState(reportDataAtom);
	const [selectedTimeRange, setSelectedTimeRange] = useState(2);
	const [dataSeries, setDataSeries] = useState(null);
	const onAddSeries = useAddSeries();

	//sync with the state of the app
	useEffect(() => {
		setDataSeries(series);
	}, [series, selectedTimeRange]);

	//Range
	const handleSelectedTimeRange = (value) => {
		setSelectedTimeRange(value);
		setReportFilter({
			...reportFilter,
			dateRange: value !== 0 && formatDateByInterval(timeRangeIntervals[value]),
		});
	};

	//Custom Range
	const handleCalendarUpdate = (value) => {
		setReportFilter({ ...reportFilter, dateRange: value });
	};

	//Interval and Custom Interval
	const handleSelection = (selection) => {
		setReportFilter({
			...reportFilter,
			[selection.name]: selection.value,
		});
	};

	//Calendar check on hide function. If user doesn't select from / to date range, disable ADD button on data series card, otherwise TO will be null and will break the call
	const handleValidation = () => {
		const { dateRange } = reportFilter;
		const isAnyTimeStampNull = dateRange.some((timeStamp) => timeStamp === null);
		if (isAnyTimeStampNull) {
			setAddButtonStatus(true);
		} else {
			setAddButtonStatus(false);
		}
	};

	const fetchReportData = async () => {
		try {
			setLoading({ visible: true, message: "Loading Data" });
			const seriesData = dataSeries.map(async (s, i) => {
				return await onAddSeries(seriesId[i].id, s);
			});
			const chartData = await Promise.all(seriesData);
			setReportData(chartData);
		} catch (e) {
			console.error(e);
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	return (
		<>
			<header className="grid column2 autoColumns jSpaceBetween aItemsCenter marginBottomMedium">
				<h2>Filter Range</h2>
				<Button
					id="refreshFilter"
					className="feature"
					label="Filter"
					icon="pi pi-filter"
					onClick={fetchReportData}
					disabled={!seriesId.length || addButtonStatus}
				/>
			</header>
			<div className="formFieldsWrapper">
				<div className="orWrapper gapMedium">
					<div className="formField">
						<label htmlFor="timeRange">Range</label>
						<Dropdown
							name="timeRange"
							value={selectedTimeRange}
							options={timeRangeOptions}
							onChange={(e) => handleSelectedTimeRange(e.target.value)}
							placeholder="Select a Type"
						/>
					</div>
					<div className="or">
						<span>OR</span>
					</div>
					<div className="formField">
						<label htmlFor="calRange">Custom Range</label>
						<Calendar
							id="calRange"
							value={reportFilter.dateRange}
							selectionMode="range"
							readOnlyInput
							maxDate={today.toJSDate()}
							dateFormat="d M yy"
							showTime
							onChange={(e) => handleCalendarUpdate(e.value)}
							onHide={handleValidation}
							disabled={selectedTimeRange !== 0}
						/>
						{addButtonStatus && <span className="small error">{t("pageReport.dateRange.error")}</span>}
					</div>
				</div>

				<div className="orWrapper gapMedium">
					<div className="formField">
						<label htmlFor="interval">Interval</label>
						<Dropdown
							name="interval"
							value={reportFilter.interval}
							options={intervalOptions}
							onChange={(e) => handleSelection(e.target)}
							placeholder="Select an Interval"
						/>
					</div>
					<div className="or">
						<span>OR</span>
					</div>
					<div className="formField">
						<label htmlFor="intervalCustom">Custom Interval</label>
						<InputNumber
							id="intervalCustom"
							name="intervalCustom"
							value={reportFilter.intervalCustom}
							onValueChange={(e) => handleSelection(e.target)}
							showButtons
							suffix=" minutes"
							min={1}
							disabled={reportFilter.interval !== 0}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default ReportFilter;
