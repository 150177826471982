//Node Modules
import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

//Pages

//BinaryForge Components
import ReportSeriesItemAdd from "./ReportSeriesItemAdd";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { reportSeriesAtomFamily } from "../../atoms/ReportAtom";
import { dialogAtomFamily } from "../../atoms/DialogAtom";

//Services

//Helpers
import { reportTypeIcon } from "../../helpers/chart/reportFilterOptions";

//Other

function ReportSeriesItem({ id, removeSeries, showSeries }) {
	//Recoil
	const series = useRecoilValue(reportSeriesAtomFamily(id));
	const resetSeries = useResetRecoilState(reportSeriesAtomFamily(id));
	const setShow = useSetRecoilState(dialogAtomFamily(id));

	//Re-render component after setShow is set to true
	useEffect(() => {
		setShow(true);
	}, [setShow]);

	const handleDelete = () => {
		resetSeries();
		removeSeries(id);
	};

	return (
		<>
			{showSeries && (
				<div className="reportSeriesWrapper aItemsCenter">
					<i className={`icon ${reportTypeIcon[series.reportType]} large marginRightSmall`} />
					<p>{series.seriesName}</p>
					<div className="flex gapXSmall">
						<Button
							icon="pi pi-pencil"
							className="round noBorder large withBackground"
							onClick={() => setShow(true)}></Button>
						<Button
							icon="pi pi-trash"
							className="round noBorder large withBackground error"
							onClick={handleDelete}></Button>
					</div>
				</div>
			)}
			<ReportSeriesItemAdd id={id} />
		</>
	);
}

export default ReportSeriesItem;
