import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const selectedBuildingAtom = atom({
	key: "selectedBuildingAtom",
	default: {
		name: null,
		latitude: 0,
		longitude: 0,
		overdueFunctional: 30,
		overdueDuration: 365,
	},
	effects_UNSTABLE: [persistAtom],
});
