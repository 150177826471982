import React from "react";
import FusionDialog from "../dialog/FusionDialog";
import MessageIcon from "./MessageIcon";

/**
 * @param {Array}
 * [{type: "warn" / "error", message: t(message.code)}]
 */
const MultipleWarningMessages = ({ messages }) => {
	return (
		<FusionDialog header="Warning" id={"reportWarning"}>
			{messages &&
				messages.map((dialog, index) => (
					<div key={`${dialog.type} - ${index}`} style={{ marginTop: 10, marginBottom: 10 }}>
						<MessageIcon icon={`pi-exclamation-circle ${dialog.type}`} messageKey={dialog.message} />
					</div>
				))}
		</FusionDialog>
	);
};

export default MultipleWarningMessages;
