import React from "react";
import { useTranslation } from "react-i18next";

function InputOverdueDur(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, disabled } = props;

	return (
		<div className="formField">
			<label htmlFor="overdueDuration">{t("pageBuildingEdit.form.label.overdueDur")}</label>
			<input
				type="number"
				defaultValue={defaultValue}
				disabled={disabled}
				className={errors.overdueDuration ? "error" : ""}
				{...register("overdueDuration", { required: true, min: 1, max: 365 })}
			/>
			<ul className="errors">
				{errors?.overdueDuration?.types?.required && <li className="error">{t("pageBuildingEdit.form.error.overdueDur.required")}</li>}
				{errors?.overdueDuration?.types?.min && <li className="error">{t("pageBuildingEdit.form.error.overdueDur.min")}</li>}
				{errors?.overdueDuration?.types?.max && <li className="error">{t("pageBuildingEdit.form.error.overdueDur.max")}</li>}
			</ul>
		</div>
	);
}

export default InputOverdueDur;
