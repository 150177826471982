//Node Modules
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import ProejctSwitchDialog from "../dialog/ProjectSwitchDialog";
import ProjectSelector from "../ProjectSelector";

//3rd Party Components
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

//Atoms
import { userAtom } from "../../atoms/UserAtom";
import { modulesAtom } from "../../atoms/ProjectAtom";
import { projectSwitchDialogAtom } from "../../atoms/DialogAtom";

//Service
import { useLogout } from "../../services/BaseServices";

//Other
import { nav } from "../../config/navigation";
const appElement = document.getElementById("appWrapper");

function AppNav() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userMenu = useRef(null);
	const navMenu = useRef(null);

	const user = useRecoilValue(userAtom);
	const modules = useRecoilValue(modulesAtom);
	const setShow = useSetRecoilState(projectSwitchDialogAtom);
	const handleLogout = useLogout();

	const getNavLabel = () => {
		const username = user.attr["name"] ? user.attr["name"] : user.username;
		// const project = user.availableProjects.length > 1 && user.selectedProject;
		const project = user.selectedProject.replace(/_/g, " ");
		return (
			<p>
				{username}
				<br />
				{project}
			</p>
		);
	};

	const userItems = [
		{
			label: getNavLabel(),
			items: [
				{
					label: t("nav.user.profile"),
					icon: "pi pi-user-edit",
					command: () => {
						navigate(nav.admin.profile);
					},
				},
				user.availableProjects.length > 1 && {
					label: t("nav.user.switchProj"),
					icon: "pi pi-globe",
					command: () => {
						setShow(true);
					},
				},
				{
					label: t("nav.user.logout"),
					icon: "pi pi-sign-out",
					command: () => handleLogout(),
				},
			],
		},
	];

	const buildNavItems = () => {
		const navLinks = [];

		modules.emergency &&
			navLinks.push({
				label: t("nav.app.emergency.header"),
				items: [
					{
						label: t("nav.app.emergency.summary"),
						icon: "pi pi-check-circle",
						command: () => {
							navigate(nav.emergency.summary);
						},
					},
					{
						label: t("nav.app.emergency.duration"),
						icon: "pi pi-clock",
						command: () => {
							navigate(nav.emergency.duration);
						},
					},
				],
			});

		modules.casambi &&
			navLinks.push({
				label: t("nav.app.device.header"),
				items: [
					{
						label: t("nav.app.device.networks"),
						icon: "pi pi-sitemap",
						command: () => {
							navigate(nav.device.networks);
						},
					},
					{
						label: t("nav.app.device.units"),
						icon: "icon-light",
						command: () => {
							navigate(nav.device.units);
						},
					},
				],
			});

		modules.mapping &&
			navLinks.push({
				label: "Mapping",
				items: [
					{
						label: "Indoor",
						icon: "pi pi-map-marker",
						command: () => {
							navigate(nav.device.mapping.indoor);
						},
					},
					{
						label: "Outdoor",
						icon: "pi pi-map",
						command: () => {
							navigate(nav.device.mapping.outdoor);
						},
					},
				],
			});

		modules.report &&
			navLinks.push({
				label: t("nav.app.reports.header"),
				items: [
					{
						label: t("nav.app.reports.preset"),
						icon: "pi pi-chart-bar",
						command: () => {
							navigate(nav.report.preset);
						},
					},
					{
						label: t("nav.app.reports.custom"),
						icon: "pi pi-chart-line",
						command: () => {
							navigate(nav.report.custom);
						},
					},
				],
			});

		navLinks.push({
			label: t("nav.app.admin.header"),
			items: [
				{
					label: t("nav.app.admin.building"),
					icon: "pi pi-home",
					command: () => {
						navigate(nav.admin.building.buildingManagement);
					},
				},
				modules.casambi && {
					label: t("nav.app.admin.account"),
					icon: "pi pi-lock",
					command: () => {
						navigate(nav.admin.account.management);
					},
				},
				// {
				// 	label: t("nav.app.admin.user"),
				// 	icon: "pi pi-users",
				// 	command: () => {
				// 		navigate(nav.admin.user.management);
				// 	},
				// },
			],
		});

		return navLinks;
	};

	const navItems = buildNavItems();

	return (
		<>
			<nav className="appNav">
				<Button
					icon="pi pi-bars"
					className="p-button-rounded"
					onClick={(event) => navMenu.current.toggle(event)}
					aria-label="App Navigation"
					aria-controls="navMenu"
					aria-haspopup
				/>
				<Menu id="navMenu" popup ref={navMenu} model={navItems} appendTo={appElement} />

				<Button
					icon="pi pi-user"
					className="p-button-rounded"
					onClick={(event) => userMenu.current.toggle(event)}
					arai-label="User Navigation"
					aria-controls="userMenu"
					aria-haspopup
				/>
				<Menu id="userMenu" popup ref={userMenu} model={userItems} appendTo={appElement} />
			</nav>

			<ProejctSwitchDialog header={t("nav.user.switchProj")}>
				<ProjectSelector />
			</ProejctSwitchDialog>
		</>
	);
}

export default AppNav;
