import { API } from "aws-amplify";
import { atom, selector, selectorFamily } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();
const apiName = "pushfusionMooreAPI";

let isLoggedIn = false;
let attr = null;

export const userAtom = atom({
	key: "userAtom",
	default: {
		isLoggedIn: isLoggedIn,
		attr: attr,
	},
	effects_UNSTABLE: [persistAtom],
});

export const usersAtom = atom({
	key: "usersAtom",
	default: null,
});

export const usersSelector = selector({
	key: "usersSelector",
	get: async ({ get }) => {
		const currentUser = get(userAtom);
		const users = await API.get(apiName, "/admin/user", {
			headers: {
				"X-BinaryForge-Project": currentUser.selectedProject,
			},
		});
		return users;
	},
	set: ({ set }, newValue) => {
		set(usersAtom, newValue);
	},
});

export const selectedUserAtom = atom({
	key: "selectedUserAtom",
	default: null,
});

export const selectedUserSelector = selector({
	key: "selectedUserSelector",
	get: async ({ get }) => {
		let user = null;
		const selectedUser = get(selectedUserAtom);
		console.log("SelectedUser ::", selectedUser);
		if (selectedUser) {
			//TODO: selectedUser does not contain the selectedProject
			user = await API.get(apiName, `/admin/user/${selectedUser.username}`, {
				headers: {
					"X-BinaryForge-Project": selectedUser.selectedProject,
				},
			});
		}
		return user;
	},
});
