import { formatDateTime, formatDateTimeExcel } from "./DateUtils";
import { useRecoilState, useRecoilValue } from "recoil";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import _ from "lodash";
import { exportPdf, exportCsv, exportExcel, exportMultiPdf, exportMultiExcel } from "../helpers/datatable/Export";

//atoms
import {
	reportTableFiltersAtom,
	reportTableFilteredDataAtom,
	reportTableFormattedDataAtom,
	reportTableColumnsAtom,
} from "../atoms/ReportAtom";
import { toastAtom } from "../atoms/ToastAtom";
import { userAtom } from "../atoms/UserAtom";

export const buildFiltersNew = (filters, pageKey, valueKey) => {
	const filtersArray = [];
	console.log("Filters ::", filters);
	Object.entries(filters).forEach(([key, value]) => {
		let filterValue = "";
		if (value.constraints) {
			value.constraints[0].value != null &&
				value.constraints.map((v) => {
					filtersArray.push(
						`\u2022 ${i18n.t(`${pageKey}.table.${key.split(".").pop()}`)}: (${i18n.t(
							`common.filterType.${v.matchMode}`
						)}) ${v.value instanceof Date ? formatDateTime(v.value) : v.value}`
					);
					return null;
				});
		} else {
			if (value.value !== null) {
				if (Array.isArray(value.value) && value.value[0] instanceof Date) {
					filterValue = value.value
						.map((v) => {
							return formatDateTime(v.toISOString());
						})
						.join(" - ");
				} else if (Array.isArray(value.value)) {
					filterValue = value.value
						.map((v) => {
							return i18n.t(`${valueKey}.${v}`);
						})
						.join(", ");
				} else {
					filterValue = value.value.toString();
				}
				filtersArray.push(`\u2022 ${i18n.t(`${pageKey}.table.${key.split(".").pop()}`)}: ${filterValue}`);
			}
		}
	});

	if (filtersArray.length === 0) {
		filtersArray.push("\u2022 None Applied");
	}

	return filtersArray;
};

export const buildFilters = (filters, pageKey, valueKey) => {
	console.log(filters);
	const filtersArray = [];
	if (filters && Object.keys(filters).length > 0) {
		Object.entries(filters).forEach(([key, value]) => {
			let filterValue = value.value.toString();
			if (Array.isArray(value.value) && value.value[0] instanceof Date) {
				filterValue = value.value
					.map((v) => {
						return formatDateTime(v.toISOString());
					})
					.join(" - ");
			} else if (Array.isArray(value.value)) {
				filterValue = value.value
					.map((v) => {
						return i18n.t(`${valueKey}.${v}`);
					})
					.join(", ");
			}
			filtersArray.push(`\u2022 ${i18n.t(`${pageKey}.table.${key.split(".").pop()}`)}: ${filterValue}`);
		});
	} else {
		filtersArray.push("\u2022 None Applied");
	}

	return filtersArray;
};

export const buildReportFilters = (filters) => {
	console.log(filters);
	const filtersArray = [];
	Object.entries(filters).forEach(([key, value]) => {
		const formattedKey = key === "timestamp" ? i18n.t("pageReport.table.timestamp") : key;
		let filterValue;

		console.log("Value", value);

		if (value.constraints) {
			value.constraints[0].value != null &&
				value.constraints.map((v) => {
					filtersArray.push(
						`\u2022 ${formattedKey}: (${i18n.t(`common.filterType.${v.matchMode}`)}) ${
							v.value instanceof Date ? formatDateTime(v.value) : v.value
						}`
					);
					return null;
				});
		} else {
			if (value.value !== null) {
				if (Array.isArray(value.value) && value.value[0] instanceof Date) {
					filterValue = value.value
						.map((v) => {
							return formatDateTime(v.toISOString());
						})
						.join(" - ");
				} else if (Array.isArray(value.value)) {
					filterValue = value.value
						.map((v) => {
							return v;
						})
						.join(", ");
				} else {
					filterValue = value.value.toString();
				}
				filtersArray.push(`\u2022 ${formattedKey}: ${filterValue}`);
			}
		}
	});

	return filtersArray;
};

const getExportColumnsFormatted = (columns, type) => {
	//Format columns for export
	const formattedColumns = ["timestamp", ...columns.map((column) => column.name)];
	//Excel needs columns' width
	let columnFormats;
	if (type === "excel") {
		columnFormats = [{ width: 20, numFmt: "dd mmm yyyy, hh:mm" }, ...columns.map(() => ({ width: 20 }))];
	}
	return { formattedColumns, columnFormats };
};

//Dynamically format columns for export data
const getReportExportData = (data, formattedColumns, type) => {
	const exportData = data.map((row) => {
		const obj = Object.assign({}, row);
		//format timestamp
		obj["timestamp"] = type !== "excel" ? formatDateTime(obj["timestamp"]) : formatDateTimeExcel(obj["timestamp"]);
		//populate empty values and keep the order
		const populatedArray = formattedColumns.map((key) => (obj[key] ? obj[key] : "--"));
		// return object values from the columns
		return Object.values(populatedArray);
	});

	return exportData;
};

export const useHandleExport = () => {
	//Table state
	const tableFormattedData = useRecoilValue(reportTableFormattedDataAtom);
	const filters = useRecoilValue(reportTableFiltersAtom);
	const filteredData = useRecoilValue(reportTableFilteredDataAtom);
	const user = useRecoilValue(userAtom);
	const columns = useRecoilValue(reportTableColumnsAtom);

	const handleFileExport = (type, tableName) => {
		try {
			//transform columns
			const { formattedColumns, columnFormats } = getExportColumnsFormatted(columns, type);
			//transform data
			const dataToTransform = filteredData ? filteredData : tableFormattedData;
			const exportData = getReportExportData(dataToTransform, formattedColumns, type);
			//transform filters
			const transformedFilters = filters ? buildReportFilters(filters) : ["\u2022 None Applied"];
			//Translate timestamp
			const translatedColumns = formattedColumns.map((column) =>
				column === "timestamp" ? i18n.t("pageReport.table.timestamp") : column
			);

			//Generate file
			if (type === "pdf") {
				exportPdf(
					translatedColumns,
					exportData,
					transformedFilters,
					`pushfusion - ${tableName} - ${user.selectedProject}`,
					`${tableName}`,
					user.selectedProject
				);
			} else if (type === "csv") {
				exportCsv(translatedColumns, exportData, `pushfusion - ${tableName} - ${user.selectedProject}`);
			} else if (type === "excel") {
				exportExcel(
					translatedColumns,
					exportData,
					transformedFilters,
					columnFormats,
					`pushfusion - ${tableName} - ${user.selectedProject}`,
					`${tableName}`,
					user.selectedProject
				);
			}
		} catch (error) {
			console.error("Error exporting file", error);
		}
	};
	return handleFileExport;
};

const isTimestamp = (value, raw) => {
	if (raw) {
		// return DateTime.fromISO(value).isValid;
		return value instanceof Date ? true : false;
	} else {
		return DateTime.fromFormat(value.toString(), "dd MMM yyyy, hh:mm").invalid ? false : true;
	}
};

const getColumnFormats = (data) => {
	const columnFormats = [];
	Object.values(data).forEach((c) => {
		if (isTimestamp(c, false)) {
			columnFormats.push({ width: 20, numFmt: "dd mmm yyyy, hh:mm" });
		} else {
			columnFormats.push({ width: 20 });
		}
	});
	return columnFormats;
};

export const useHandleGenericExport = () => {
	const { t } = useTranslation();
	const [toast, setToast] = useRecoilState(toastAtom);
	const user = useRecoilValue(userAtom);

	const handleFileExport = (type, tableName, data, resourceKey) => {
		try {
			const values = data.baseData.map((b) => {
				return data.fields.map((p) => {
					let cellValue = _.get(b, p);
					if (isTimestamp(cellValue, true)) cellValue = formatDateTime(cellValue);
					if (i18n.exists(`${resourceKey}.${cellValue}`)) cellValue = t(`${resourceKey}.${cellValue}`);
					return cellValue;
				});
			});

			//Generate file
			if (type === "pdf") {
				exportPdf(
					data.columns,
					values,
					data.filters,
					`pushfusion - ${tableName} - ${user.selectedProject}`,
					`${tableName}`,
					user.selectedProject
				);
			} else if (type === "csv") {
				exportCsv(data.columns, values, `pushfusion - ${tableName} - ${user.selectedProject}`);
			} else if (type === "excel") {
				exportExcel(
					data.columns,
					values,
					data.filters,
					getColumnFormats(values[0]),
					`pushfusion - ${tableName} - ${user.selectedProject}`,
					`${tableName}`,
					user.selectedProject
				);
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("common.toast.exportSuccessSummary"),
				detail: t("common.toast.exportSuccessDetail"),
			});
		} catch (error) {
			console.error("Error exporting file", error);

			setToast({
				...toast,
				severity: "error",
				summary: t("common.toast.exportErrorSummary"),
				detail: t("common.toast.exportErrorDetail"),
			});
		}
	};
	return handleFileExport;
};

export const useHandleGenericMultiExport = () => {
	const { t } = useTranslation();
	const [toast, setToast] = useRecoilState(toastAtom);
	const user = useRecoilValue(userAtom);

	const handleFileMultiExport = (type, tableName, data, resourceKey) => {
		try {
			const values = [];
			data.baseData.map((d) => {
				if (d) {
					values.push(
						d.map((b) => {
							return data.fields.map((p) => {
								let cellValue = "";
								if (p === "testBookSubEntry") {
									if (b.testBookSubEntry.length > 0)
										cellValue = b.testBookSubEntry
											.map((s, i) => {
												return `${i > 0 ? "\r\n---\r\n" : ""}${s.path}\r\n${s.result}\r\n${
													s.detailResult
												}`;
											})
											.join();
								} else {
									cellValue = _.get(b, p);
									if (isTimestamp(cellValue, true)) cellValue = formatDateTime(cellValue);
									if (i18n.exists(`${resourceKey}.${cellValue}`))
										cellValue = t(`${resourceKey}.${cellValue}`);
								}
								return cellValue;
							});
						})
					);

					return null;
				} else {
					values.push([]);
				}

				return null;
			});

			// Generate file
			if (type === "pdf") {
				exportMultiPdf(
					data.columns,
					values,
					data.pageTitles,
					data.filters,
					`pushfusion - ${tableName} - ${user.selectedProject}`,
					`${tableName}`,
					user.selectedProject
				);
			} else if (type === "csv") {
				values.map((v, idx) => {
					v.map((i) => {
						i.unshift(data.pageTitles[idx][1]);
						return null;
					});

					return null;
				});
				const formattedValues = values.flat();
				data.columns.unshift("Scenecom");
				exportCsv(data.columns, formattedValues, `pushfusion - ${tableName} - ${user.selectedProject}`);
			} else if (type === "excel") {
				exportMultiExcel(
					data.columns,
					values,
					data.pageTitles,
					data.filters,
					getColumnFormats(values[0]),
					`pushfusion - ${tableName} - ${user.selectedProject}`,
					`${tableName}`,
					user.selectedProject
				);
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("common.toast.exportSuccessSummary"),
				detail: t("common.toast.exportSuccessDetail"),
			});
		} catch (error) {
			console.error("Error exporting file", error);

			setToast({
				...toast,
				severity: "error",
				summary: t("common.toast.exportErrorSummary"),
				detail: t("common.toast.exportErrorDetail"),
			});
		}
	};
	return handleFileMultiExport;
};
