import React from "react";
import { useTranslation } from "react-i18next";

function InputName(props) {
	const { t } = useTranslation();
	const { register, label, value } = props;

	return (
		<div className="formField">
			<label htmlFor={label}>{t(`pageAccountCreate.form.label.${label}`)}</label>
			<input type="text" value={value} readOnly={true} {...register(label)} />
		</div>
	);
}

export default InputName;
