//Node Modules
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader, MessageIcon } from "../../../components/general";
import { UserList } from "../../../components/user";

//3rd Party Components
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

//Atoms
import { selectedUserAtom, usersSelector, userAtom } from "../../../atoms/UserAtom";
import { useNavigate } from "react-router";

//Helpers

//Other
import { nav } from "../../../config/navigation";
import { useConfirmUserAction } from "../../../helpers/user";

const User = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const moreBttnMenu = useRef(null);
	const appElement = document.getElementById("appWrapper");
	const { confirmDeleteUser, confirmResetPassword, confirmResendInvite } = useConfirmUserAction();

	//Recoil
	const refreshUserCache = useRecoilRefresher_UNSTABLE(usersSelector);
	const selectedUser = useRecoilValue(selectedUserAtom);
	const loggedInUser = useRecoilValue(userAtom);

	useEffect(() => {
		refreshUserCache();
	}, []);

	const featureBttn = (
		<Button
			className="feature"
			label={t("user.management.featureBttn")}
			icon="pi pi-user"
			onClick={() => navigate(`${nav.admin.user.details}/${selectedUser?.username}`)}
			disabled={!selectedUser}
		/>
	);

	const moreBttnItems = [
		{
			label: t("user.management.actions.create"),
			icon: "pi pi-user-plus",
			command: () => navigate(nav.admin.user.create),
		},
		{
			label: t("user.management.actions.delete"),
			icon: "pi pi-user-minus",
			command: () => confirmDeleteUser(),
			disabled: !selectedUser || selectedUser.username === loggedInUser.username,
		},
		{
			label: t("user.management.actions.password"),
			icon: "pi pi-lock",
			command: () => confirmResetPassword(),
			disabled: !selectedUser || selectedUser.username === loggedInUser.username,
		},
		{
			label: t("user.management.actions.invite"),
			icon: "pi pi-envelope",
			command: () => confirmResendInvite(),
			disabled:
				!selectedUser ||
				selectedUser.username === loggedInUser.username ||
				selectedUser.status !== "FORCE_CHANGE_PASSWORD",
		},
	];

	const moreBttn = (
		<>
			<Button
				label={t("user.management.secondaryBttn")}
				icon="pi pi-angle-down"
				onClick={(event) => moreBttnMenu.current.toggle(event)}
				aria-label="More Actions"
				aria-controls="bttnMenu"
				aria-haspopup
			/>
			<Menu id="bttnMenu" popup ref={moreBttnMenu} model={moreBttnItems} appendTo={appElement} />
		</>
	);

	return (
		<>
			<PageHeader title={t("user.management.pageTitle")} featureBttn={featureBttn} secondaryBttn={moreBttn} />
			<main>
				<div className="mainContent">
					<section className="card">
						<UserList />
					</section>
				</div>
			</main>

			{/* Dialogs */}
			{/* <UserCreateDialog />
			<UserEditDialog /> */}
		</>
	);
};

export default User;
