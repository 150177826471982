import i18n from "../../i18n";

const BooleanItem = ({ value, type, options }) => {
	let response = null;

	switch (options) {
		case "yes-no":
			response = value ? i18n.t("common.boolean.yes") : i18n.t("common.boolean.no");
			break;
		case "true-false":
			response = value ? i18n.t("common.boolean.true") : i18n.t("common.boolean.false");
			break;
		case "active-inactive":
			response = value ? i18n.t("common.boolean.active") : i18n.t("common.boolean.inactive");
			break;
		default:
			response = value ? i18n.t("common.boolean.true") : i18n.t("common.boolean.false");
			break;
	}

	return (
		<div className={`flex gapXSmall ${value ? "font-success" : "error"}`}>
			{type !== "text" && <i className={`pi ${value ? "pi-check-circle" : "pi-times-circle"}`} />}
			{type !== "icon" && <span>{response}</span>}
		</div>
	);
};

export default BooleanItem;
