import React from "react";
import { useTranslation } from "react-i18next";

function InputApiKey(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, disabled } = props;

	return (
		<div className="formField">
			<label htmlFor="apiKey">{t("pageAccountCreate.form.label.apiKey")}</label>
			<input type="text" defaultValue={defaultValue} disabled={disabled} className={errors.name ? "error" : ""} {...register("apiKey", { required: true })} />
			<ul className="errors">{errors?.apiKey?.types?.required && <li className="error">{t("pageAccountCreate.form.error.apiKey.required")}</li>}</ul>
		</div>
	);
}

export default InputApiKey;
