import { useTranslation } from "react-i18next";
import { DatatableItem, HighlightItem } from "../datatable";
import { formatDateTime } from "../../helpers/DateUtils";

const UserDetails = ({ userData }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="grid column3 gapColMedium">
				<div className="grid columnLabel gapColMedium gapRowXSmall aItemsCenter aContentStart">
					<span>{t("user.details.form.fullname")}:</span>
					<span>{userData.fullname}</span>
					<span>{t("user.details.form.email")}:</span>
					<span>{userData.email}</span>
				</div>
				<div className="grid columnLabel gapColMedium gapRowXSmall aItemsCenter aContentStart">
					<span>{t("user.details.form.active")}:</span>
					<span>
						<DatatableItem
							type="full"
							value={userData.active ? t("common.boolean.active") : t("common.boolean.inactive")}
							icon={userData.active ? "pi-check-circle" : "pi-times-circle"}
							wrapperStyle={userData.active ? "success" : "error"}
						/>
					</span>
					<span>{t("user.details.form.status")}:</span>
					<span>
						<DatatableItem
							type="text"
							value={t(`user.status.${userData.status}`)}
							// icon={userData.status ? "pi-lock" : "pi-lock-open"}
							wrapperStyle={userData.status === "CONFIRMED" ? "success" : "error"}
						/>
					</span>
					<span>{t("user.details.form.mfa")}:</span>
					<span>
						<DatatableItem
							type="full"
							value={
								userData.mfa
									? t(`pageProfile.mfa.type.${userData.mfa}`)
									: t(`pageProfile.mfa.type.NONE`)
							}
							icon={userData.mfa ? "pi-lock" : "pi-lock-open"}
							wrapperStyle={userData.mfa ? "success" : "error"}
						/>
					</span>
				</div>
				<div className="grid columnLabel gapColMedium gapRowXSmall aItemsCenter aContentStart">
					<span>{t("common.table.createdAt")}:</span>
					<span>{formatDateTime(userData.created)}</span>
					<span>{t("common.table.updatedAt")}:</span>
					<span>{formatDateTime(userData.updated)}</span>
				</div>
			</div>
			{userData.authEvents && (
				<div className="grid column2 autoColumns jContentStart gapLarge marginTopLarge">
					<div className="grid columnLabel gapColMedium gapRowSmall aItemsCenter aContentStart">
						<span>{t("user.login.last")}:</span>
						<span>
							<HighlightItem
								type="full"
								value={formatDateTime(userData.authEvents.lastLogin.date)}
								icon={
									userData.authEvents.lastLogin.response === "Pass"
										? "pi-check-circle"
										: "pi-times-circle"
								}
								wrapperStyle={userData.authEvents.lastLogin.response === "Pass" ? "success" : "error"}
							/>
						</span>
					</div>
					<div className="grid columnLabel gapColMedium gapRowSmall aItemsCenter aContentStart">
						<span>{t("user.login.lastSuccess")}:</span>
						<span>
							{userData.authEvents.lastSuccess ? (
								<HighlightItem
									type="full"
									//TODO: handle null value
									value={formatDateTime(userData.authEvents.lastSuccess.date)}
									icon={
										userData.authEvents.lastSuccess.response === "Pass"
											? "pi-check-circle"
											: "pi-times-circle"
									}
									wrapperStyle={
										userData.authEvents.lastSuccess.response === "Pass" ? "success" : "error"
									}
								/>
							) : (
								t("user.login.noLastSuccess")
							)}
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default UserDetails;
