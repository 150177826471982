//Node Modules
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router";
import { useRecoilState, useRecoilValueLoadable } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/general";
import { UserDetailsSkeleton } from "../../../components/skeleton";
import { MessageIcon } from "../../../components/general";

//3rd Party Components
import { Button } from "primereact";

//Atoms
import { selectedUserAtom, selectedUserSelector } from "../../../atoms/UserAtom";

//Helpers

//Other
import UserForm from "../../../components/user/UserForm";

const UserUpdate = () => {
	//Hooks
	const { t } = useTranslation();
	const { username } = useLoaderData();
	const formRef = useRef();

	//Recoil
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const { state: selectedUserState, contents: selectedUserData } = useRecoilValueLoadable(selectedUserSelector);

	//On Page Load
	useEffect(() => {
		if (!selectedUser) {
			setSelectedUser({ username: username });
		}
	}, []);

	//Render the User Details
	const PageBody = () => {
		switch (selectedUserState) {
			case "hasError":
				return (
					<MessageIcon
						messageKey={`Error: ${selectedUserData.message ? selectedUserData.message : selectedUserData}`}
						icon="pi-exclamation-circle error"
					/>
				);
			case "loading":
				return <UserDetailsSkeleton />;
			case "hasValue":
				return <UserForm type="userEdit" userData={selectedUserData} ref={formRef} />;
			default:
				return null;
		}
	};

	const featureBttn = (
		<Button
			className="feature"
			label={t("user.userEdit.featureBttn")}
			icon="pi pi-save"
			onClick={() => formRef.current.initUserUpdate()}
			disabled={!selectedUser}
		/>
	);

	return (
		<>
			<PageHeader title={t("user.userEdit.pageTitle")} subtitle={username} enableBack featureBttn={featureBttn} />
			<main>
				<div className="mainContent">
					<div className="card">
						<PageBody />
					</div>
				</div>
			</main>
		</>
	);
};

export default UserUpdate;
