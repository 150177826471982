export const fr = {
	common: {
		appName: "blah",
		company: "blah",
		companyShort: "blah",
		poweredBy: "blah",
		back: "blah",
		multiselectPlaceholder: "blah",
		toggleMap: "blah",
		toggleTable: "blah",
		of: "blah",
		toast: {
			loadDataFailedSummary: "blah",
			loadDataFailedDetail: "blah",
		},
		table: {
			noData: "blah",
			name: "blah",
			createdAt: "blah",
			updatedAt: "blah",
			paginatorTemplate: "blah",
		},
		map: {
			searchPlaceholder: "blah",
		},
		form: {
			label: {
				username: "blah",
			},
			error: {
				username: {
					required: "blah",
					length: "blah",
				},
				password: {
					required: "blah",
				},
			},
		},
		state: {
			success: "blah",
			failure: "blah",
			failures: "blah",
			warning: "blah",
			incomplete: "blah",
			cancelled: "blah",
			delayed: "blah",
			delayed1: "blah",
			delayed2: "blah",
			delayed3: "blah",
			delayed4: "blah",
			notstarted: "blah",
			novalue: "blah",
			healthy: "blah",
		},
		test: {
			function: "blah",
			duration: "blah",
			inspection: "blah",
			addressing: "blah",
			readdressing: "blah",
			deleting: "blah",
			blocking: "blah",
			notblocked: "blah",
			unblocking: "blah",
			notunblocking: "blah",
			dcmode: "blah",
			acmode: "blah",
			deepcharge: "blah",
			information: "blah",
		},
	},
	nav: {
		user: {
			profile: "blah",
			logout: "blah",
		},
		app: {
			summary: "blah",
			duration: "blah",
			building: "blah",
		},
	},
	pageLogin: {
		login: "blah",
		forgotPass: "blah",
		form: {
			label: {
				password: "blah",
			},
			error: {
				password: {
					required: "blah",
				},
			},
		},
		toast: {
			regFailSummary: "blah",
			regFailDetail: "blah",
			loginFailSummary: "blah",
			loginFailDetail: "blah",
		},
	},
	pageSummary: {
		pageHeader: "blah",
		loading: "blah",
		updatingMap: "blah",
		featureBttn: "blah",
		summary: {
			failure: "blah",
			overdue: "blah",
			summary: "blah",
			name: "blah",
			health: "blah",
			updated: "blah",
			noBuilding: "blah",
		},
		table: {
			building: "blah",
			health: "blah",
			function: "blah",
			functionDate: "blah",
			duration: "blah",
			durationDate: "blah",
		},
		toast: {
			findErrorSummary: "blah",
			findErrorDetail: "blah",
		},
		dialog: {
			header: "blah",
			footer: {
				no: "blah",
				yes: "blah",
			},
			message: "<p>blah</p><p>blah</p>",
		},
	},
	pageDuration: {
		pageHeader: "blah",
		updatingMap: "blah",
		summary: {
			failure: "blah",
			overdue: "blah",
			summary: "blah",
			name: "blah",
			state: "blah",
			date: "blah",
			noBuilding: "blah",
		},
		table: {
			building: "blah",
			result: "blah",
			date: "blah",
		},
		toast: {
			findErrorSummary: "blah",
			findErrorDetail: "blah",
		},
	},
	pageTestBook: {
		pageHeader: "blah",
		loading: "blah",
		building: "blah",
		updated: "blah",
		lastFunction: "blah",
		lastDuration: "blah",
		numFaulty: "blah",
		numLocked: "blah",
		numEmOp: "blah",
		numMainsOp: "blah",
		table: {
			path: "blah",
			result: "blah",
			entry: "blah",
			details: "blah",
			timestamp: "blah",
			testType: "blah",
			testSubType: "blah",
			manner: "blah",
			duration: "blah",
		},
	},
	pageBuildingMan: {
		loading: "blah",
		pageHeader: "blah",
		featureBttn: "blah",
		table: {
			lat: "blah",
			lng: "blah",
			new: "blah",
			recent: "blah",
			noName: "blah",
			noLoc: "blah",
		},
		map: {
			search: "blah",
		},
	},
	pageBuildingEdit: {
		pageHeader: "blah",
		featureBttn: "blah",
		building: "blah",
		form: {
			label: {
				name: "blah",
				latitude: "blah",
				longitude: "blah",
				overdueFunc: "blah",
				overdueDur: "blah",
			},
			error: {
				name: {
					required: "blah",
					length: "blah",
				},
				latitude: {
					required: "blah",
					min: "blah",
					max: "blah",
					pattern: "blah",
				},
				longitude: {
					required: "blah",
					min: "blah",
					max: "blah",
					pattern: "blah",
				},
				overdueFunc: {
					required: "blah",
					min: "blah",
					max: "blah",
				},
				overdueDur: {
					required: "blah",
					min: "blah",
					max: "blah",
				},
			},
		},
		toast: {
			successSummary: "blah",
			successDetail: "blah",
			errorSummary: "blah",
			errorDetail: "blah",
		},
	},
	pageProfile: {
		pageHeader: "blah",
		featureBttn: "blah",
		user: "blah",
		updatePass: "blah",
		changeWarning: "blah",
		verifyEmailBttn: "blah",
		form: {
			label: {
				fullname: "blah",
				email: "blah",
				currentPass: "blah",
			},
			error: {
				fullname: {
					required: "blah",
					length: "blah",
				},
				email: {
					required: "blah",
					length: "blah",
					pattern: "blah",
				},
				currentPass: {
					required: "blah",
				},
			},
		},
		toast: {
			updateSuccessSummary: "blah",
			updateSuccessDetail: "blah",
			updateErrorSummary: "blah",
			updateErrorDetail: "blah",
			passwordSuccessSummary: "blah",
			passwordSuccessDetail: "blah",
			passwordErrorSummary: "blah",
			passwordErrorDetail: "blah",
			emailSuccessSummary: "blah",
			emailSuccessDetail: "blah",
			emailErrorSummary: "blah",
			emailErrorDetail: "blah",
		},
	},
	pageRegistration: {
		pageHeader: "blah",
		submit: "blah",
		form: {
			label: {
				tempPassword: "blah",
				newPassword: "blah",
				confirmPassword: "blah",
			},
			error: {
				tempPassword: {
					required: "blah",
				},
				newPassword: {
					required: "blah",
					length: "blah",
					pattern: "blah",
				},
				confirmPassword: {
					match: "blah",
				},
			},
		},
		toast: {
			errorSummary: "blah",
			errorDetail: "blah",
		},
	},
	pageForgotPass: {
		pageHeader: "blah",
		back: "blah",
		steps: {
			step1: "blah",
			step2: "blah",
		},
		toast: {
			codeSentSummary: "blah",
			codeSentDetail: "blah",
			errorSummary: "blah",
			errorDetail: "blah",
			resetSuccessSummary: "blah",
			resetSuccessDetail: "blah",
			resetErrorSummary: "blah",
			resetErrorDetail: "blah",
		},
	},
	pageNotFound: {
		pageHeader: "blah",
		message: "blah",
		contactAdmin: "blah",
	},
};
