//Node Modules
import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Auth } from "aws-amplify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { NoHudHeader, PageHelmet } from "../components/general";
import { InputUsername, InputPasswordConfirm } from "../components/user";

//3rd Party Components
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";

//Atoms
import { toastAtom } from "../atoms/ToastAtom";
import { loadingAtom } from "../atoms/LoadingAtom";

//Other
import { nav } from "../config/navigation";

function ForgotPassword() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	//Recoil Atom States
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoading = useSetRecoilState(loadingAtom);

	//State
	const [stage, setStage] = useState(0);
	const [user, setUser] = useState();

	//Check if we need to go straight to step 2
	useEffect(() => {
		if (searchParams.get("stage") === "2") setStage(1);
	}, [searchParams]);

	//React Hook Form
	const {
		register,
		getValues,
		formState: { errors },
		handleSubmit,
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	const onSubmitRequestCode = async (data) => {
		try {
			await Auth.forgotPassword(data.username);
			setUser(data.username);
			setStage(1);
			setToast({
				...toast,
				severity: "info",
				summary: t("pageForgotPass.toast.codeSentSummary"),
				detail: t("pageForgotPass.toast.codeSentDetail"),
			});
		} catch (error) {
			console.error("Password Reset Code Error:", error);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageForgotPass.toast.errorSummary"),
				detail: t("pageForgotPass.toast.errorDetail", { err: error.message }),
			});
		}
	};

	const onSubmitPasswordReset = async (data) => {
		try {
			setLoading({ visible: true, message: "Requesting Password Reset" });
			await Auth.forgotPasswordSubmit(user, data.code, data.password);
			setToast({
				...toast,
				severity: "success",
				summary: t("pageForgotPass.toast.resetSuccessSummary"),
				detail: t("pageForgotPass.toast.resetSuccessDetail"),
			});
			navigate(nav.general.login);
		} catch (error) {
			console.error("Password Reset Error:", error);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageForgotPass.toast.resetErrorSummary"),
				detail: t("pageForgotPass.toast.resetErrorDetail", {
					err: error.message,
				}),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const items = [{ label: t("pageForgotPass.steps.step1") }, { label: t("pageForgotPass.steps.step2") }];

	return (
		<div className="noHud">
			<PageHelmet title={t("pageForgotPass.pageHeader")} />
			<NoHudHeader />

			<div className="noHudMain">
				<h2 className="marginBottomMedium">{t("pageForgotPass.pageHeader")}</h2>
				<div className="divider marginBottomLarge"></div>
				<Steps className="marginBottomMedium" model={items} activeIndex={stage} />

				{stage === 0 && (
					<form onSubmit={handleSubmit(onSubmitRequestCode)}>
						<InputUsername register={register} errors={errors} />
						<Button type="submit" label="Request Code" className="feature" />
					</form>
				)}
				{stage === 1 && (
					<form onSubmit={handleSubmit(onSubmitPasswordReset)}>
						<div className="formField">
							<label htmlFor="code">Code</label>
							<input
								type="text"
								className={errors.code ? "error" : ""}
								{...register("code", { required: true })}
							/>
							<ul className="errors">
								{errors?.code?.types?.required && <li className="error">Code is required</li>}
							</ul>
						</div>
						<InputPasswordConfirm register={register} errors={errors} values={getValues} />
						<Button type="submit" label="Reset Password" className="feature" />
					</form>
				)}
				<Link to={nav.general.login} className="block marginTopSmall small center">
					{t("pageForgotPass.back")}
				</Link>
			</div>
		</div>
	);
}

export default ForgotPassword;
