//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../components/general";

//3rd Party Components
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { userAtom } from "../../atoms/UserAtom";
import { selectedBuildingAtom } from "../../atoms/BuildingAtom";
import { selectedDeviceAtom } from "../../atoms/DeviceAtom";

//Services

//Helpers
import { formatDateTime, today } from "../../helpers/DateUtils";
import { GET_DEVICES_BY_BUILDING, GET_ALL_ACCOUNT_TYPES } from "../../helpers/Constants";
import { useApiRequest, useMakeRequest } from "../../helpers/NetworkUtils";

//Other
import { nav } from "../../config/navigation";
const appElement = document.getElementById("appWrapper");

function DevicesBuilding() {
	const { t } = useTranslation();
	const dt = useRef(null);
	const navigate = useNavigate();
	const makeRequest = useMakeRequest();

	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const selectedBuilding = useRecoilValue(selectedBuildingAtom);
	const [selectedDevice, setSelectedDevice] = useRecoilState(selectedDeviceAtom);

	const [devices, setDevices] = useState();
	const [filters, setFilters] = useState(null);
	const [accountTypeOptions, setAccountTypeOptions] = useState([]);

	const { data } = useApiRequest("get", `${GET_DEVICES_BY_BUILDING}/${selectedBuilding.id}`, null);

	useEffect(() => {
		setDevices(null);
		initFilters();
		setLoading({ visible: true, message: t("pageDevices.loading") });
	}, [user]);

	useEffect(() => {
		data &&
			data.map((d) => {
				d.createdAt = new Date(d.createdAt);
				d.updatedAt = new Date(d.updatedAt);
				d.firmwareVersion = parseFloat(d.firmwareVersion);
				return d;
			});
		setDevices(data);
	}, [data]);

	useEffect(() => {
		getAccountTypes();
	}, []);

	const getAccountTypes = async () => {
		const resp = await makeRequest("get", GET_ALL_ACCOUNT_TYPES, null);
		setAccountTypeOptions(resp);
	};

	const initFilters = () => {
		setFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			firmwareVersion: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			type: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			"network.account.accountType.name": { value: null, matchMode: FilterMatchMode.IN },
			createdAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			updatedAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				maxDate={today.toJSDate()}
				showButtonBar
				showTime={true}
				appendTo={appElement}
			/>
		);
	};

	const typeFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={accountTypeOptions}
				optionLabel="name"
				optionValue="name"
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const basicNumberFilterTemplate = (options) => {
		return (
			<InputNumber
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				mode="decimal"
				minFractionDigits={2}
				showButtons
			/>
		);
	};

	const createdAtRowTemplate = (rowData) => {
		return formatDateTime(rowData.createdAt);
	};

	const updatedAtRowTemplate = (rowData) => {
		return formatDateTime(rowData.updatedAt);
	};

	const viewDataBttn = (
		<Button
			className="feature"
			label={t("pageDevicesBuilding.featureBttn")}
			icon="pi pi-list"
			disabled={!selectedDevice}
			onClick={() => navigate(nav.device.unitDetails)}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageDevices.pageHeader")} />
			<PageHeader
				title={t("pageDevices.pageHeader")}
				subtitle={selectedBuilding.name}
				featureBttn={viewDataBttn}
				enableBack
			/>
			<main>
				<div className="mainContent">
					{/* <div className="column3 horizGap">
						<div className="card cardSummary"></div>
						<div className="card cardSummary"></div>
						<div className="card cardSummary"></div>
					</div> */}

					<div className="card">
						<DataTable
							ref={dt}
							value={devices}
							emptyMessage={t("common.table.noData")}
							selectionMode="single"
							selection={selectedDevice}
							onSelectionChange={(e) => setSelectedDevice(e.value)}
							sortMode="multiple"
							removableSort
							filters={filters}
							filterDisplay="menu"
							autoLayout={true}
							paginator
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
							currentPageReportTemplate={t("common.table.paginatorTemplate")}
							rows={10}
							dataKey="id">
							{/* <Column field="id" header="ID" sortable filter filterMatchMode="contains" /> */}
							<Column
								field="name"
								header={t("common.table.name")}
								sortable
								filter
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="firmwareVersion"
								header={t("pageDevicesBuilding.table.firmware")}
								dataType="numeric"
								sortable
								filter
								filterElement={basicNumberFilterTemplate}
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="type"
								header={t("pageDevicesBuilding.table.type")}
								sortable
								filter
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="network.account.accountType.name"
								header={t("pageDevicesBuilding.table.system")}
								sortable
								filter
								showFilterMatchModes={false}
								filterElement={typeFilter}
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="createdAt"
								header={t("common.table.addedAt")}
								body={createdAtRowTemplate}
								dataType="date"
								sortable
								filter
								filterElement={dateFilterTemplate}
								filterApply={filterApplyTemplate}
							/>
							<Column
								field="updatedAt"
								header={t("common.table.updatedAt")}
								body={updatedAtRowTemplate}
								dataType="date"
								sortable
								filter
								filterElement={dateFilterTemplate}
								filterApply={filterApplyTemplate}
							/>
						</DataTable>
					</div>
				</div>
			</main>
		</>
	);
}

export default DevicesBuilding;
