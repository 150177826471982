//React Router
import { createBrowserRouter } from "react-router-dom";
import { AuthRoute, GuestRoute } from "./helpers";
import { nav } from "../config/navigation";

//Pages
import App from "../App";
import {
	Login,
	CompleteRegistration,
	ForgotPassword,
	NotFound,
	ProjectSelect,
	UserProfile,
	VerifyAttribute,
} from "../pages";
import { BuildingManagement, BuildingCreate, BuildingEdit } from "../pages/admin/building";
import { AccountManagement, AccountCreate, AccountEdit } from "../pages/admin/account";
import { UserCreate, UserDetails, UserManagement, UserUpdate } from "../pages/admin/user";
import { Summary, Duration, TestBook } from "../pages/emergency";
import {
	Networks,
	NetworkBuilding,
	Devices,
	DevicesBuilding,
	DeviceDetails,
	DeviceMap,
	DeviceMapAlt,
} from "../pages/device";
import { Report, ReportPreset } from "../pages/reports";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		children: [
			{
				index: true,
				element: (
					<AuthRoute>
						<Summary />
					</AuthRoute>
				),
			},
			{
				path: nav.general.login,
				element: (
					<GuestRoute>
						<Login />
					</GuestRoute>
				),
			},
			{
				path: nav.general.logout,
				element: (
					<GuestRoute>
						<Login />
					</GuestRoute>
				),
			},
			{
				path: nav.general.projectSelect,
				element: (
					<AuthRoute>
						<ProjectSelect />
					</AuthRoute>
				),
			},
			{
				path: nav.general.completeReg,
				element: (
					<GuestRoute>
						<CompleteRegistration />
					</GuestRoute>
				),
			},
			{
				path: nav.general.forgotPassword,
				element: (
					<GuestRoute>
						<ForgotPassword />
					</GuestRoute>
				),
			},
			{
				path: nav.admin.profile,
				element: (
					<AuthRoute>
						<UserProfile />
					</AuthRoute>
				),
			},
			{
				path: nav.emergency.summary,
				element: (
					<AuthRoute module="emergency">
						<Summary />
					</AuthRoute>
				),
			},
			{
				path: nav.emergency.testbook,
				element: (
					<AuthRoute module="emergency">
						<TestBook />
					</AuthRoute>
				),
			},
			{
				path: nav.emergency.duration,
				element: (
					<AuthRoute module="emergency">
						<Duration />
					</AuthRoute>
				),
			},
			{
				path: nav.device.networks,
				element: (
					<AuthRoute module="casambi">
						<Networks />
					</AuthRoute>
				),
			},
			{
				path: nav.device.networkBuilding,
				element: (
					<AuthRoute module="casambi">
						{" "}
						module="casambi"
						<NetworkBuilding />
					</AuthRoute>
				),
			},
			{
				path: nav.device.buildingUnits,
				element: (
					<AuthRoute module="casambi">
						<DevicesBuilding />
					</AuthRoute>
				),
			},
			{
				path: nav.device.units,
				element: (
					<AuthRoute module="casambi">
						<Devices />
					</AuthRoute>
				),
			},
			{
				path: nav.device.unitDetails,
				element: (
					<AuthRoute module="casambi">
						<DeviceDetails />
					</AuthRoute>
				),
			},
			{
				path: nav.report.custom,
				element: (
					<AuthRoute module="report">
						<Report />
					</AuthRoute>
				),
			},
			{
				path: nav.report.preset,
				element: (
					<AuthRoute module="report">
						<ReportPreset />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.building.buildingManagement,
				element: (
					<AuthRoute>
						<BuildingManagement />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.building.buildingCreate,
				element: (
					<AuthRoute>
						<BuildingCreate />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.building.buildingEdit,
				element: (
					<AuthRoute>
						<BuildingEdit />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.account.management,
				element: (
					<AuthRoute>
						<AccountManagement />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.account.create,
				element: (
					<AuthRoute>
						<AccountCreate />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.account.edit,
				element: (
					<AuthRoute>
						<AccountEdit />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.user.management,
				element: (
					<AuthRoute>
						<UserManagement />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.user.details + "/:username",
				element: (
					<AuthRoute>
						<UserDetails />
					</AuthRoute>
				),
				loader: ({ params }) => {
					return params;
				},
			},
			{
				path: nav.admin.user.create,
				element: (
					<AuthRoute>
						<UserCreate />
					</AuthRoute>
				),
			},
			{
				path: nav.admin.user.update + "/:username",
				element: (
					<AuthRoute>
						<UserUpdate />
					</AuthRoute>
				),
				loader: ({ params }) => {
					return params;
				},
			},
			{
				path: nav.device.mapping.indoor,
				element: (
					<AuthRoute>
						<DeviceMap />
					</AuthRoute>
				),
			},
			{
				path: nav.device.mapping.outdoor,
				element: (
					<AuthRoute>
						<DeviceMapAlt />
					</AuthRoute>
				),
			},
			{
				path: nav.general.verifyAttribute,
				element: <VerifyAttribute />,
			},
			{
				path: nav.general.notfound,
				element: (
					<GuestRoute>
						<NotFound />
					</GuestRoute>
				),
			},
		],
	},
]);
