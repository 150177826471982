import React from "react";
import { useTranslation } from "react-i18next";

function InputUsername(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, disabled } = props;

	return (
		<div className="formField">
			<label htmlFor="fullname">{t("pageProfile.form.label.fullname")}</label>
			<input
				type="text"
				defaultValue={defaultValue}
				disabled={disabled}
				className={errors.fullname ? "error" : ""}
				{...register("fullname", { required: true, maxLength: 100, pattern: /^[a-zA-Z\s]*$/ })}
			/>
			<ul className="errors">
				{errors?.fullname?.types?.required && <li className="error">{t("pageProfile.form.error.fullname.required")}</li>}
				{errors?.fullname?.types?.maxLength && <li className="error">{t("pageProfile.form.error.fullname.length")}</li>}
				{errors?.fullname?.types?.pattern && <li className="error">{t("pageProfile.form.error.fullname.pattern")}</li>}
			</ul>
		</div>
	);
}

export default InputUsername;
