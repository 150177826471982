import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { reportDataAtom, reportSeriesIdAtom } from "../../atoms/ReportAtom";
import { isDialogOpenAtom, dialogAtomFamily } from "../../atoms/DialogAtom";
import { loadingAtom } from "../../atoms/LoadingAtom";
import LineDateChart from "./LineDate";
import { MultipleWarningMessages } from "../general";
import { PresetDatatableComponent } from "../../components/reports";
import { formatReportData, getColumnsForReportTable } from "../../helpers/datatable/ReportFunctions";
import { formatDataForDisplay, getFormattedMessages } from "../../helpers/reports/messages";

export default function CustomViewComponent({ isGraphView }) {
	const location = useLocation();
	const setWarningShow = useSetRecoilState(dialogAtomFamily("reportWarning"));
	const chartData = useRecoilValue(reportDataAtom);
	const series = useRecoilValue(reportSeriesIdAtom);
	//It informs the component when the series form is open
	const isOpen = useRecoilValue(isDialogOpenAtom);
	const loading = useRecoilValue(loadingAtom);
	const [messages, setMessages] = useState(null);
	const [data, setData] = useState(null);
	const [formattedData, setFormattedData] = useState([]);
	const [tableColumns, setTableColumns] = useState([]);

	//It formats data for datatable
	const formatData = useCallback(() => {
		return formatReportData(data);
	}, [data]);

	//It generates datatable columns dynamically
	const getColumns = useCallback(() => {
		return getColumnsForReportTable(data);
	}, [data]);

	const getData = useCallback(() => {
		setMessages(null);

		const {
			warningArr: { noData, maxData, error },
			dataArr,
		} = formatDataForDisplay(chartData);

		if (noData.length > 0 || maxData.length > 0 || error.length > 0) {
			setMessages(getFormattedMessages(location.pathname, { noData, maxData, error }));
			setWarningShow(true);
		}

		return dataArr.length > 0 ? dataArr : null;
	}, [chartData, setWarningShow]);

	useEffect(() => {
		if (chartData.length > 0) {
			const data = getData();
			setData(data);
		}
	}, [chartData, getData]);

	//It prepares the data for datatable
	useEffect(() => {
		if (data) {
			setFormattedData(formatData());
			setTableColumns(getColumns());
		}
	}, [data, formatData, getColumns]);

	return (
		<div>
			{series.length > 0 && !isOpen && !loading.visible && (
				<>
					{data &&
						(isGraphView ? (
							<div className="card">
								<LineDateChart data={data} unit="" />
							</div>
						) : (
							<PresetDatatableComponent
								formattedData={formattedData}
								reportName={""}
								columns={tableColumns}
							/>
						))}
					<MultipleWarningMessages messages={messages} />
				</>
			)}
		</div>
	);
}
