import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import AppNav from "./AppNav";

import { themeAssetsAtom } from "../../atoms/ThemeAssetsAtom";

function AppHeader() {
  const { t } = useTranslation();

  //Theme
  const { appName, logoAltHoriz } = useRecoilValue(themeAssetsAtom);

  return (
    <header className="appHeader">
      <div className="appHeaderContent">
        <div>
          <img src={logoAltHoriz} alt="Logo" />
          {appName !== "pushfusion" && (
            <>
              &bull; {t("common.poweredBy")} {t("common.companyShort")}
            </>
          )}
        </div>

        <AppNav />
      </div>
    </header>
  );
}

export default AppHeader;
