//Node Modules
import React, { useEffect, useState, useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { classNames } from "primereact/utils";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { dialogAtomFamily, isDialogOpenAtom } from "../../atoms/DialogAtom";
import {
	reportDataAtom,
	reportFilterAtom,
	reportSeriesAtomFamily,
	reportSeriesIdAtom,
	reportTypeOptionsAtom,
} from "../../atoms/ReportAtom";

//Helpers
// import { compareArray } from "../../helpers/GeneralUtils";
import { aggregateOptions, reportTypeIcon, filterByOptions } from "../../helpers/chart/reportFilterOptions";
import {
	getReportTypeDevices,
	GET_DEVICE_NAMES_FOR_FORM,
	GET_ALL_NETWORKS,
	GET_BUILDINGS,
} from "../../helpers/Constants";
import { useMakeRequest } from "../../helpers/NetworkUtils";
import { useAddSeries } from "../../helpers/chart/hooks";
import { useSeries } from "../../helpers/reports/hooks";

//Other
const appElement = document.getElementById("appWrapper");

function ReportSeriesItemAdd({ id }) {
	const { t } = useTranslation();

	//Recoil
	const [show, setShow] = useRecoilState(dialogAtomFamily(id));
	const setLoading = useSetRecoilState(loadingAtom);
	const [seriesIds, setSeriesIds] = useRecoilState(reportSeriesIdAtom);
	const [series, setSeries] = useRecoilState(reportSeriesAtomFamily(id));
	// const AllSeries = useRecoilValue(reportSeriesSelectorFamily(seriesIds));
	const reportFilter = useRecoilValue(reportFilterAtom);
	const [reportData, setReportData] = useRecoilState(reportDataAtom);
	const deviceTypes = useRecoilValue(reportTypeOptionsAtom);
	const setIsOpen = useSetRecoilState(isDialogOpenAtom);
	const { dataSeries } = useSeries();

	//State
	const [filterSelection, setFilterSelection] = useState([]);
	const [reportType, setReportType] = useState(null);

	const makeRequest = useMakeRequest();
	const onAddSeries = useAddSeries();

	//React Hook Form
	const defaultValues = {
		seriesName: series.name,
		reportType: series.reportType,
		aggregate: series.aggregate,
		position: series.position,
	};

	const {
		control,
		formState: { errors },
		handleSubmit,
		setValue,
		getValues,
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues,
	});

	const watchReportType = useWatch({
		control,
		name: "reportType",
	});

	const watchFilterBy = useWatch({
		control,
		name: "filterBy",
	});

	const getFilterSelectionOptions = useCallback(
		async (filterOption) => {
			try {
				let filterSelectionOptions;
				if (filterOption === "device") {
					setLoading({ visible: true, message: t("pageReport.updatingDevices") });
					const resp = await makeRequest("get", getReportTypeDevices(reportType), null);
					filterSelectionOptions = await makeRequest(
						"post",
						GET_DEVICE_NAMES_FOR_FORM,
						resp.map((d) => d.unit_id)
					);
				} else if (filterOption === "network") {
					setLoading({ visible: true, message: t("pageReport.updatingNetwork") });
					const resp = await makeRequest("get", GET_ALL_NETWORKS, null);
					filterSelectionOptions = resp.map(({ id, name }) => ({ id, name }));
				} else if (filterOption === "building") {
					setLoading({ visible: true, message: t("pageReport.updatingBuildings") });
					const resp = await makeRequest("get", GET_BUILDINGS, null);
					filterSelectionOptions = resp.map(({ id, name }) => ({ id, name }));
				} else {
					filterSelectionOptions = [];
				}

				setFilterSelection(filterSelectionOptions);
			} catch (e) {
				console.error(e);
			} finally {
				setLoading({ visible: false, message: "" });
			}
		},
		[makeRequest, reportType, setLoading, t]
	);

	//After selecting filterBy, it fetches options related to the filter
	useEffect(() => {
		if (watchFilterBy) {
			getFilterSelectionOptions(watchFilterBy);
		}
	}, [getFilterSelectionOptions, watchFilterBy]);

	//Get list of devices based on device type
	useEffect(() => {
		if (watchReportType) {
			//Reset filter by and filter selection if user changes report type.
			//It needs to be extracted from here and be used with compareArray func
			const filters = getValues(["filterBy", "filterSelection"]);
			if (filters[0] || filters[1]) {
				//TODO: keep selection if array same...check first
				//Clean form values
				["filterBy", "filterSelection"].forEach((field) => setValue(field, null));
				//Clean group/filter selection
				setFilterSelection([]);
			}
			setReportType(watchReportType);
		}
	}, [watchReportType, setValue, getValues]);

	//Filter selection effect management
	useEffect(() => {
		if (filterSelection.length === 1) {
			setValue("filterSelection", filterSelection);
		} else {
			//Clear previous values in case user is editing series
			setValue("filterSelection", "");
		}
	}, [filterSelection, setValue]);

	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="small error">{errors[name].message}</span>;
	};

	const handleOnAddSeries = async (data) => {
		setSeries(data);
		setShow(false);
		setIsOpen(false);

		try {
			setLoading({ visible: true, message: "Loading Data" });

			const seriesData = dataSeries.map(async (s, i) => {
				//Fetch all the existing series with same interval/range
				if (seriesIds[i].id !== id) {
					return await onAddSeries(seriesIds[i].id, s);
				} else {
					//Fetch the active series
					return await onAddSeries(id, data);
				}
			});
			setReportData(await Promise.all(seriesData));
		} catch (error) {
			console.error(error);
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const handleCancel = () => {
		if (reportData.length === 0 || !reportData.some((r) => r.id === id)) {
			const keepSeries = seriesIds.filter((s) => s.id !== id);
			setSeriesIds(keepSeries);

			//remove from the chart
			const keepData = reportData.filter((r) => r.id !== id);
			setReportData(keepData);
		}
		setShow(false);
		setIsOpen(false);
	};

	return (
		<Dialog
			id={id}
			visible={show}
			closable={true}
			onHide={() => handleCancel()}
			appendTo={appElement}
			maskClassName="mask">
			<form onSubmit={handleSubmit(handleOnAddSeries)}>
				<div className="formFieldsWrapper">
					<div className="formField">
						<label htmlFor="seriesName">{t("pageReport.series.form.seriesName.label")}</label>
						<Controller
							name="seriesName"
							control={control}
							rules={{
								required: {
									value: true,
									message: t("pageReport.series.form.seriesName.required"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText
									placeholder={t("pageReport.series.form.seriesName.placeholder")}
									id={field.name}
									{...field}
									className={classNames({ error: fieldState.invalid })}
								/>
							)}
						/>
						{getFormErrorMessage("seriesName")}
					</div>
					<div className="formField">
						<label htmlFor="reportType">{t("pageReport.series.form.reportType.label")}</label>
						<Controller
							name="reportType"
							control={control}
							rules={{
								required: {
									value: true,
									message: t("pageReport.series.form.reportType.required"),
								},
							}}
							render={({ field, fieldState }) => {
								const formatLabel = (option) => {
									return (
										<>
											<i className={`icon ${reportTypeIcon[option]} small marginRightSmall`} />
											<p>{t(`pageReport.reportTypes.${option}`)}</p>
										</>
									);
								};
								return (
									<Dropdown
										placeholder={t("pageReport.series.form.reportType.placeholder")}
										id={field.name}
										value={field.value}
										options={deviceTypes}
										itemTemplate={formatLabel}
										valueTemplate={(option) =>
											option ? (
												t(`pageReport.reportTypes.${option}`)
											) : (
												<p style={{ height: 26 }} />
											)
										}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ error: fieldState.invalid })}
										filter
									/>
								);
							}}
						/>
						{getFormErrorMessage("reportType")}
					</div>
					{reportFilter.interval !== -1 && (
						<div className="formField">
							<label htmlFor="aggregate">{t("pageReport.series.form.aggregate.label")}</label>
							<Controller
								name="aggregate"
								control={control}
								rules={{
									required: {
										value: true,
										message: t("pageReport.series.form.aggregate.required"),
									},
								}}
								render={({ field, fieldState }) => (
									<Dropdown
										placeholder={t("pageReport.series.form.aggregate.placeholder")}
										id={field.name}
										value={field.value}
										options={aggregateOptions}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ error: fieldState.invalid })}
										disabled={reportFilter.interval < 0}
									/>
								)}
							/>
							{getFormErrorMessage("aggregate")}
						</div>
					)}
					{reportType && (
						<div className="formField">
							<label htmlFor="filterBy">{t("pageReport.series.form.filterBy.label")}</label>
							<Controller
								name="filterBy"
								control={control}
								rules={{
									required: {
										value: true,
										message: t("pageReport.series.form.filterBy.required"),
									},
								}}
								render={({ field, fieldState }) => {
									const formatLabel = (option) =>
										option ? (
											t(`pageReport.filterByTypes.${option.label}`)
										) : (
											<p style={{ height: 26 }} />
										);
									return (
										<Dropdown
											placeholder={t("pageReport.series.form.filterBy.placeholder")}
											id={field.name}
											value={field.value}
											options={filterByOptions}
											itemTemplate={formatLabel}
											valueTemplate={formatLabel}
											onChange={(e) => field.onChange(e.value)}
											className={classNames({ error: fieldState.invalid })}
											disabled={!reportType ? true : false}
										/>
									);
								}}
							/>
							{getFormErrorMessage("filterBy")}
						</div>
					)}
					{filterSelection.length > 0 && (
						<div className="formField">
							<label htmlFor="filterSelection">{t("pageReport.series.form.filterSelection.label")}</label>
							<Controller
								name="filterSelection"
								control={control}
								rules={{
									required: {
										value: true,
										message: t("pageReport.series.form.filterSelection.required"),
									},
								}}
								render={({ field, fieldState }) => (
									<MultiSelect
										placeholder={t("pageReport.series.form.filterSelection.placeholder")}
										id={field.name}
										value={field.value}
										options={filterSelection}
										optionLabel="name"
										filter
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ error: fieldState.invalid })}
									/>
								)}
							/>
							{getFormErrorMessage("filterSelection")}
						</div>
					)}

					<div className="formField">
						<label htmlFor="position">{t("pageReport.selectAxisPosition")}</label>
						<Controller
							name="position"
							control={control}
							render={({ field, fieldState }) => (
								<SelectButton
									placeholder={t("pageReport.selectAxisPosition")}
									id={field.name}
									value={field.value}
									options={[
										{ value: "left", label: t("common.left") },
										{ value: "right", label: t("common.right") },
									]}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ error: fieldState.invalid })}
								/>
							)}
						/>
					</div>
				</div>
				<div className="flex gapSmall jContentEnd">
					<Button type="button" onClick={() => handleCancel()}>
						{t("pageReport.series.form.cancel")}
					</Button>
					<Button type="submit" className="feature">
						{t("pageReport.series.form.submit")}
					</Button>
				</div>
			</form>
		</Dialog>
	);
}

export default ReportSeriesItemAdd;
