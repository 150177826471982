//Node Modules
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../components/general";
import Map from "../../components/Map";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { toastAtom } from "../../atoms/ToastAtom";
import { userAtom } from "../../atoms/UserAtom";
import { selectedBuildingAtom } from "../../atoms/BuildingAtom";

//Services

//Helpers
import { GET_BUILDINGS_HAS_DEVICES, REFRESH_CLOUD_DEVICES } from "../../helpers/Constants";
import { useApiRequest } from "../../helpers/NetworkUtils";

//Other
import { nav } from "../../config/navigation";

function Devices() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const selectedBuilding = useRecoilValue(selectedBuildingAtom);
	const resetBuilding = useResetRecoilState(selectedBuildingAtom);

	const [buildings, setBuildings] = useState();
	const [endpoint, setEndpoint] = useState(GET_BUILDINGS_HAS_DEVICES);
	const { data } = useApiRequest("get", endpoint, null);

	useEffect(() => {
		resetBuilding();
		setBuildings(null);
		setLoading({ visible: true, message: t("pageDevices.loading") });
	}, [user]);

	useEffect(() => {
		setBuildings(data);
		setEndpoint(null);
	}, [data]);

	const refreshCloudData = async () => {
		try {
			setLoading({ visible: true, message: t("pageDevices.refreshCloudData") });
			setEndpoint(REFRESH_CLOUD_DEVICES);
		} catch (e) {
			console.error(e);
			setToast({
				...toast,
				severity: "error",
				summary: t("common.toast.loadDataFailedSummary"),
				detail: t("common.toast.loadDataFailedDetail"),
			});
		}
	};

	const refreshCloudDataBttn = (
		<Button
			className="feature"
			label={t("pageDevices.featureBttn")}
			icon="pi pi-refresh"
			onClick={refreshCloudData}
		/>
	);
	const viewDevicesBttn = (
		<Button
			className="feature"
			label={t("pageDevices.secondaryBttn")}
			icon="icon-light"
			onClick={() => navigate(nav.device.buildingUnits)}
			disabled={!selectedBuilding.name}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageDevices.pageHeader")} />
			<PageHeader
				title={t("pageDevices.pageHeader")}
				featureBttn={refreshCloudDataBttn}
				secondaryBttn={viewDevicesBttn}
			/>
			<main>
				<div className="mainContent">
					{/* <div className="column3 horizGap">
						<div className="card cardSummary"></div>
						<div className="card cardSummary"></div>
						<div className="card cardSummary"></div>
					</div> */}

					{buildings && <Map places={buildings} />}

					{/* <div className="card">
						<DataTable
							ref={dt}
							value={devices}
							emptyMessage={t("common.table.noData")}
							// selectionMode="single"
							// selection={selectedBuilding}
							// onSelectionChange={(e) => setSelectedBuilding(e.value)}
							sortMode="multiple"
							removableSort
							autoLayout={true}
							paginator
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
							currentPageReportTemplate={t("common.table.paginatorTemplate")}
							rows={10}
							dataKey="id">
							<Column field="id" header="ID" sortable filter filterMatchMode="contains" />
							<Column field="name" header={t("common.table.name")} sortable filter filterMatchMode="contains" />
						</DataTable>
					</div> */}
				</div>
			</main>
		</>
	);
}

export default Devices;
