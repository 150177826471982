//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../components/general";
import Map from "../../components/Map";

//3rd Party Components
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Menu } from "primereact/menu";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { userAtom } from "../../atoms/UserAtom";
import { selectedBuildingAtom } from "../../atoms/BuildingAtom";

//Helpers
import { formatDateTime, isFunctionalBefore, isDurationBefore, isTestBefore, today } from "../../helpers/DateUtils";
import { healthOptions, healthTemplate, resultFilterTemplate } from "../../helpers/datatable/SummaryDatatableUtils";
import { buildFiltersNew } from "../../helpers/ExportUtils";
import { useApiRequest } from "../../helpers/NetworkUtils";
import { GET_BUILDINGS_WITH_SUMMARY } from "../../helpers/Constants";
import { useHandleGenericExport } from "../../helpers/ExportUtils";

//Other
import { nav } from "../../config/navigation";

function Summary() {
	const { t } = useTranslation();
	const dt = useRef(null);
	const moreBttnMenu = useRef(null);
	const navigate = useNavigate();

	const appElement = document.getElementById("appWrapper");

	const handleFileExport = useHandleGenericExport();

	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const [selectedBuilding, setSelectedBuilding] = useRecoilState(selectedBuildingAtom);
	const resetBuilding = useResetRecoilState(selectedBuildingAtom);

	const [viewType, setViewType] = useState(true);
	const [buildings, setBuildings] = useState();
	const [mappedBuildings, setMappedBuildings] = useState();
	const [filterFailed, setFilterFailed] = useState(false);
	const [filterOld, setFilterOld] = useState(false);
	const [filteredData, setFilteredData] = useState(null);
	const [filteredState, setFilteredState] = useState(null);
	const [filters, setFilters] = useState(null);

	const { data } = useApiRequest("get", GET_BUILDINGS_WITH_SUMMARY, null);

	useEffect(() => {
		resetBuilding();
		setBuildings(null);
		initFilters();
		setLoading({ visible: true, message: t("pageSummary.loading") });
	}, [user.selectedProject]);

	useEffect(() => {
		data &&
			data.map((d) => {
				d.emergencySummary.lastFunctionTestDate = new Date(d.emergencySummary.lastFunctionTestDate);
				d.emergencySummary.lastDurationTestDate = new Date(d.emergencySummary.lastDurationTestDate);
				return d;
			});
		setBuildings(data);
	}, [data]);

	useEffect(() => {
		if (filterFailed && filterOld) {
			setMappedBuildings(
				buildings.filter(
					(b) => b.emergencySummary.state !== "HEALTHY" && (isFunctionalBefore(b) || isDurationBefore(b))
				)
			);
		} else if (filterFailed && !filterOld) {
			setMappedBuildings(
				buildings.filter(
					(b) => b.emergencySummary.state !== "HEALTHY" && !b.emergencySummary.state.startsWith("OVERDUE")
				)
			);
		} else if (!filterFailed && filterOld) {
			setMappedBuildings(buildings.filter((b) => isFunctionalBefore(b) || isDurationBefore(b)));
		} else {
			setMappedBuildings(buildings);
		}
	}, [buildings, filterFailed, filterOld]);

	const initFilters = () => {
		setFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			"emergencySummary.state": { value: null, matchMode: FilterMatchMode.IN },
			"emergencySummary.faultyCount": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			"emergencySummary.totalCount": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			"emergencySummary.failCount": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			"emergencySummary.warnCount": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			"emergencySummary.lastFunctionTestDate": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			"emergencySummary.lastDurationTestDate": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const handleFilterFailed = (value) => {
		setLoading({ visible: true, message: t("pageSummary.updatingMap") });
		if (value) setSelectedBuilding(null);
		setFilterFailed(value);
		setLoading({ visible: false, message: "" });
	};

	const handleFilterOld = (value) => {
		setLoading({ visible: true, message: t("pageSummary.updatingMap") });
		if (value) setSelectedBuilding(null);
		setFilterOld(value);
		setLoading({ visible: false, message: "" });
	};

	const functionDateRowTemplate = (rowData) => {
		return (
			<span
				className={
					isTestBefore(rowData.emergencySummary.lastFunctionTestDate, rowData.overdueFunctional)
						? "warn withTooltip"
						: undefined
				}
				aria-label={`${t("pageSummary.table.overdue")}: ${rowData.overdueFunctional} days`}>
				{formatDateTime(rowData.emergencySummary.lastFunctionTestDate)};
			</span>
		);
	};
	const durationDateRowTemplate = (rowData) => {
		return (
			<span
				className={
					isTestBefore(rowData.emergencySummary.lastDurationTestDate, rowData.overdueDuration)
						? "warn withTooltip"
						: undefined
				}
				aria-label={`${t("pageSummary.table.overdue")}: ${rowData.overdueDuration} days`}>
				{formatDateTime(rowData.emergencySummary.lastDurationTestDate)};
			</span>
		);
	};

	const healthRowTemplate = (rowData) => {
		return healthTemplate(rowData.emergencySummary.state, rowData.overdueFunctional, rowData.overdueDuration);
	};

	const healthFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={healthOptions}
				itemTemplate={resultFilterTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const basicNumberFilterTemplate = (options) => {
		return (
			<InputNumber
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				showButtons
			/>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				maxDate={today.toJSDate()}
				showButtonBar
				showTime={true}
				appendTo={appElement}
			/>
		);
	};

	const viewTestBookBttn = (
		<Button
			className="feature"
			label={t("pageSummary.featureBttn")}
			icon="pi pi-book"
			onClick={() => navigate(nav.emergency.testbook)}
			disabled={selectedBuilding && !selectedBuilding.name}
		/>
	);

	const moreBttnItems = [
		{
			label: "View",
			items: [
				{
					label: viewType ? t("common.toggleTable") : t("common.toggleMap"),
					icon: viewType ? "pi pi-table" : "pi pi-map-marker",
					command: () => setViewType(!viewType),
				},
			],
		},
		!viewType && {
			label: "Export",
			items: [
				{
					label: "PDF",
					icon: "pi pi-file-pdf",
					command: () => handleExport("pdf"),
				},
				{
					label: "Excel",
					icon: "pi pi-file-excel",
					command: () => handleExport("excel"),
				},
				{
					label: "CSV",
					icon: "pi pi-file",
					command: () => handleExport("csv"),
				},
			],
		},
	];

	const moreBttn = (
		<>
			<Button
				label={t("pageAccountMan.secondaryBttn")}
				icon="pi pi-angle-down"
				onClick={(event) => moreBttnMenu.current.toggle(event)}
				aria-label="More Actions"
				aria-controls="bttnMenu"
				aria-haspopup
			/>
			<Menu id="bttnMenu" popup ref={moreBttnMenu} model={moreBttnItems} appendTo={appElement} />
		</>
	);

	const handleExport = async (type) => {
		const dtFilters = JSON.parse(sessionStorage.getItem("dt-state-summary")).filters;

		const tableName = "Emergency Test Summary";
		const tableResource = "common.state";
		const columns = dt.current.props.children.map((p) => p.props.header);
		const fields = dt.current.props.children.map((p) => p.props.field);
		const filters = buildFiltersNew(dtFilters, "pageSummary", "common.state");
		const baseData = filteredData ? filteredData : buildings;

		handleFileExport(
			type,
			tableName,
			{ baseData: baseData, filters: filters, columns: columns, fields: fields },
			tableResource
		);
	};

	return (
		<>
			<PageHelmet title={t("pageSummary.pageHeader")} />
			<PageHeader title={t("pageSummary.pageHeader")} featureBttn={viewTestBookBttn} secondaryBttn={moreBttn} />
			<main>
				<div className="mainContent">
					<div className="column3 horizGap">
						<div className="card cardSummary">
							<div className="column2 autoColumns">
								<h2>{t("pageSummary.summary.failure")}</h2>
								{viewType && (
									<InputSwitch
										checked={filterFailed}
										onChange={(e) => handleFilterFailed(e.value)}
										className="jSelfEnd"
									/>
								)}
							</div>
							{buildings && (
								<div>
									<p className="h1 double">
										{
											buildings.filter(
												(b) =>
													b.emergencySummary.state !== "HEALTHY" &&
													!b.emergencySummary.state.startsWith("OVERDUE")
											).length
										}{" "}
										<span className="h3 thin">{t("common.of")}</span> {buildings.length}
									</p>
								</div>
							)}
						</div>
						<div className="card cardSummary">
							<div className="column2 autoColumns">
								<h2>{t("pageSummary.summary.overdue")}</h2>
								{viewType && (
									<InputSwitch
										checked={filterOld}
										onChange={(e) => handleFilterOld(e.value)}
										className="jSelfEnd"
									/>
								)}
							</div>
							{buildings && (
								<div>
									<p className="h1 double">
										{buildings.filter((b) => isFunctionalBefore(b) || isDurationBefore(b)).length}{" "}
										<span className="h3 thin">{t("common.of")}</span> {buildings.length}
									</p>
								</div>
							)}
						</div>
						<div className="card cardSummary">
							<h2>{t("pageSummary.summary.summary")}</h2>
							{selectedBuilding && selectedBuilding.emergencySummary ? (
								<div className="column2 autoColumns noVertGap">
									<span>{t("pageSummary.summary.name")}:</span>
									<span>{selectedBuilding.name}</span>
									<span>{t("pageSummary.summary.health")}:</span>
									<span>{healthTemplate(selectedBuilding.emergencySummary.state)}</span>
									<span>{t("pageSummary.summary.updated")}:</span>
									<span>{formatDateTime(selectedBuilding.emergencySummary.lastUpdatedAt)}</span>
								</div>
							) : (
								<p>{t("pageSummary.summary.noBuilding")}</p>
							)}
						</div>
					</div>
					{buildings && !viewType && (
						<div className="card">
							<DataTable
								ref={dt}
								value={buildings}
								emptyMessage={t("common.table.noData")}
								selectionMode="single"
								selection={selectedBuilding}
								onSelectionChange={(e) => setSelectedBuilding(e.value)}
								// sortMode="multiple"
								removableSort
								filters={filters}
								filterDisplay="menu"
								paginator
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
								currentPageReportTemplate={t("common.table.paginatorTemplate")}
								rows={10}
								onValueChange={(tableState) => setFilteredData(tableState)}
								stateStorage="session"
								stateKey="dt-state-summary">
								<Column field="name" header={t("pageSummary.table.name")} sortable />
								<Column
									field="emergencySummary.state"
									header={t("pageSummary.table.state")}
									body={healthRowTemplate}
									sortable
									filter
									showFilterMatchModes={false}
									filterElement={healthFilter}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.lastFunctionTestDate"
									header={t("pageSummary.table.lastFunctionTestDate")}
									body={functionDateRowTemplate}
									dataType="date"
									sortable
									filter
									filterElement={dateFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.lastDurationTestDate"
									header={t("pageSummary.table.lastDurationTestDate")}
									body={durationDateRowTemplate}
									dataType="date"
									sortable
									filter
									filterElement={dateFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.faultyCount"
									header={t("pageSummary.table.faultyCount")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.totalCount"
									header={t("pageSummary.table.totalCount")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.failCount"
									header={t("pageSummary.table.failCount")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="emergencySummary.warnCount"
									header={t("pageSummary.table.warnCount")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
							</DataTable>
						</div>
					)}
					{mappedBuildings && viewType && <Map places={mappedBuildings} />}
				</div>
			</main>
		</>
	);
}

export default Summary;
