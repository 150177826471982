//Node Modules
import { useCallback } from "react";
import { API } from "aws-amplify";
import { useRecoilValue, useSetRecoilState } from "recoil";

//Atoms
import { dialogAtomFamily } from "../atoms/DialogAtom";
import { userAtom } from "../atoms/UserAtom";

//Helpers
import { useLogout } from "../services/BaseServices";

export const useApiRequest = () => {
	const apiName = "pushfusionMooreAPI";
	const handleLogout = useLogout();
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const user = useRecoilValue(userAtom);

	const makeRequest = useCallback(async (verb = "get", endpoint, body, message) => {
		console.log("Calling ::", `${verb.toUpperCase()} ${endpoint}`, `Body: ${body}`);

		const customInit = {
			body,
			headers: {
				"X-BinaryForge-Project": user.selectedProject,
			},
		};

		try {
			message && setLoading({ visible: true, message: message });
			const response = await API[verb](apiName, endpoint, customInit);
			console.log("useApiRequest Response Data ::", response);
			return response;
		} catch (err) {
			console.error("useApiRequestError:", err);
			if (err.code === "NotAuthorizedException" || err === "No current user") {
				handleLogout();
			} else if (err.response) {
				throw err;
			}
		} finally {
			setLoading({ visible: false, message: "" });
		}
	}, []);

	return makeRequest;
};
