import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { themeAssetsAtom } from "../../atoms/ThemeAssetsAtom";

function AppFooter() {
	const { t } = useTranslation();

	//Theme
	const { appName } = useRecoilValue(themeAssetsAtom);

	return (
		<footer className="appFooter">
			<div className="appFooterContent">
				{appName} v{process.env.REACT_APP_VERSION} | &copy; {new Date().getFullYear()} {t("common.company")}
			</div>
		</footer>
	);
}

export default AppFooter;
