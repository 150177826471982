//Node Modules
import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { GoogleMap, Marker } from "@react-google-maps/api";

//Pages

//BinaryForge Components
import { InputName, InputLat, InputLng, InputOverdueFunc, InputOverdueDur } from "./";
import AssignGateway from "./AssignGatway";

//3rd Party Components

//Atoms
import { selectedBuildingAtom } from "../../atoms/BuildingAtom";

//Helpers
import { mapContainerStyle, mapZoom, mapOptions } from "../../helpers/MapUtils";

//Other
import selectedMarker from "../../assets/imgs/map/selectedMarker.svg";

function BuildingFormContent(props) {
	const { t } = useTranslation();
	const mapRef = useRef();
	const { register, errors, setValue } = props;

	// Atom States
	const [selectedBuilding, setSelectedBuilding] = useRecoilState(selectedBuildingAtom);

	// Google Maps
	const onMapLoad = useCallback((map) => {
		mapRef.current = map;
	}, []);

	const onMarkerDrag = useCallback(
		(event) => {
			setSelectedBuilding({
				...selectedBuilding,
				latitude: event.latLng.lat(),
				longitude: event.latLng.lng(),
			});
			setValue("latitude", event.latLng.lat(), { shouldValidate: true });
			setValue("longitude", event.latLng.lng(), { shouldValidate: true });
		},
		[selectedBuilding, setSelectedBuilding, setValue]
	);

	const updateMap = (name, value) => {
		const coord = parseFloat(value);
		if (name === "latitude") setSelectedBuilding({ ...selectedBuilding, latitude: coord });
		if (name === "longitude") setSelectedBuilding({ ...selectedBuilding, longitude: coord });
	};

	const handleSelectGateway = (gateway) => {
		setSelectedBuilding({ ...selectedBuilding, gateway: gateway });
	};

	return (
		<>
			<div className="grid column2 horizGap">
				<div className="card">
					<div className="formFieldsWrapper">
						<InputName register={register} errors={errors} defaultValue={selectedBuilding.name} />
						<InputLat
							register={register}
							errors={errors}
							defaultValue={selectedBuilding.latitude}
							updateMap={updateMap}
						/>
						<InputLng
							register={register}
							errors={errors}
							defaultValue={selectedBuilding.longitude}
							updateMap={updateMap}
						/>
						<InputOverdueFunc
							register={register}
							errors={errors}
							defaultValue={selectedBuilding.overdueFunctional}
						/>
						<InputOverdueDur
							register={register}
							errors={errors}
							defaultValue={selectedBuilding.overdueDuration}
						/>
						<div className="formField">
							<label htmlFor="assignGateway">{t("pageBuildingEdit.form.label.assignGw")}</label>
							<AssignGateway
								currentGateway={selectedBuilding.gateway}
								handleSelectGateway={handleSelectGateway}
							/>
						</div>
					</div>
				</div>
				<div className="card noPadding">
					<GoogleMap
						mapContainerStyle={mapContainerStyle}
						zoom={mapZoom}
						center={{
							lat: selectedBuilding.latitude,
							lng: selectedBuilding.longitude,
						}}
						options={mapOptions}
						onLoad={onMapLoad}>
						<Marker
							key={selectedBuilding.id}
							title={selectedBuilding.name}
							position={{
								lat: selectedBuilding.latitude,
								lng: selectedBuilding.longitude,
							}}
							icon={{
								url: selectedMarker,
								scaledSize: new window.google.maps.Size(40, 60),
								origin: new window.google.maps.Point(0, 0),
								anchor: new window.google.maps.Point(20, 60),
							}}
							draggable={true}
							onDragEnd={onMarkerDrag}
						/>
					</GoogleMap>
				</div>
			</div>
		</>
	);
}

export default BuildingFormContent;
