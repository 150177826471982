import { useEffect, useState, useCallback } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { API } from "aws-amplify";
import { userAtom } from "../atoms/UserAtom";
import { toastAtom } from "../atoms/ToastAtom";
import { loadingAtom } from "../atoms/LoadingAtom";
import { useTranslation } from "react-i18next";
import { useLogout } from "../services/BaseServices";

const apiName = "pushfusionMooreAPI";

export const useApiRequest = (verb = "get", endpoint, body) => {
	const { t } = useTranslation();
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const [data, setData] = useState();
	const handleLogout = useLogout();

	const customInit = {
		headers: {
			"X-BinaryForge-Project": user.selectedProject.toLowerCase(),
		},
		body,
	};

	const apiRequest = async () => {
		process.env.NODE_ENV === "development" && console.log("Calling :", `${verb} - ${endpoint} - Body : ${body}`);
		try {
			//Make calls using amplify API instead of axios (although they've axios built in)
			const response = await API[verb](apiName, endpoint, customInit);
			setData(response);
			process.env.NODE_ENV === "development" && console.log("useApiRequest Response Data:", response);
		} catch (error) {
			console.error(error);
			//Logout user if token is expired
			if (error.code === "NotAuthorizedException" || error === "No current user") {
				handleLogout();
			}
			setToast({
				...toast,
				severity: "error",
				summary: t("common.toast.loadDataFailedSummary"),
				detail: t("common.toast.loadDataFailedDetail"),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	useEffect(() => {
		if (endpoint !== null) {
			apiRequest();
		}
	}, [verb, endpoint, body, user.selectedProject]);

	return { data, apiRequest };
};

export const useMakeRequest = () => {
	const user = useRecoilValue(userAtom);
	const handleLogout = useLogout();

	const makeRequest = useCallback(
		async (verb = "get", endpoint, body) => {
			process.env.NODE_ENV === "development" &&
				console.log("Calling :", `${verb} - ${endpoint} - Body : ${body}`);

			const customInit = {
				headers: {
					"X-BinaryForge-Project": user.selectedProject,
				},
				body,
			};

			try {
				//Make calls using amplify API instead of axios (although they've axios built in)
				const response = await API[verb](apiName, endpoint, customInit);

				process.env.NODE_ENV === "development" && console.log("useMakeRequest Response Data:", response);

				return response;
			} catch (error) {
				console.error("makeRequestError:", error);
				if (error.code === "NotAuthorizedException" || error === "No current user") {
					handleLogout();
				}

				const errMsg =
					error.response && error.response.data.errorMessage
						? error.response.data.errorMessage
						: error.message;
				return { error: true, msg: errMsg };
			}
		},
		[user.selectedProject]
	);

	return makeRequest;
};
