import { formatDateTimeFile } from "../DateUtils";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as xlsx from "xlsx";

import ExcelJS from "exceljs";

import { pushFusionBadgeWithText } from "../../assets/imgs/pdf/badgeWithText";
import { formatDateTime } from "../DateUtils";

const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
const CSV_TYPE = "text/csv;chartset=UTF-8";
const CSV_EXTENSION = ".csv";
const PDF_EXTENSION = ".pdf";
const MARGIN_TOP = 15;
const MARGIN_LEFT = 15;
const STD_COLOUR = "#3F3E3E";
const PAGE_COLOUR = "#617792";

export const exportPdf = (columns, data, filters, fileName, docTitle, projectName) => {
	jsPDF.autoTableSetDefaults({
		headStyles: { fillColor: [14, 152, 250] },
	});

	const doc = new jsPDF({ orientation: "landscape" });

	doc.addImage({
		imageData: pushFusionBadgeWithText,
		format: "PNG",
		x: MARGIN_LEFT,
		y: MARGIN_TOP,
		width: 58,
		height: 70,
		alias: "logo",
		compression: null,
		rotataion: 0,
	});

	doc.setFont("helvetica", "bold");
	doc.setTextColor(STD_COLOUR);
	doc.setFontSize(24);
	doc.text("pushfusion Data Export", MARGIN_LEFT, 115);
	doc.text(docTitle, MARGIN_LEFT, 125);

	doc.setFontSize(12);
	doc.text(`Project: ${projectName}`, MARGIN_LEFT, 135);
	doc.text(`Prepared: ${formatDateTime(new Date().toISOString())}`, MARGIN_LEFT, 141);

	doc.text("Data Filters:", MARGIN_LEFT, 147);
	doc.setFont("helvetica", "normal");
	filters.map((f, i) => {
		return doc.text(f, MARGIN_LEFT * 1.5, 147 + (i + 1) * 5);
	});

	doc.addPage();

	doc.setFont("helvetica", "normal");
	doc.setTextColor(STD_COLOUR);

	if (data.length > 0) {
		doc.autoTable({
			head: [columns],
			body: data,
			rowPageBreak: "avoid",
		});
	} else {
		doc.text("No results.", MARGIN_LEFT, MARGIN_TOP);
	}

	pageNumbers(doc);

	doc.save(fileName + " - " + formatDateTimeFile() + PDF_EXTENSION);
};

export const exportMultiPdf = (columns, data, pageTitles, filters, fileName, docTitle, projectName) => {
	jsPDF.autoTableSetDefaults({
		headStyles: { fillColor: [14, 152, 250] },
	});

	const doc = new jsPDF({ orientation: "landscape" });

	doc.addImage({
		imageData: pushFusionBadgeWithText,
		format: "PNG",
		x: MARGIN_LEFT,
		y: MARGIN_TOP,
		width: 58,
		height: 70,
		alias: "logo",
		compression: null,
		rotataion: 0,
	});

	doc.setFont("helvetica", "bold");
	doc.setTextColor(STD_COLOUR);
	doc.setFontSize(24);
	doc.text("pushfusion Data Export", MARGIN_LEFT, 115);
	doc.text(docTitle, MARGIN_LEFT, 125);

	doc.setFontSize(12);
	doc.text(`Project: ${projectName}`, MARGIN_LEFT, 135);
	doc.text(`Prepared: ${formatDateTime(new Date().toISOString())}`, MARGIN_LEFT, 141);

	doc.text("Data Filters:", MARGIN_LEFT, 147);
	doc.setFont("helvetica", "normal");
	filters.map((f, i) => {
		return doc.text(f, MARGIN_LEFT * 1.5, 147 + (i + 1) * 5);
	});

	data.map((d, i) => {
		doc.addPage();

		doc.setFont("helvetica", "bold");
		doc.setFontSize(24);
		pageTitles[i].map((t, idx) => {
			doc.text(t, MARGIN_LEFT, MARGIN_TOP + idx * 10);
			return null;
		});

		doc.setFont("helvetica", "normal");
		doc.setFontSize(12);

		console.log("Start:", pageTitles[0].length, 40 + 10 * pageTitles[0].length);

		if (d.length > 0) {
			doc.autoTable({
				head: [columns],
				body: d,
				rowPageBreak: "avoid",
				startY: 40 + 10 * pageTitles.length,
			});
		} else {
			doc.text("No results.", MARGIN_LEFT, 40 + 10 * pageTitles[0].length);
		}

		return null;
	});

	pageNumbers(doc);

	doc.save(fileName + " - " + formatDateTimeFile() + PDF_EXTENSION);
};

const pageNumbers = (doc) => {
	const pageCount = doc.internal.getNumberOfPages();
	doc.setFontSize(8);
	doc.setTextColor(PAGE_COLOUR);
	for (let i = 1; i <= pageCount; i++) {
		doc.setPage(i);
		doc.text("Page " + String(i) + " of " + String(pageCount), 285, 195, { align: "right" });
	}
};

export const exportExcel = async (columns, data, filters, columnFormats, fileName, docTitle, projectName) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet("data_new");

	//Set up the row heights
	worksheet.getRow(1).height = 20;
	worksheet.getRow(2).height = 20;
	worksheet.getRow(5).height = 70;
	worksheet.getRow(6).height = 40;

	//Add the logo
	worksheet.mergeCells("A1:A5");
	const img = workbook.addImage({
		base64: pushFusionBadgeWithText,
		extension: "png",
	});
	worksheet.addImage(img, {
		tl: { col: 0, row: 0 },
		ext: { width: 159, height: 192 },
	});

	//Add the file details
	worksheet.mergeCells("C1:I1");
	worksheet.mergeCells("C2:I2");
	worksheet.mergeCells("D3:F3");
	worksheet.mergeCells("D4:F4");
	worksheet.mergeCells("D5:F5");

	worksheet.getCell("C1").value = "pushfusion Data Export";
	worksheet.getCell("C2").value = docTitle;
	worksheet.getCell("C3").value = "Project:";
	worksheet.getCell("C4").value = "Perpared:";
	worksheet.getCell("C5").value = "Data Filters:";

	worksheet.getCell("D3").value = projectName;
	worksheet.getCell("D4").value = formatDateTime(new Date().toISOString());
	worksheet.getCell("D5").value = filters.join("\r\n");

	//Style the cells
	worksheet.getCell("C1").font = { size: 16, bold: true };
	worksheet.getCell("C2").font = { size: 16, bold: true };
	worksheet.getCell("C3").font = { bold: true };
	worksheet.getCell("C4").font = { bold: true };
	worksheet.getCell("C5").font = { bold: true };

	// Format the columns
	columnFormats.map((f, i) => {
		worksheet.getColumn(i + 1).alignment = { vertical: "top", horizontal: "left" };
		worksheet.getColumn(i + 1).width = f.width;
		worksheet.getColumn(i + 1).numFmt = f.numFmt;
		return worksheet;
	});

	// Add a table to a sheet
	if (data.length > 0) {
		worksheet.addTable({
			name: "tableData",
			ref: "A7",
			headerRow: true,
			totalsRow: false,
			style: {
				theme: "TableStyleLight2",
				showRowStripes: true,
			},
			columns: columns.map((c, i) => {
				return { name: c, filterButton: true };
			}),
			rows: data,
		});
	} else {
		worksheet.getCell("A6").value = "No results";
	}

	const buffer = await workbook.xlsx.writeBuffer();
	saveAsExcelFile(buffer, fileName);
};

export const exportMultiExcel = async (
	columns,
	data,
	pageTitles,
	filters,
	columnFormats,
	fileName,
	docTitle,
	projectName
) => {
	const workbook = new ExcelJS.Workbook();
	let worksheet = null;
	data.map((d, i) => {
		worksheet = workbook.addWorksheet(pageTitles[i][1]);

		//Set up the row heights
		worksheet.getRow(1).height = 20;
		worksheet.getRow(2).height = 20;
		worksheet.getRow(5).height = 70;
		worksheet.getRow(6).height = 40;

		//Add the logo
		worksheet.mergeCells("A1:A4");
		const img = workbook.addImage({
			base64: pushFusionBadgeWithText,
			extension: "png",
		});
		worksheet.addImage(img, {
			tl: { col: 0, row: 0 },
			ext: { width: 159, height: 192 },
		});

		//Add the file details
		worksheet.mergeCells("C1:I1");
		worksheet.mergeCells("C2:I2");
		worksheet.mergeCells("D3:F3");
		worksheet.mergeCells("D4:F4");
		worksheet.mergeCells("D5:F5");

		worksheet.getCell("C1").value = "pushfusion Data Export";
		worksheet.getCell("C2").value = docTitle;
		worksheet.getCell("C3").value = "Project:";
		worksheet.getCell("C4").value = "Perpared:";
		worksheet.getCell("C5").value = "Data Filters:";

		worksheet.getCell("D3").value = projectName;
		worksheet.getCell("D4").value = formatDateTime(new Date().toISOString());
		worksheet.getCell("D5").value = filters.join("\r\n");

		//Style the cells
		worksheet.getCell("C1").font = { size: 16, bold: true };
		worksheet.getCell("C2").font = { size: 16, bold: true };
		worksheet.getCell("C3").font = { bold: true };
		worksheet.getCell("C4").font = { bold: true };
		worksheet.getCell("C5").font = { bold: true };

		// Format the columns
		columnFormats.map((f, i) => {
			worksheet.getColumn(i + 1).alignment = { vertical: "top", horizontal: "left" };
			worksheet.getColumn(i + 1).width = f.width;
			worksheet.getColumn(i + 1).numFmt = f.numFmt;
			return worksheet;
		});

		if (data.length > 0) {
			worksheet.addTable({
				name: `tableData-${i}`,
				ref: "A7",
				headerRow: true,
				totalsRow: false,
				style: {
					theme: "TableStyleLight2",
					showRowStripes: true,
				},
				columns: columns.map((c) => {
					return { name: c, filterButton: true };
				}),
				rows: d,
			});
		} else {
			worksheet.getCell("A6").value = "No results";
		}

		return null;
	});

	const buffer = await workbook.xlsx.writeBuffer();
	saveAsExcelFile(buffer, fileName);
};

const saveAsExcelFile = (buffer, fileName) => {
	import("file-saver").then((FileSaver) => {
		const data = new Blob([buffer], {
			type: EXCEL_TYPE,
		});
		FileSaver.saveAs(data, fileName + " - " + formatDateTimeFile() + EXCEL_EXTENSION);
	});
};

export const exportCsv = (columns, data, fileName) => {
	data.unshift(columns);
	const worksheet = xlsx.utils.aoa_to_sheet(data);
	const csv = xlsx.utils.sheet_to_csv(worksheet);
	saveAsCSVFile(csv, fileName);
};

const saveAsCSVFile = (buffer, fileName) => {
	import("file-saver").then((FileSaver) => {
		const data = new Blob([buffer], {
			type: CSV_TYPE,
		});
		FileSaver.saveAs(data, fileName + " - " + formatDateTimeFile() + CSV_EXTENSION);
	});
};
