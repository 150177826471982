//Node Modules
import React, { useState, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { Outlet, useLocation } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import { Auth } from "aws-amplify";

//BinaryForge Components
import { Loading, AppHeader, AppFooter, IdleTimeout, LoadingV2 } from "./components/general";

//3rd Party Components
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";

//Atoms
import { themeAtom } from "./atoms/ThemeAtom";
import { toastAtom } from "./atoms/ToastAtom";
import { userAtom } from "./atoms/UserAtom";

//Helpers
import { nav } from "./config/navigation";
import { useLogout } from "./services/BaseServices";
import { useIsValidSession } from "./helpers/Auth";

//Messages & Languages
import "./i18n";

//Styles
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/css/main.css";

//Other
import { guestRoutes } from "./config/navigation";

function App() {
	const toastRef = useRef(null);
	const location = useLocation();
	const handleLogout = useLogout();
	const isValidSession = useIsValidSession();

	const [user, setUser] = useRecoilState(userAtom);
	const toast = useRecoilValue(toastAtom);
	const [theme, setTheme] = useRecoilState(themeAtom);
	const [showHUD, setShowHUD] = useState(false);

	const clearToast = useResetRecoilState(toastAtom);

	const [isSetup, setIsSetup] = useState(false);

	const checkLogin = async () => {
		try {
			if (!guestRoutes.includes(location.pathname)) {
				const session = await Auth.currentSession();
				if (session) {
					setUser((prevState) => ({
						...prevState,
						isLoggedIn: true,
						username: session.idToken.payload["cognito:username"],
						attr: session.getIdToken().payload,
						availableProjects: session.idToken.payload["cognito:groups"],
					}));
				}
			}
		} catch (error) {
			handleLogout();
		} finally {
			setIsSetup(true);
		}
	};

	//On first load set the user, theme and modules
	useEffect(() => {
		const subdomain = window.location.hostname.split(".")[0];
		setTheme(subdomain);
		checkLogin();
	}, []);

	//Generic show toast message
	useEffect(() => {
		if (toast.detail != null) {
			toastRef.current.show({
				severity: toast.severity,
				summary: toast.summary,
				detail: toast.detail,
				life: toast.life,
			});
			clearToast();
		}
	}, [toast, clearToast]);

	useEffect(() => {
		const handleSetShowHUD = async () => {
			const urlRoot = `/${location.pathname.split("/")[1]}`;
			let show = false;
			if ((await isValidSession()) && !Object.values(nav.general).includes(urlRoot)) show = true;
			setShowHUD(show);
		};

		handleSetShowHUD();

		// const urlRoot = `/${location.pathname.split("/")[1]}`;

		// if (!user.isLoggedIn) {
		// 	setShowHUD(false);
		// } else if (urlRoot === "/" || Object.values(nav.general).includes(urlRoot)) {
		// 	setShowHUD(false);
		// } else {
		// 	setShowHUD(true);
		// }
	}, [location]);

	return (
		<div id="appWrapper" className={`${showHUD && "showHUD"} app`} data-theme={theme}>
			<Toast ref={toastRef} />

			{showHUD && <AppHeader />}
			{showHUD && <IdleTimeout />}

			{isSetup && (
				<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
					<Outlet />
				</LoadScript>
			)}

			<ConfirmDialog />
			<Loading />
			<LoadingV2 />
			<AppFooter />
		</div>
	);
}

export default App;
