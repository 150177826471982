//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

//3rd Party Components
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

//Helpers
import { useApiRequest } from "../../helpers/NetworkUtils";
import { GET_ALL_ACCOUNT_TYPES } from "../../helpers/Constants";

function InputType(props) {
	const { t } = useTranslation();
	const { errors, defaultValue, disabled, control } = props;

	// States
	const [accountTypeOptions, setAccountTypeOptions] = useState([]);

	const { data } = useApiRequest("get", GET_ALL_ACCOUNT_TYPES, null);

	useEffect(() => {
		setAccountTypeOptions(data);
	}, [data]);

	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="small error">{errors[name].message}</span>;
	};

	return (
		<div className="formField">
			<label htmlFor="type">{t("pageAccountCreate.form.label.type")}</label>
			<Controller
				name="type"
				control={control}
				defaultValue={defaultValue && defaultValue.id}
				rules={{
					required: {
						value: true,
						message: t("pageAccountCreate.form.error.type.required"),
					},
				}}
				render={({ field, fieldState }) => (
					<Dropdown
						id={field.name}
						value={field.value}
						options={accountTypeOptions}
						optionValue="id"
						optionLabel="name"
						onChange={(e) => field.onChange(e.value)}
						className={classNames({ error: fieldState.invalid })}
						disabled={disabled}
					/>
				)}
			/>
			{getFormErrorMessage("type")}
		</div>
	);
}

export default InputType;
