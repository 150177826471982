//Node Modules
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../components/general";
import Map from "../../components/Map";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";
import { toastAtom } from "../../atoms/ToastAtom";
import { userAtom } from "../../atoms/UserAtom";
import { selectedBuildingAtom } from "../../atoms/BuildingAtom";
import { selectedNetworkAtom } from "../../atoms/NetworkAtom";

//Helpers
import { GET_ALL_BUILDINGS, assignNetworkBuilding } from "../../helpers/Constants";
import { useApiRequest, useMakeRequest } from "../../helpers/NetworkUtils";

//Other
import { nav } from "../../config/navigation";

function NetworkBuilding() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const setLoading = useSetRecoilState(loadingAtom);
	const user = useRecoilValue(userAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const [selectedBuilding, setSelectedBuilding] = useRecoilState(selectedBuildingAtom);
	const selectedNetwork = useRecoilValue(selectedNetworkAtom);
	const resetBuilding = useResetRecoilState(selectedBuildingAtom);

	const [buildings, setBuildings] = useState();

	//Async Hooks
	const { data } = useApiRequest("get", GET_ALL_BUILDINGS, null);
	const makeRequest = useMakeRequest();

	useEffect(() => {
		// Data response from put operation is null, so don't reload the component and go back to previous screen
		setBuildings(data);
	}, [data]);

	useEffect(() => {
		resetBuilding();
		selectedNetwork && setSelectedBuilding(selectedNetwork.building);
		setBuildings(null);
		setLoading({ visible: true, message: "Loading Building Data" });
	}, [user.selectedProject]);

	const assignBuilding = async () => {
		try {
			setLoading({
				visible: true,
				message: t("pageNetworkBuilding.loadingAssign"),
			});

			const updatedNetwork = { ...selectedNetwork };
			updatedNetwork.buildingId = selectedBuilding.id;
			await makeRequest("put", assignNetworkBuilding(selectedNetwork.id), updatedNetwork);
			setToast({
				...toast,
				severity: "success",
				summary: t("pageNetworkBuilding.toast.successSummary"),
				detail: t("pageNetworkBuilding.toast.successDetail"),
			});
			// navigate(nav.device.networks);
		} catch (err) {
			console.error("AccountCreate onSubmit Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageNetworkBuilding.toast.errorSummary"),
				detail: t("pageNetworkBuilding.toast.errorDetail", {
					err: err.message,
				}),
			});
		} finally {
			setLoading({ visible: false, message: "" });
			navigate(nav.device.networks);
		}
	};

	const assignBttn = (
		<Button
			className="feature"
			label={t("pageNetworkBuilding.featureBttn")}
			icon="pi pi-home"
			onClick={assignBuilding}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageNetworkBuilding.pageHeader")} />
			<PageHeader
				title={t("pageNetworkBuilding.pageHeader")}
				subtitle={`Network: ${selectedNetwork.name}`}
				featureBttn={assignBttn}
				enableBack
			/>
			<main>
				<div className="mainContent">
					{/* <div className="column3 horizGap">
						<div className="card cardSummary"></div>
						<div className="card cardSummary"></div>
						<div className="card cardSummary"></div>
					</div> */}

					{buildings && <Map places={buildings} />}
				</div>
			</main>
		</>
	);
}

export default NetworkBuilding;
