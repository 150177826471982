//Node Modules
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

//Pages

//BinaryForgeComponenets
import { PageHeader, PageHelmet } from "../../../components/general";
import { BuildingFormContent } from "../../../components/building";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { loadingAtom } from "../../../atoms/LoadingAtom";
import { toastAtom } from "../../../atoms/ToastAtom";
import { selectedBuildingAtom } from "../../../atoms/BuildingAtom";

//Helpers
import { UPDATE_BUILDING } from "../../../helpers/Constants";
import { useMakeRequest } from "../../../helpers/NetworkUtils";

//Other
import { nav } from "../../../config/navigation";

function BuildingEdit() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const hiddenSubmit = useRef();
	const makeRequest = useMakeRequest();

	// Atom States
	const setLoading = useSetRecoilState(loadingAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const selectedBuilding = useRecoilValue(selectedBuildingAtom);

	// React Hook Form
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	// On Form Submit
	const onSubmit = async (data) => {
		try {
			setLoading({ visible: true, message: "Saving Building Details" });
			await makeRequest("put", `${UPDATE_BUILDING}/${selectedBuilding.id}`, {
				...data,
				gatewayId: selectedBuilding.gateway.id,
			});
			setToast({
				...toast,
				severity: "success",
				summary: t("pageBuildingEdit.toast.successSummary"),
				detail: t("pageBuildingEdit.toast.successDetail"),
			});
			navigate(nav.admin.building.buildingManagement);
		} catch (err) {
			console.error("BuildingEdit onSubmit Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageBuildingEdit.toast.errorSummary"),
				detail: t("pageBuildingEdit.toast.errorDetail", { err: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	// Page Buttons
	const saveBuildingBttn = (
		<Button
			className="feature"
			label={t("pageBuildingEdit.featureBttn")}
			icon="pi pi-save"
			onClick={() => hiddenSubmit?.current?.click()}
		/>
	);

	return (
		<>
			<PageHelmet title={t("pageBuildingEdit.pageHeader")} />
			<PageHeader
				enableBack
				title={t("pageBuildingEdit.pageHeader")}
				subtitle={selectedBuilding.name}
				featureBttn={saveBuildingBttn}
			/>
			<main>
				<div className="mainContent">
					<form onSubmit={handleSubmit(onSubmit)}>
						<BuildingFormContent register={register} errors={errors} setValue={setValue} />
						<button type="submit" hidden={true} ref={hiddenSubmit} />
					</form>
				</div>
			</main>
		</>
	);
}

export default BuildingEdit;
