//Node Modules
import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";

//Pages

//BinaryForge Components
import { PageHelmet } from "../components/general";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { themeAssetsAtom } from "../atoms/ThemeAssetsAtom";
import { toastAtom } from "../atoms/ToastAtom";
import { userAtom } from "../atoms/UserAtom";
import { modulesAtom } from "../atoms/ProjectAtom";
import { selectedBuildingAtom } from "../atoms/BuildingAtom";
import { selectedAccountAtom } from "../atoms/AccountAtom";
import { selectedNetworkAtom } from "../atoms/NetworkAtom";
import { selectedDeviceAtom } from "../atoms/DeviceAtom";
import { reportSeriesIdAtom, reportDataAtom } from "../atoms/ReportAtom";

//Services
import { useLoginUser } from "../services/BaseServices";

//Helpers

//Other
import { nav } from "../config/navigation";

function LoginPage() {
	const { t } = useTranslation();
	const { login } = useLoginUser();

	//Theme
	const { logo, appName } = useRecoilValue(themeAssetsAtom);

	//Recoil Atom States
	const [toast, setToast] = useRecoilState(toastAtom);
	const resetUser = useResetRecoilState(userAtom);
	const resetBuilding = useResetRecoilState(selectedBuildingAtom);
	const resetAccount = useResetRecoilState(selectedAccountAtom);
	const resetDevice = useResetRecoilState(selectedDeviceAtom);
	const resetNetwork = useResetRecoilState(selectedNetworkAtom);
	const resetModule = useResetRecoilState(modulesAtom);
	const resetSeriesId = useResetRecoilState(reportSeriesIdAtom);
	const resetReportData = useResetRecoilState(reportDataAtom);

	//OnLoad
	useEffect(() => {
		resetUser();
		resetModule();
		resetBuilding();
		resetAccount();
		resetDevice();
		resetNetwork();
		resetSeriesId();
		resetReportData();
	}, []);

	//React Hook Form
	const {
		register,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	// Login Form Submit
	const onSubmit = async (data) => {
		try {
			//Sign in user, if it fails, `NotAuthorizedException` will be thrown, triggering the toast.
			const response = await Auth.signIn(data.username, data.password);

			//Check if the user completed registration
			if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
				setToast({
					...toast,
					severity: "warn",
					summary: t("pageLogin.toast.regFailSummary"),
					detail: t("pageLogin.toast.regFailDetail"),
					life: 6000,
				});
			} else {
				//Login user using useLoginUser hook
				login();
			}
		} catch (error) {
			console.error("onSubmit Error:", error);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageLogin.toast.loginFailSummary"),
				detail: t("pageLogin.toast.loginFailDetail"),
				life: 6000,
			});
		}
	};

	return (
		<div className="appLogin">
			<PageHelmet title={t("pageLogin.pageHeader")} />
			<div className="appLogo">
				<div className="logoImg">
					<img src={logo} alt="Logo" />
				</div>
				{/* <div className="divider"></div> */}
				{/* <div className="appName marginTopSmall">{t("common.appName")}</div> */}
				{appName !== "pushfusion" && (
					<>
						<div className="divider"></div>
						<div className="appNameSub">
							{t("common.poweredBy")} {t("common.companyShort")}
						</div>
					</>
				)}
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="formField">
					<label htmlFor="username">{t("common.form.label.username")}</label>
					<input
						type="text"
						className={errors.username ? "error" : ""}
						{...register("username", { required: true, maxLength: 50 })}
					/>
					<ul className="errors">
						{errors?.username?.types?.required && (
							<li className="error">{t("common.form.error.username.required")}</li>
						)}
						{errors?.username?.types?.maxLength && (
							<li className="error">{t("common.form.error.username.length")}</li>
						)}
					</ul>
				</div>
				<div className="formField">
					<label htmlFor="password">{t("pageLogin.form.label.password")}</label>
					<input
						type="password"
						className={errors.password ? "error" : ""}
						{...register("password", { required: true })}
					/>
					<ul className="errors">
						{errors?.password?.types?.required && (
							<li className="error">{t("pageLogin.form.error.password.required")}</li>
						)}
					</ul>
				</div>
				<Button
					label={isSubmitting ? t("pageLogin.loggingIn") : t("pageLogin.login")}
					type="submit"
					className="feature"
					disabled={isSubmitting}
				/>
			</form>
			<Link to={nav.general.forgotPassword} className="block marginTopSmall small center">
				{t("pageLogin.forgotPass")}
			</Link>
		</div>
	);
}

export default LoginPage;
