import React from "react";
import { useTranslation } from "react-i18next";

function InputName(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, readOnly } = props;

	return (
		<div className="formField">
			<label htmlFor="name">{t("pageAccountCreate.form.label.name")}</label>
			<input type="text" defaultValue={defaultValue} readOnly={readOnly} className={errors.name ? "error" : ""} {...register("name", { required: true, maxLength: 100 })} />
			<ul className="errors">
				{errors?.name?.types?.required && <li className="error">{t("pageAccountCreate.form.error.name.required")}</li>}
				{errors?.name?.types?.maxLength && <li className="error">{t("pageAccountCreate.form.error.name.length")}</li>}
			</ul>
		</div>
	);
}

export default InputName;
