import { selector } from "recoil";
import { themeAtom } from "./ThemeAtom";

//Logos Animated
import LogoPF from "../assets/imgs/logo/logoPushFusion.svg";
import LogoLF from "../assets/imgs/theme/lightforge/logoLightForge.svg";
import LogoASD from "../assets/imgs/theme/asd/logoASD2.svg";

import LogoPFHoriz from "../assets/imgs/logo/logoPushFusionHoriz.svg";
import LogoLFHoriz from "../assets/imgs/logo/logoPushFusionHoriz.svg";
import LogoASDHoriz from "../assets/imgs/theme/asd/logoASDHoriz.svg";

import LogoPFAltHoriz from "../assets/imgs/logo/logoPushFusionAltHoriz.svg";

//Badges
import BadgePF from "../assets/imgs/badge/badgePushFusion.svg";
import BadgeLF from "../assets/imgs/theme/lightforge/badgeLightForge.svg";
import BadgeASD from "../assets/imgs/theme/asd/badgeASD.svg";

/* *** NOTE ***
	If the asset is a component the key must be PascalCase
	If the asset is a html element the key my be camelCase
*/

export const themeAssetsAtom = selector({
  key: "themeAssetsAtom",
  get: ({ get }) => {
    const themeName = get(themeAtom);
    let assets = {};

    switch (themeName) {
      case "lightforge":
        assets = {
          logo: LogoLF,
          LogoHoriz: LogoLFHoriz,
          logoAltHoriz: LogoLFHoriz,
          badge: BadgeLF,
          appName: "LightForge Lite",
        };
        break;
      case "asd":
        assets = {
          logo: LogoASD,
          LogoHoriz: LogoASDHoriz,
          logoAltHoriz: LogoASDHoriz,
          badge: BadgeASD,
          appName: "ASD Emergency Dashboard",
        };
        break;
      default:
        assets = {
          logo: LogoPF,
          logoHoriz: LogoPFHoriz,
          logoAltHoriz: LogoPFAltHoriz,
          badge: BadgePF,
          appName: "pushfusion",
        };
        break;
    }

    return assets;
  },
});
