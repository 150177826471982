//Node Modules
import React, { useEffect, useRef, useCallback } from "react";

//Pages

//BinaryForge Components

//3rd Party Components
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-luxon";

//Atoms

//Services

//Helpers
import { getUnitComparingTimestamps } from "../../helpers/chart/dateUtils";
import chartConfig from "../../config/chart.json";

//Other
const deviceNamesMap = {
	1: "L005",
	2: "L002",
	3: "L003",
	4: "L004",
};

function LineDateChart({ data, unit = "" }) {
	const chart = useRef(null);
	Chart.register(...registerables);
	process.env.NODE_ENV === "development" && console.log("From chart:", data);

	const getUpdatedOptions = useCallback(() => {
		let options = {
			responsive: true,
			maintainAspectRatio: false,
			parsing: {
				xAxisKey: "timestamp",
				yAxisKey: "measure_value",
			},
			scales: {
				x: {
					type: "time",
					time: {
						// Luxon format string
						tooltipFormat: "d MMM yy T",
						unit: getUnitComparingTimestamps(data),
						displayFormats: {
							minute: "d MMM HH:mm",
							hour: "d MMM HH:mm",
							day: "d MMM",
							week: "d MMM",
						},
					},
					grid: {
						display: false,
						color: chartConfig.gridColour,
						borderColor: chartConfig.axisColour,
						borderWidth: 3,
					},
					ticks: {
						source: "auto",
						color: chartConfig.colour,
						font: {
							family: "Poppins",
						},
					},
				},
				energy_total: {
					title: {
						display: true,
						text: "Energy Total (Wh)",
						font: {
							family: "Poppins",
						},
					},
					type: "linear",
					display: data.some((d) => d.type === "energy_total"),
					// position: data.some((d) => (d.type === "energy_total" ? d.position : "left")),
					position: "right",
					grid: {
						color: chartConfig.gridColour,
						borderColor: chartConfig.axisColour,
						borderWidth: 3,
					},
					ticks: {
						color: chartConfig.colour,
						font: {
							family: "Poppins",
						},
					},
				},
				output_power: {
					title: {
						display: true,
						text: "Output Power (W)",
						font: {
							family: "Poppins",
						},
					},
					type: "linear",
					display: data.some((d) => d.type === "output_power"),
					position: "right",
					grid: {
						color: chartConfig.gridColour,
						borderColor: chartConfig.axisColour,
						borderWidth: 3,
					},
					ticks: {
						color: chartConfig.colour,
						font: {
							family: "Poppins",
						},
					},
				},
				device_temperature: {
					title: {
						display: true,
						text: "Temperature (°C)",
						font: {
							family: "Poppins",
						},
					},
					type: "linear",
					display: data.some((d) => d.type === "device_temperature"),
					position: "right",
					grid: {
						color: chartConfig.gridColour,
						borderColor: chartConfig.axisColour,
						borderWidth: 3,
					},
					ticks: {
						color: chartConfig.colour,
						font: {
							family: "Poppins",
						},
					},
				},
				ambient_temperature: {
					title: {
						display: true,
						text: "Ambient Temperature (°C)",
						font: {
							family: "Poppins",
						},
					},
					type: "linear",
					display: data.some((d) => d.type === "ambient_temperature"),
					position: "right",
					grid: {
						color: chartConfig.gridColour,
						borderColor: chartConfig.axisColour,
						borderWidth: 3,
					},
					ticks: {
						color: chartConfig.colour,
						font: {
							family: "Poppins",
						},
					},
				},
				humidity_relative: {
					title: {
						display: true,
						text: "Relative Humidity",
						font: {
							family: "Poppins",
						},
					},
					type: "linear",
					display: data.some((d) => d.type === "humidity_relative"),
					position: "right",
					grid: {
						color: chartConfig.gridColour,
						borderColor: chartConfig.axisColour,
						borderWidth: 3,
					},
					ticks: {
						color: chartConfig.colour,
						font: {
							family: "Poppins",
						},
					},
				},
				air_quality_index: {
					title: {
						display: true,
						text: "Air Quality Index",
						font: {
							family: "Poppins",
						},
					},
					type: "linear",
					display: data.some((d) => d.type === "air_quality_index"),
					position: "right",
					grid: {
						color: chartConfig.gridColour,
						borderColor: chartConfig.axisColour,
						borderWidth: 3,
					},
					ticks: {
						color: chartConfig.colour,
						font: {
							family: "Poppins",
						},
					},
				},
			},
			plugins: {
				legend: {
					display: true,
					labels: {
						usePointStyle: true,
						padding: 30,
					},
				},

				tooltip: {
					...chartConfig.tooltip,
					callbacks: {
						title: function (context) {
							return context[0].dataset.label;
						},
						label: function (context) {
							// process.env.NODE_ENV === "development" && console.log("Context:", context);
							const labelArray = [context.label, `${context.raw.measure_value} ${unit}`];
							context.raw.unit_id && labelArray.push(deviceNamesMap[context.raw.unit_id]);
							return labelArray;
						},
					},
				},
			},
		};
		data.forEach((d, index) => {
			const yAxisId = options.scales[d.type];

			//Report preset position is always undefined, it needs an assignment here.
			if (d.position) {
				yAxisId.position = d.position;
			} else {
				yAxisId.position = index % 2 === 0 ? "left" : "right";
			}
		});
		return options;
	}, [data, unit]);

	const createChart = useCallback(() => {
		const ctx = chart.current.getContext("2d");

		return new Chart(ctx, {
			type: "line",
			data: {
				datasets: data.map((d, i) => {
					return {
						label: d.name,
						data: d.data,
						fill: true,
						position: d.position,
						borderColor: chartConfig.line[i].borderColour,
						backgroundColor: chartConfig.line[i].backgroundColour,
						tension: 0.25,
						radius: 6,
						pointRadius: 8,
						pointHoverRadius: 10,
						yAxisID: d.type,
					};
				}),
			},
			options: getUpdatedOptions(),
		});
	}, [data, getUpdatedOptions]);

	useEffect(() => {
		// process.env.NODE_ENV === "development" && console.log("Graph Data:", data);
		let lineChart;
		if (data.length > 0) {
			lineChart = createChart();
		}

		return () => {
			if (lineChart) lineChart.destroy();
		};
	}, [createChart, data]);

	return (
		<div className="chartContainer">
			<canvas id="myChart" ref={chart}></canvas>
		</div>
	);
}

export default LineDateChart;
