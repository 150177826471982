//Node Modules
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useIdleTimer } from "react-idle-timer";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
//Pages

//BinaryForge Components
import IdleTimeoutDialog from "../dialog/IdleTimoutDialog";

//3rd Party Components

//Atoms
import { idleDialogAtom } from "../../atoms/DialogAtom";

//Services
import { useLogout } from "../../services/BaseServices";

//Other
const timer = 1000 * 2;
const timeout = 1 * 60 * timer;

function IdleTimeout() {
	const { t } = useTranslation();
	const [isTimedOut, setIsTimedOut] = useState(false);
	const setShow = useSetRecoilState(idleDialogAtom);
	const doLogout = useLogout();

	const handleLogout = () => {
		setShow(false);
		doLogout();
	};

	const handleOnIdle = async (event) => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const tokenExp = user.signInUserSession.idToken.payload.exp;

			if (isTimedOut) {
				setIsTimedOut(false);
				doLogout();
				setShow(false);
			}

			if (tokenExp < new Date(Date.now() + timeout).getTime() / 1000) {
				reset();
				setIsTimedOut(true);
				setShow(true);
			} else {
				reset();
			}
		} catch (error) {
			doLogout();
			setShow(false);
		}
	};

	const handleOnActive = (event) => {
		// console.log("user is active", event);
		setIsTimedOut(false);
	};

	const handleOnAction = (event) => {
		// console.log("user did something", event);
		setIsTimedOut(false);
	};

	const handleUseRemainOnScreen = async () => {
		setIsTimedOut(false);
		setShow(false);
		try {
			const cognitoUser = await Auth.currentAuthenticatedUser({
				bypassCache: true,
			});
			const currentSession = await Auth.currentSession();
			cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
				// console.log("session", err, session);
				// const { idToken, refreshToken, accessToken } = session;
				// do whatever you want to do now :)
			});
		} catch (err) {
			console.error("Unable to refresh token", err);
		}
	};

	const { reset } = useIdleTimer({
		timeout: timeout,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		debounce: 500,
	});

	const idleFooter = (
		<>
			<button onClick={handleUseRemainOnScreen}>{t("common.dialog.idle.remain")}</button>
			<button className="feature" onClick={handleLogout}>
				{t("common.dialog.idle.logout")}
			</button>
		</>
	);

	return (
		<IdleTimeoutDialog header={t("common.dialog.idle.title")} footer={idleFooter}>
			<div className="grid column2 autoColumns">
				<i className="pi pi-exclamation-circle warn large" />
				<p>{t("common.dialog.idle.message")}</p>
			</div>
		</IdleTimeoutDialog>
	);
}

export default IdleTimeout;
