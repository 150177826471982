//React
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

//Recoil
import { RecoilRoot } from "recoil";

//React Router
import { RouterProvider } from "react-router-dom";
import { router } from "./components/Router";

// Amplify
import { Amplify, Auth } from "aws-amplify";
import awsmobile from "./aws-exports";

Amplify.configure({
	...awsmobile,
	API: {
		endpoints: [
			{
				name: "pushfusionMooreAPI",
				endpoint: process.env.REACT_APP_API_AWS + process.env.REACT_APP_API_AWS_STAGE,
				custom_header: async () => {
					return {
						Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
						"Content-Type": "application/json",
					};
				},
			},
		],
	},
});

ReactDOM.createRoot(document.getElementById("root")).render(
	// <React.StrictMode>
	<RecoilRoot>
		<RouterProvider router={router} />
	</RecoilRoot>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
