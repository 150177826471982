import { DateTime, Duration, Interval } from "luxon";
import i18n from "../i18n";

export const today = DateTime.local();
export const todayMidnight = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

export const getDateObject = (date) => {
	if (date instanceof Date) {
		return DateTime.fromJSDate(date);
	} else {
		return DateTime.fromISO(date);
	}
};

export const formatDate = (date) => {
	const dt = date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : "No Date Available";
	return dt;
};

export const formatDateTime = (date) => {
	let dt = null;
	if (date instanceof Date) {
		dt = date ? DateTime.fromJSDate(date).toFormat("d MMM yyyy, HH:mm") : "No Date Available";
	} else {
		dt = date ? DateTime.fromISO(date).toFormat("d MMM yyyy, HH:mm") : "No Date Available";
	}

	return dt;
};

export const formatDateTimeWithSeconds = (date) => {
	let dt = null;
	if (date instanceof Date) {
		dt = date ? DateTime.fromJSDate(date).toFormat("d MMM yyyy, HH:mm:ss") : "No Date Available";
	} else {
		dt = date ? DateTime.fromISO(date).toFormat("d MMM yyyy, HH:mm:ss") : "No Date Available";
	}

	return dt;
};

export const formatDateTimeExcel = (date) => {
	if (date instanceof Date) {
		return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy HH:mm");
	} else {
		return DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm");
	}
};

export const formatDateTimeFile = () => {
	return DateTime.local().toFormat("yyyyMMddHHmm");
};

export const minsToTime = (mins) => {
	return Duration.fromObject({ minutes: mins }).toFormat("hh:mm:ss");
};

export const toTimeBasic = (value, type, output) => {
	const outputFormat = {
		mins: "mm 'mins'",
		hours: "hh 'hrs' mm 'mins'",
		days: "d 'days' hh 'hrs'",
	};
	return Duration.fromObject({ [type]: value }).toFormat(outputFormat[output]);
};

export const isFunctionalBefore = (building) => {
	return isTestBefore(building.emergencySummary.lastFunctionTestDate, building.overdueFunctional);
};

export const isDurationBefore = (building) => {
	return isTestBefore(building.emergencySummary.lastDurationTestDate, building.overdueDuration);
};

export const isTestBefore = (testDate, days) => {
	return getDateObject(testDate) < DateTime.now().minus({ days: days });
};

export const timeRangeOptions = [
	{ value: 0, label: "Custom Range" },
	{ value: 1, label: "Past Day" },
	{ value: 2, label: "Past 7 Days" },
	{ value: 3, label: "Past 1 Month" },
];

export const timeRangeIntervals = {
	1: { months: 0, days: 1, hours: 0, minutes: 0 },
	2: { months: 0, days: 7, hours: 0, minutes: 0 },
	3: { months: 1, days: 0, hours: 0, minutes: 0 },
};

export const selectedTimeRangeToDates = {
	1: [today.minus({ days: 1 }).toJSDate(), today.toJSDate()],
	2: [today.minus({ days: 7 }).toJSDate(), today.toJSDate()],
	3: [today.minus({ month: 1 }).toJSDate(), today.toJSDate()],
};

export const getDatesInterval = (dateArray) => {
	const units = ["month", "days"];
	const diff = Interval.fromDateTimes(dateArray[0], dateArray[1]).toDuration(units);
	return diff.values;
};

export const formatDateByInterval = (obj) => {
	const today = DateTime.local();
	//TODO: need more testing before adding hours and minutes
	return [today.minus({ months: obj.months, days: obj.days }).toJSDate(), today.toJSDate()];
};

//Format timestamp range for criteria
export const formatRangeForPresetReports = (array) => {
	const diff = Interval.fromDateTimes(DateTime.fromISO(array.from), DateTime.fromISO(array.to)).toDuration([
		"days",
		"months",
	]);
	const { months, days } = diff.values;

	if (months === 1) {
		return i18n.t("pageReport.dateRange.past1Month");
	} else if (days === 7) {
		return i18n.t("pageReport.dateRange.past7Days");
	} else if (days === 1) {
		return i18n.t("pageReport.dateRange.pastDay");
	} else {
		return `${formatDateTime(array.from)} - ${formatDateTime(array.to)}`;
	}
};
