//Node Modules
import { useTranslation } from "react-i18next";
import { useRecoilValue, useResetRecoilState, useRecoilState } from "recoil";
import { useNavigate } from "react-router";

//Pages

//BinaryForge Components
import { MessageIcon } from "../../components/general";

//3rd Party Components
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { selectedUserAtom, usersAtom } from "../../atoms/UserAtom";
import { toastAtom } from "../../atoms/ToastAtom";

//Helpers
import { useApiRequest } from "../Api";

//Other
import { nav } from "../../config/navigation";

export const useConfirmUserAction = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const apiRequest = useApiRequest();

	const [toast, setToast] = useRecoilState(toastAtom);
	const selectedUser = useRecoilValue(selectedUserAtom);
	const resetSelectedUser = useResetRecoilState(selectedUserAtom);
	const [users, setUsers] = useRecoilState(usersAtom);

	const doDeleteUser = async () => {
		try {
			await apiRequest("del", `/admin/user/${selectedUser.username}`, null, t("user.userDelete.loading"));
			setToast({
				...toast,
				severity: "success",
				summary: t("user.deleteUser.toast.successSummary"),
				detail: t("user.deleteUser.toast.successDetail", { user: selectedUser.username }),
			});
			const updatedUsers = users.filter((u) => u.username !== selectedUser.username);
			setUsers(updatedUsers);
			resetSelectedUser();
			navigate(nav.admin.user.management);
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("user.deleteUser.toast.errorSummary"),
				detail: t("user.deleteUser.toast.errorDetail", { error: err.message }),
			});
		}
	};

	const doResendInvite = async () => {
		try {
			await apiRequest(
				"post",
				`/admin/user/${selectedUser.username}/confirm`,
				null,
				t("user.resendInvite.loading")
			);
			setToast({
				...toast,
				severity: "success",
				summary: t("user.resendInvite.toast.successSummary"),
				detail: t("user.resendInvite.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("user.resendInvite.toast.errorSummary"),
				detail: t("user.resendInvite.toast.errorDetail", { error: err.message }),
			});
		}
	};

	const doResetPassword = async () => {
		try {
			await apiRequest(
				"post",
				`/admin/user/${selectedUser.username}/reset_password`,
				null,
				t("user.passwordReset.loading")
			);
			setToast({
				...toast,
				severity: "success",
				summary: t("user.passwordReset.toast.successSummary"),
				detail: t("user.passwordReset.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("user.passwordReset.toast.errorSummary"),
				detail: t("user.passwordReset.toast.errorDetail", {
					error: err.response ? err.response.data.message : err.message,
				}),
			});
		}
	};

	const confirmDeleteUser = () => {
		confirmDialog({
			header: t("user.userDelete.header"),
			message: (
				<MessageIcon
					messageKey={t("user.userDelete.message", { user: selectedUser.username })}
					icon="pi pi-exclamation-triangle warn"
				/>
			),
			acceptLabel: t("user.userDelete.accept"),
			rejectLabel: t("user.userDelete.reject"),
			acceptIcon: "pi pi-user-minus",
			acceptClassName: "error feature",
			accept: () => doDeleteUser(),
			reject: () => {}, //Do Nothing,
		});
	};

	const confirmResetPassword = () => {
		confirmDialog({
			header: t("user.userAction.resetPassword.header"),
			message: (
				<MessageIcon
					messageKey={t("user.userAction.resetPassword.message", {
						user: selectedUser.username,
						email: selectedUser.email,
					})}
					icon="pi pi-exclamation-triangle warn"
				/>
			),
			acceptLabel: t("user.userAction.resetPassword.accept"),
			rejectLabel: t("user.userAction.resetPassword.reject"),
			acceptIcon: "pi pi-lock",
			acceptClassName: "success feature",
			accept: () => doResetPassword(),
			reject: () => {}, //Do Nothing,
		});
	};

	const confirmResendInvite = () => {
		confirmDialog({
			header: t("user.userAction.resendInvite.header"),
			message: (
				<MessageIcon
					messageKey={t("user.userAction.resendInvite.message", {
						user: selectedUser.username,
						email: selectedUser.email,
					})}
					icon="pi pi-exclamation-triangle warn"
				/>
			),
			acceptLabel: t("user.userAction.resendInvite.accept"),
			rejectLabel: t("user.userAction.resendInvite.reject"),
			acceptIcon: "pi pi-envelope",
			acceptClassName: "success feature",
			accept: () => doResendInvite(),
			reject: () => {}, //Do Nothing,
		});
	};

	return { confirmDeleteUser, confirmResetPassword, confirmResendInvite };
};
