//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { useTranslation, Trans } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader, PageHelmet } from "../../../components/general";
import FusionDialog from "../../../components/dialog/FusionDialog";
import AssignGateway from "../../../components/building/AssignGatway";
import MessageIcon from "../../../components/general/MessageIcon";

//3rd Party Components
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { loadingAtom } from "../../../atoms/LoadingAtom";
import { toastAtom } from "../../../atoms/ToastAtom";
import { userAtom } from "../../../atoms/UserAtom";
import { selectedBuildingAtom } from "../../../atoms/BuildingAtom";
import { dialogAtomFamily } from "../../../atoms/DialogAtom";

//Helpers
import { formatDateTime, today } from "../../../helpers/DateUtils";
import {
	VERB_BUILDING,
	GET_BUILDINGS,
	assignGatewayToBuilding,
	gatewayLicenceRequest,
} from "../../../helpers/Constants";
import { useApiRequest, useMakeRequest } from "../../../helpers/NetworkUtils";
import {
	scenecomCapacityTemplate,
	scenecomCapacityFilterTemplate,
} from "../../../helpers/datatable/BuildingDatatableUtils";

//Other
import { nav } from "../../../config/navigation";
const appElement = document.getElementById("appWrapper");

function BuildingManagement() {
	const { t } = useTranslation();
	const dt = useRef(null);
	const bttnMenu = useRef(null);
	const navigate = useNavigate();

	// Recoil Atoms State
	const setLoading = useSetRecoilState(loadingAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const user = useRecoilValue(userAtom);
	const [selectedBuilding, setSelectedBuilding] = useRecoilState(selectedBuildingAtom);
	const resetBuilding = useResetRecoilState(selectedBuildingAtom);
	const setAssignGwShow = useSetRecoilState(dialogAtomFamily("assignGateway"));
	const setRequestLicenceShow = useSetRecoilState(dialogAtomFamily("requestLicence"));

	// Component State
	const [buildings, setBuildings] = useState();
	const [selectedGateway, setSelectedGateway] = useState();
	const [additionalLicence, setAdditionalLicence] = useState(1);
	const [filters, setFilters] = useState(null);
	const [assignGatewayBttn, setAssignGatewayBttn] = useState(false);

	const makeRequest = useMakeRequest();
	const { data } = useApiRequest("get", GET_BUILDINGS, null);

	useEffect(() => {
		resetBuilding();
		setBuildings(null);
		initFilters();
		setLoading({ visible: true, message: t("pageBuildingMan.loading") });
	}, [user]);

	useEffect(() => {
		data &&
			data.map((d) => {
				d.createdAt = new Date(d.createdAt);
				d.updatedAt = new Date(d.updatedAt);
				return d;
			});
		setBuildings(data);
	}, [data]);

	const initFilters = () => {
		setFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			"gateway.hardwareId": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
			},
			"gateway.scenecomCapacity": { value: null, matchMode: FilterMatchMode.IN },
			overdueFunctional: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			overdueDuration: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			createdAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			updatedAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const handleSelectGateway = (gateway) => {
		setSelectedGateway(gateway);
	};

	const handleAssignGateway = async () => {
		try {
			setAssignGwShow(false);
			setLoading({ visible: true, message: t("pageBuildingMan.updatingGateways") });

			const buildings = await makeRequest(
				"post",
				assignGatewayToBuilding(selectedBuilding.id, selectedGateway.id),
				null
			);
			setBuildings(buildings);

			setToast({
				...toast,
				severity: "success",
				summary: t("pageBuildingMan.toast.assignGatewaySuccessSummary"),
				detail: t("pageBuildingMan.toast.assignGatewaySuccessDetail"),
			});
		} catch (err) {
			console.error("Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageBuildingMan.toast.assignGatewayErrorSummary"),
				detail: t("pageBuildingMan.toast.assignGatewayErrorDetail", { err: err }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const handleRequestLicence = async () => {
		try {
			setRequestLicenceShow(false);
			setLoading({ visible: true, message: t("pageBuildingMan.updatingGateways") });

			const params = {
				gateway: selectedBuilding.gateway,
				user: {
					name: user.attr.name,
					email: user.attr.email,
				},
				additionalLicence: additionalLicence,
			};

			await makeRequest("post", gatewayLicenceRequest(selectedBuilding.gateway.id), params);

			setToast({
				...toast,
				severity: "success",
				summary: t("pageBuildingMan.toast.requestLicenceSuccessSummary"),
				detail: t("pageBuildingMan.toast.requestLicenceSuccessDetail"),
			});
		} catch (err) {
			console.error("Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageBuildingMan.toast.requestLicenceErrorSummary"),
				detail: t("pageBuildingMan.toast.requestLicenceErrorDetail", { err: err }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const scenecomRowTemplate = (rowData) => {
		return rowData.gateway && rowData.gateway.scenecom
			? scenecomCapacityTemplate(
					rowData.gateway.scenecomLicence,
					rowData.gateway.scenecom.length,
					rowData.gateway.scenecomLoad,
					rowData.gateway.scenecomCapacity
			  )
			: null;
	};

	const createdAtRowTemplate = (rowData) => {
		return formatDateTime(rowData.createdAt);
	};

	const updatedAtRowTemplate = (rowData) => {
		return formatDateTime(rowData.updatedAt);
	};

	const basicNumberFilterTemplate = (options) => {
		return (
			<InputNumber
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				showButtons
			/>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				maxDate={today.toJSDate()}
				showButtonBar
				showTime={true}
				appendTo={appElement}
			/>
		);
	};

	const scenecomFilterTemplate = (options) => {
		const pickone = [
			{ value: "success", label: "Healthy" },
			{ value: "warn", label: "Nearing Capacity" },
			{ value: "error", label: "Potential Data Loss" },
		];

		return (
			<MultiSelect
				showClear
				value={options.value}
				options={pickone}
				optionLabel="label"
				itemTemplate={scenecomCapacityFilterTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
				appendTo={appElement}
			/>
		);
	};

	const confirmDeleteBuilding = () => {
		confirmDialog({
			header: t("pageBuildingMan.delete.confirm.header"),
			message: (
				<Trans
					i18nKey="pageBuildingMan.delete.confirm.message"
					values={{ building: selectedBuilding.name }}
					components={{ note: <p className="light small" />, bold: <strong /> }}
				/>
			),
			acceptLabel: t("pageBuildingMan.delete.confirm.accept"),
			rejectLabel: t("pageBuildingMan.delete.confirm.reject"),
			acceptIcon: "pi pi-trash",
			rejectIcon: "",
			acceptClassName: "error",
			accept: () => handleDeleteBuilding(),
			reject: () => console.log("Do Nothing"),
		});
	};

	const handleDeleteBuilding = async () => {
		setLoading({ visible: true, message: t("pageBuildingMan.delete.loading") });

		try {
			const resp = await makeRequest("del", `${VERB_BUILDING}/${selectedBuilding.id}`, null);

			setBuildings(resp);
			resetBuilding();
			setToast({
				...toast,
				severity: "success",
				summary: t("pageBuildingMan.toast.deleteSuccessSummary"),
				detail: t("pageBuildingMan.toast.deleteSuccessDetail"),
			});
		} catch (err) {
			console.error("Building Delete Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("pageBuildingMan.toast.deleteErrorSummary"),
				detail: t("common.toast.errorDetail", { err: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const bttnItems = [
		{
			items: [
				{
					label: t("pageBuildingMan.secondaryBttnMenu.item1"),
					icon: "pi pi-pencil",
					command: () => {
						navigate(nav.admin.building.buildingEdit);
					},
				},
				// {
				// 	label: t("pageBuildingMan.secondaryBttnMenu.item2"),
				// 	icon: "pi pi-trash",
				// 	command: () => {
				// 		confirmDeleteBuilding();
				// 	},
				// },
				{
					label: t("pageBuildingMan.secondaryBttnMenu.item3"),
					icon: "pi pi-cloud-upload",
					disabled: selectedBuilding && selectedBuilding.gateway,
					command: () => {
						setAssignGwShow(true);
					},
				},
				{
					label: t("pageBuildingMan.secondaryBttnMenu.item4"),
					icon: "pi pi-key",
					disabled: selectedBuilding && !selectedBuilding.gateway,
					command: () => {
						setRequestLicenceShow(true);
					},
				},
			],
		},
	];

	const featureBttn = (
		<Button
			className="feature"
			label={t("pageBuildingMan.featureBttn")}
			icon="pi pi-pencil"
			onClick={() => navigate(nav.admin.building.buildingCreate)}
		/>
	);

	const secondaryBttn = (
		<>
			<Button
				label={t("pageBuildingMan.secondaryBttn")}
				icon="pi pi-angle-down"
				disabled={selectedBuilding && !selectedBuilding.name}
				onClick={(event) => bttnMenu.current.toggle(event)}
				aria-label="More Actions"
				aria-controls="bttnMenu"
				aria-haspopup
			/>
			<Menu id="bttnMenu" popup className="flat" ref={bttnMenu} model={bttnItems} appendTo={appElement} />
		</>
	);

	const assignGatewayFooter = (
		<>
			<button onClick={() => setAssignGwShow(false)}>{t("common.cancel")}</button>
			<button className="feature" onClick={handleAssignGateway} disabled={assignGatewayBttn}>
				{t("pageBuildingMan.dialog.assignGateway.assign")}
			</button>
		</>
	);

	const handleAssignGatewayBttn = (isDisabled) => {
		setAssignGatewayBttn(isDisabled);
	};

	const requestLicenceFooter = (
		<>
			<button onClick={() => setRequestLicenceShow(false)}>{t("common.cancel")}</button>
			<button className="feature" onClick={handleRequestLicence}>
				{t("pageBuildingMan.dialog.requestLicence.confirm")}
			</button>
		</>
	);

	return (
		<>
			<PageHelmet title={t("pageBuildingMan.pageHeader")} />
			<PageHeader
				title={t("pageBuildingMan.pageHeader")}
				featureBttn={featureBttn}
				secondaryBttn={secondaryBttn}
			/>
			<main>
				<div className="mainContent">
					{buildings && (
						<div className="card">
							<DataTable
								ref={dt}
								value={buildings}
								emptyMessage={t("common.table.noData")}
								selectionMode="single"
								selection={selectedBuilding}
								onSelectionChange={(e) => setSelectedBuilding(e.value)}
								sortMode="multiple"
								removableSort
								filters={filters}
								filterDisplay="menu"
								autoLayout={true}
								paginator
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
								currentPageReportTemplate={t("common.table.paginatorTemplate")}
								rows={10}
								dataKey="id">
								<Column
									field="name"
									header={t("common.table.name")}
									sortable
									filter
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="gateway.hardwareId"
									header={t("pageBuildingMan.table.gateway")}
									sortable
									filter
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="gateway.scenecomCapacity"
									header={t("pageBuildingMan.table.scenecom")}
									body={scenecomRowTemplate}
									sortable
									sortField="gateway.scenecomLoad"
									filter
									showFilterMatchModes={false}
									filterElement={scenecomFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="overdueFunctional"
									header={t("pageBuildingMan.table.overdueFunc")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="overdueDuration"
									header={t("pageBuildingMan.table.overdueDur")}
									dataType="numeric"
									sortable
									filter
									filterElement={basicNumberFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="createdAt"
									header={t("common.table.createdAt")}
									body={createdAtRowTemplate}
									dataType="date"
									sortable
									filter
									filterElement={dateFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
								<Column
									field="updatedAt"
									header={t("common.table.updatedAt")}
									body={updatedAtRowTemplate}
									dataType="date"
									sortable
									filter
									filterElement={dateFilterTemplate}
									filterApply={filterApplyTemplate}
								/>
							</DataTable>
						</div>
					)}
				</div>
			</main>

			<FusionDialog
				id="assignGateway"
				header={t("pageBuildingMan.dialog.assignGateway.header")}
				footer={assignGatewayFooter}>
				<AssignGateway
					showMessage
					handleSelectGateway={handleSelectGateway}
					handleAssignGatewayBttn={handleAssignGatewayBttn}
				/>
			</FusionDialog>
			{selectedBuilding && selectedBuilding.gateway && (
				<FusionDialog
					id="requestLicence"
					header={t("pageBuildingMan.dialog.requestLicence.header")}
					footer={requestLicenceFooter}>
					<p>
						{t("pageBuildingMan.dialog.requestLicence.message", {
							gateway: selectedBuilding.gateway.hardwareId,
							licenceCount: selectedBuilding.gateway.scenecomLicence,
							scenecomSupport: selectedBuilding.gateway.scenecomLicence * 5,
						})}
					</p>
					<p>
						{t("pageBuildingMan.dialog.requestLicence.message2", {
							scenecomCount: selectedBuilding.gateway.scenecom.length,
						})}
					</p>
					<p>
						{t("pageBuildingMan.dialog.requestLicence.message3", {
							gateway: selectedBuilding.gateway.hardwareId,
						})}
					</p>
					<InputNumber
						inputId="additionalLicence"
						value={additionalLicence}
						onValueChange={(e) => setAdditionalLicence(e.value)}
						showButtons
						buttonLayout="horizontal"
						step={1}
						min={1}
						decrementButtonClassName="feature"
						incrementButtonClassName="feature"
						incrementButtonIcon="pi pi-plus"
						decrementButtonIcon="pi pi-minus"
						className="marginTopXSmall marginBottomMedium"
					/>
					<div className="flexVert gapSmall">
						<MessageIcon
							icon={`pi-info-circle info`}
							messageKey={t("pageBuildingMan.dialog.requestLicence.info")}
							messageStyle="small"
						/>
						<MessageIcon
							icon={`pi-exclamation-circle warn`}
							messageKey={t("pageBuildingMan.dialog.requestLicence.warning")}
							messageStyle="small"
						/>
					</div>
				</FusionDialog>
			)}
		</>
	);
}

export default BuildingManagement;
