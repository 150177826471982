import React from "react";
import { useTranslation } from "react-i18next";

function InputName(props) {
	const { t } = useTranslation();
	const { register, errors, defaultValue, disabled, updateMap } = props;

	const handleUpdate = (e) => {
		updateMap(e.target.name, e.target.value);
	};

	return (
		<div className="formField">
			<label htmlFor="longitude">{t("pageBuildingEdit.form.label.longitude")}</label>
			<input
				type="text"
				defaultValue={defaultValue}
				disabled={disabled}
				className={errors.longitude ? "error" : ""}
				{...register("longitude", { required: true, min: -180, max: 180, pattern: /^-?\d*\.?\d*$/ })}
				onBlur={handleUpdate}
			/>
			<ul className="errors">
				{errors?.longitude?.types?.required && <li className="error">{t("pageBuildingEdit.form.error.longitude.required")}</li>}
				{errors?.longitude?.types?.min && <li className="error">{t("pageBuildingEdit.form.error.longitude.min")}</li>}
				{errors?.longitude?.types?.max && <li className="error">{t("pageBuildingEdit.form.error.longitude.max")}</li>}
				{errors?.longitude?.types?.pattern && <li className="error">{t("pageBuildingEdit.form.error.longitude.pattern")}</li>}
			</ul>
		</div>
	);
}

export default InputName;
